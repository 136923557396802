import { Filter } from "./Filter";
import { TaskObject } from "../TaskObject";

/**
 * Inverts a filter 
 * 
 * @remarks
 * This filter passes anything its child filter would block, and vice versa
 * 
 * @param child - the filter that is to be inverted
 */
export class NotFilter extends Filter{
    public get debugDescription(): string {
       return `NotFilter: not ${this.child.debugDescription}`
    }
    child: Filter;
    constructor(name: string, child: Filter) {
        super(name);
        this.child = child;
    }

    /**
     * Return false if child filter return true
     * 
     * @param item 
     */
    public isFiltered(item: TaskObject): boolean {
        return !this.child?.isFiltered(item);       
    }
    
    
    public get description(){
        return `not ${this.child.description}`
    }

   
}