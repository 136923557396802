import { defineStore } from "pinia";

import { SplitFactory } from "@splitsoftware/splitio";
import SplitIO from "@splitsoftware/splitio/types/splitio";
import { useUserStore } from "./useUserStore";
import { Logger } from "@/helpers/Logger";

const factory: SplitIO.ISDK = SplitFactory({
  core: {
    authorizationKey: "iqs3a60l6i3j15hiapfgdb1qnpofsambbsm1",
    key: "dummy to be set by makeClientPromise",
  },
  startup: {
    readyTimeout: 1.5, // 1.5 sec
  },
});

const makeClientPromise = (key: string): Promise<SplitIO.IClient> => {
  return new Promise((resolve, reject) => {
    try {
      const splitKey: SplitIO.SplitKey = key;
      const client = factory.client(splitKey);
      client.on(client.Event.SDK_READY, function () {
        resolve(client);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const useFeatureFlagsStore = defineStore("filter-flags", {
  state: () => ({
    userKey: "",
    client: null,
    initialised: false,
    initialising: false,

    unfilteredSearchFlag: false,
    freeTrial7Days: false,
    autoUpdate: false,
    purgeCompletedTasksFlag: false,
    sortByContextFlag: false,
    stripeFreeTrialFlag: true,
    newRegisterPageFlag: false,
    newLoginPageFlag: false,
    stripeDirectFlag: false,
    firebaseStripeExtensionFlag: false,
    zapierConnectFeatureFlag: false,
    sortUndatedAtEndFlag: true,
    importFeatureFlag: false,
    exportFeatureFlag: false,
    addedDateFilterFlag: false,
    selectTasksFlag: false,
    testNewSignupFlag: false,
    filterLinkFlag: false,
    newHamburgerMenuFlag: true,
    manageSubscriptionFlag: true,
    newFolderListFlag: false,
    bulkDeleteFeatureFlag: false,
    installPWAFlag: false,
    freePassFlag: false,
    selectTasksFeatureFlag: false,
    adoptSubtasksFeatureFlag: false,
    promoteSubtaskFeatureFlag: false,
    expandingParentFeatureFlag: false,
  }),

  actions: {
    async init(): Promise<boolean> {
      try {
        const userStore = useUserStore();
        if (this.initialising) {
          return Promise.resolve(false);
        }

        if (!userStore.user?.uid) {
          return Promise.resolve(false);
        }
        this.initialising = true;
        const userKey = userStore.user.uid;
        this.userKey = userKey;
        this.client = await makeClientPromise(userKey);

        this.initialised = true;
        this.initialising = false;

        const treatment: SplitIO.Treatment = this.client.getTreatment(
          "feature-unfiltered-search-results"
        );
        this.unfilteredSearchFlag = treatment == "on";

        const freeTrial7DaysTreatment: SplitIO.Treatment =
          this.client.getTreatment("free-trial-7-days");
        this.freeTrial7Days = freeTrial7DaysTreatment == "on";
        this.autoUpdate = this.client.getTreatment("auto-update") == "on";
        this.purgeCompletedTasksFlag =
          this.client.getTreatment("Purge_Completed") == "on";
        this.sortByContextFlag =
          this.client.getTreatment("sort-by-context") == "on";
        this.stripeFreeTrialFlag =
          this.client.getTreatment("stripe-free-trial") == "on";
        this.stripeDirectFlag =
          this.client.getTreatment("stripe-direct") == "on";
        this.firebaseStripeExtensionFlag =
          this.client.getTreatment("firebase-stripe-extension") == "on";
        this.zapierConnectFeatureFlag =
          this.client.getTreatment("zapier-connect") == "on";
        this.importFeatureFlag = this.client.getTreatment("import") == "on";
        this.exportFeatureFlag = this.client.getTreatment("export") == "on";
        this.addedDateFilterFlag =
          this.client.getTreatment("added-date-filter") == "on";
        this.selectTasksFlag = this.client.getTreatment("select-tasks") == "on";
        this.testNewSignupFlag =
          this.client.getTreatment("test-new-registration") == "on";
        this.filterLinkFlag = this.client.getTreatment("filter-link") == "on";
        this.manageSubscriptionFlag =
          this.client.getTreatment("manage-subscription") == "on";
        this.newFolderListFlag =
          this.client.getTreatment("new-folder-list") == "on";
        this.bulkDeleteFeatureFlag =
          this.client.getTreatment("bulk_delete") == "on";
        this.installPWAFlag = this.client.getTreatment("install-pwa") == "on";
        this.freePassFlag = this.client.getTreatment("free-pass") == "on";
        this.selectTasksFeatureFlag =
          this.client.getTreatment("select-tasks") == "on";
        this.adoptSubtasksFeatureFlag =
          this.client.getTreatment("adopt-subtasks") == "on";
        this.promoteSubtaskFeatureFlag =
          this.client.getTreatment("promote-subtask") == "on";
        this.expandingParentFeatureFlag =
          this.client.getTreatment("expanding-parent") == "on";

        this.initialising = false;
        this.initialised = true;
        return true;
      } catch (error) {
        Logger.logError("Error while initialising feature flags", error);
        this.initialising = false;
        this.initialised = false;
        throw error;
      }
    },
  },
});
