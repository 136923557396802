export enum SubscribePageStep {
  Starting = "starting",
  SigningUp = "signing_up",
  SignedUp = "signed_up",
  SigningIn = "signing_in",
  SignedIn = "signed_in",
  InPortal = "in_portal",
  OutOfPortal = "out_of_portal",
  Finished = "finished",
}
