import { Filter } from "./Filter";
import { TaskObject } from "../TaskObject";
import { FolderObject } from "../FolderModel";
import { Constants } from "@/constants/constants";

/**
 * Filters tasks that have folder Id set to a given vaue
 *
 * @param value - the folder Id to be filtered on
 *
 * @remarks
 * If value is null or an empty string, tasks are filtered in when they have no folderId
 */
export class FolderFilter extends Filter {
  public get debugDescription(): string {
    if (!this.value) return "No folder";
    return `Folder filter: folder ${this.value.title}`
  }
  value: FolderObject;
  constructor(value: FolderObject) {
    super(value?.title ?? Constants.INBOX_TITLE);
    this.value = value;
  }

  public isFiltered(item: TaskObject): boolean {
    if(!this.value){
      // console.log(`Folder filter is looking for tasks with no folder id`)
      if(!item.folderId){
        // console.log(`Folder filter passes task with folder id ${item.folderId}`)
        return true
      }
      // console.log(`Folder filter blocks task with folder id ${item.folderId}`)
      return false
    }
    // if (this.value == null) return item.folderId == null || item.folderId === "";
    const result = this.value.id === item.folderId;
    if(result) {
      // console.log(`Folder filter passes task with folder id ${item.folderId} while looking for a folder with id ${this.value}`)
      return true
    }
    // console.log(`Folder filter blocks task with folder id ${item.folderId} while looking for a folder with id ${this.value}`)

    return false;
  }

  public get description() {
    if (this.value == null) return Constants.INBOX_TITLE;
    return this.value.title;
  }

  
}
