import { Constants } from "@/constants/constants";
import {
  db,
  getCollectionDocs,
  listenForCollectionChanges,
  notesCollectionRef,
} from "@/firebase/firebase-auth";
import { NoteObject } from "@/models/NoteObject";
import {
  collection,
  doc,
  DocumentChange,
  DocumentData,
  onSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { defineStore, storeToRefs } from "pinia";
import { v4 as generateUUID } from "uuid";
import { startOfDay } from "date-fns";

import { Logger } from "@/helpers/Logger";
import { Sorter } from "@/sorters/Sorter";
import { useFiltersStore } from "@/stores/useFiltersStore";
import * as Sentry from "@sentry/vue";
import { useSettingsStore } from "./useSettingsStore";
import { useUserStore } from "./useUserStore";

let firebaseNotesUnsubscribe: { (): void; (): void } | null = null;

export const useNotesStore = defineStore("notesStore", {
  state: () => ({
    notes: Array<NoteObject>(),
    haveNotesLoaded: false,
    isLoadingNotes: false,
    mostRecentId: "",
    isFirestoreSubscribed: false,
  }),

  getters: {
    getNotes: (state) => {
      return state.notes ?? [];
    },
    noteFromId:
      (state) =>
      (id: string): NoteObject | undefined => {
        if (state.notes) {
          const index = state.notes.findIndex((n) => n.id === id);
          const result: NoteObject = (index >= 0 && state.notes[index]) || null;
          return result;
        }
        return null;
      },

    allNotesCount: (state) => {
      return state.notes?.length || 0;
    },

    todaysNotesCount: (state) => {
      const today = new Date();
      const limit = startOfDay(today);
      return (
        state.notes?.filter(
          (n: NoteObject) => n.added > limit || n.modified > limit
        ).length || 0
      );
    },

    countNotesFilteredBy: (state) => {
      return (filterId: string) => {
        const filtersStore = useFiltersStore();
        const filter = filtersStore.noteFilterFromId(filterId);
        const result =
          state.notes?.filter((obj) => filter?.isFiltered(obj)).length || 0;
        return result;
      };
    },
  },
  actions: {
    subscribeToFirestoreNotes() {
      return new Promise<NoteObject[]>((resolve, reject) => {
        if (!notesCollectionRef) {
          reject(new Error(`subscribeToFirestoreNotes has no collection ref`));
          return;
        }
        try {
          firebaseNotesUnsubscribe = onSnapshot(
            notesCollectionRef,
            async (snap: QuerySnapshot) => {
              try {
                if (this.haveNotesLoaded) {
                  const changes = snap.docChanges();
                  if (!Array.isArray(changes)) return;
                  if (changes.length === 0) return;
                  snap.docChanges().forEach((change) => {
                    this.processFirestoreChange(change);
                  });
                  resolve(this.notes);
                  return;
                }

                const docs = snap.docs;

                if (!Array.isArray(docs)) {
                  console.log(
                    "subscribeToFirestoreNotes snapshot sees docs are not an array"
                  );
                  reject(
                    new Error(
                      "subscribeToFirestoreNotes snapshot sees docs are not an array"
                    )
                  );
                }
                if (docs.length === 0) {
                  console.log(
                    "subscribeToFirestoreNotes snapshot sees there are no docs"
                  );
                  this.notes = [];
                }

                this.notes = this.convertFirestoreDocs(snap.docs);

                this.haveNotesLoaded = true;
                this.isLoadingNotes = false;
                resolve(this.notes);
                return;
              } catch (innerError) {
                Logger.logError(
                  `Error in firebase notes onSnapshot, rejecting promise`,
                  innerError
                );
                reject(innerError);
              }
            }
          );

          this.isFirestoreSubscribed = true;
        } catch (error) {
          Logger.logError("Error in subscribe to Firestore Notes", error);
          reject(error);
        }
      });
    },
    listenForFirestoreNoteChanges() {
      firebaseNotesUnsubscribe = listenForCollectionChanges(
        "notes",
        (changes: DocumentChange<DocumentData, DocumentData>[]) => {
          this.processFirestoreChanges(changes);
        }
      );
    },
    async loadNotes() {
      try {
        console.log("loadNotes is starting");
        Logger.log(`loadNotes is starting`);

        console.log(`loadNotes is calling getCollectionDocs for notes`);
        const docs = await getCollectionDocs("notes");
        console.log(`loadNotes has got ${docs.length} docs`);

        const fetched = this.convertFirestoreDocs(docs);
        this.$patch({ notes: fetched, haveNotesLoaded: true });

        console.log(
          `loadNotes has converted ${this.notes.length} notes and starts listening for changes`
        );

        this.listenForFirestoreNoteChanges();
        console.log(
          `loadNotes is listening for changes. There are currently ${this.notes.length} notes.`
        );
      } catch (error) {
        Logger.logError("Error in loadNotes", error);
        throw error;
      }
    },

    processFirestoreChanges(
      changes: DocumentChange<DocumentData, DocumentData>[]
    ) {
      for (const change of changes) {
        this.processFirestoreChange(change);
      }
      console.log(
        `after processFirestoreChanges we now have ${this.notes.length} notes`
      );
    },

    processFirestoreChange(change: DocumentChange<DocumentData, DocumentData>) {
      const data = change.doc.data();
      const id = change.doc.id;
      const note = NoteObject.fromFirestoreObject(data, id);
      if (change.type === "added") this.processFirestoreAdded(note);
      else if (change.type === "modified") this.processFirestoreModified(note);
      else if (change.type === "removed") this.processFirestoreRemoved(note);
    },

    processFirestoreAdded(note: NoteObject) {
      const exists = !!this.noteFromId(note.id);
      if (!exists) this.notes.push(note);
    },

    processFirestoreModified(note: NoteObject) {
      note.modified = new Date();
      const noteIndex = this.notes.findIndex(
        (t: NoteObject) => t.id == note.id
      );
      if (noteIndex < 1) return;

      this.notes.splice(noteIndex, 1);
      this.notes.push(note);
    },

    processFirestoreRemoved(note: NoteObject) {
      const noteIndex = this.notes.findIndex(
        (t: NoteObject) => t.id == note.id
      );
      if (noteIndex < 1) {
        return;
      }
      this.notes.splice(noteIndex, 1);
    },

    convertFirestoreDocs(
      docs: QueryDocumentSnapshot<DocumentData, DocumentData>[]
    ) {
      const result = Array<NoteObject>();
      docs.forEach((doc: any) => {
        const note = this.convertFirestoreDoc(doc);
        result.push(note);
      });
      return result;
    },

    convertFirestoreDoc(doc: any) {
      const data = doc.data();
      const id = doc.id;
      return NoteObject.fromFirestoreObject(data, id);
    },

    unsubscribeNotes() {
      if (firebaseNotesUnsubscribe) {
        firebaseNotesUnsubscribe();
        this.haveNotesLoaded = false;
      }
    },
    clearNotes() {
      this.notes = [];
      this.haveNotesLoaded = false;
    }, // clearNotes

    addNoteToStateAndSort(note: NoteObject) {
      const exists = !!this.noteFromId(note.id);
      if (exists) return;
      this.notes.push(note);

      const settings = useSettingsStore();
      const { sortNotesBy, sortNotesDirection } = storeToRefs(settings);
      Sorter.sortNotesInPlace(
        this.notes,
        sortNotesBy.value,
        sortNotesDirection.value
      );
    },

    addNoteToState(note: NoteObject) {
      const exists = !!this.noteFromId(note.id);
      if (exists) return;
      this.notes.push(note);
    },

    async addNote(note: NoteObject) {
      try {
        if (!note.id) note.id = generateUUID();
        note.modified = new Date();
        note.added = note.modified;
        this.addNoteToState(note);
        await this.writeNoteToFirestore(note);
      } catch (error) {
        Logger.logError("Error in addNote:", error);
        throw error;
      }
    },

    async writeNoteToFirestore(note: NoteObject) {
      const userStore = useUserStore();
      if (!userStore.user?.uid) return false;
      const userDocRef = doc(db, "users", userStore.user.uid);
      const collectionRef = collection(userDocRef, "notes");
      const docRef = doc(collectionRef, note.id);
      const obj = note.copyToFirestoreObject();
      await setDoc(docRef, obj);
    },

    async saveNote(note: NoteObject) {
      try {
        const userStore = useUserStore();
        if (!userStore.user?.uid) return false;

        note.modified = new Date();
        note.added = note.added ?? note.modified;

        const noteIndex: number = this.notes.findIndex(
          (t: NoteObject) => t.id == note.id
        );

        if (noteIndex < 0) {
          return;
        }

        this.removeNoteFromStateAtIndex(noteIndex);
        this.notes.push(note);

        await this.writeNoteToFirestore(note);
      } catch (error: any) {
        Logger.logError("Error in editNote", error);
        throw error;
      }
    },

    makeDefaultNote() {
      const note = new NoteObject({});
      return note;
    },

    removeNoteFromStateAtIndex(index: number) {
      this.notes.splice(index, 1);
    },

    removeNoteWithIdFromState(id: string) {
      const foundIndex = this.notes.findIndex((note) => note.id === id);
      if (foundIndex >= 0) this.removeNoteFromStateAtIndex(foundIndex);
    },

    async removeNote(id: string) {
      await this.removeNotes([id]);
    },

    async removeNotes(ids: string[]) {
      if (!ids) return;
      const userStore = useUserStore();
      for (const id of ids) {
        const noteIndex: number = this.notes.findIndex(
          (t: NoteObject) => t.id == id
        );
        this.removeNoteFromStateAtIndex(noteIndex);
      }
      try {
        let batch = writeBatch(db);
        let itemsInBatch = 0;

        for (const id of ids) {
          itemsInBatch += 1;
          if (itemsInBatch >= Constants.MAX_BATCH_SIZE) {
            await batch.commit();
            batch = writeBatch(db);
            itemsInBatch = 1;
          }

          const userDocRef = doc(db, "users", userStore.user.uid);
          const collectionRef = collection(userDocRef, "notes");
          const docRef = doc(collectionRef, id);

          batch.delete(docRef);
        }

        await batch.commit();
      } catch (error: any) {
        Logger.logError("Error in removeNotes", error);
        throw error;
      }
    },

    unsubscribeFirestore() {
      if (firebaseNotesUnsubscribe) {
        firebaseNotesUnsubscribe();
        this.isFirestoreSubscribed = false;
      }
    },
  },
});
