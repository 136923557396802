import { Logger } from "@/helpers/Logger";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
  getAuth,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
  User,
} from "firebase/auth";
import {
  Firestore,
  getFirestore,
  initializeFirestore,
} from "firebase/firestore";
import {
  persistentLocalCache,
  persistentSingleTabManager,
} from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAU-W1NpRM2fZ-yLGwKdnY_Smnkq7ym9DA",
  authDomain: "taskangel-f1161.firebaseapp.com",
  databaseURL: "https://taskangel-f1161.firebaseio.com",
  projectId: "taskangel-f1161",
  storageBucket: "taskangel-f1161.appspot.com", // This is the correct value
  messagingSenderId: "541410762395",
  appId: "1:541410762395:web:1129c4382cd9589167303e",
  measurementId: "G-S1VDL12M4V",
};

export const app = initializeApp(firebaseConfig);

export let db: Firestore;
try {
  // db = initializeFirestore(app, {
  //   localCache: persistentLocalCache({
  //     tabManager: persistentSingleTabManager({}),
  //   }),
  // });
  db = getFirestore(app);
} catch (error) {
  Logger.logError(
    `Error while initialising firestore. Please close any other versions of TaskAngel already running`,
    error
  );
  throw error;
}

export const storage: FirebaseStorage = getStorage(app);
