import { Filter } from "./Filter";
import { TaskObject } from "../TaskObject";

export class IsCompleteFilter extends Filter{
    public get debugDescription(): string {
        return `IsComplete filter: ${this.name}`
    }
    value: boolean;
    constructor(value: boolean) {
        super(value ? 'complete' : 'active');
        this.value = !!value;
    }

    public isFiltered(item: TaskObject): boolean {
        let result = false;
        if (this.value) {
            result = !!item.completed;
        } else {
            result =  !item.completed;
        }
        // console.log(`IsComplete with value ${this.value} returns ${result} for item ${JSON.stringify(item)}`);
        return result;
    }
    
    public get description(){
        return this.value ? "complete" : "incomplete";
    }

   
}