import { SortDirection } from "@/models/SortDirection";
import { TaskObject } from "@/models/TaskObject";
import { useGoalsStore } from "@/stores/useGoalsStore";
import { TaskComparer } from "./TaskComparer";

export class TaskGoalComparer extends TaskComparer {
  constructor(direction: SortDirection) {
    super(direction);
  }
  compare = (a: TaskObject, b: TaskObject) => {
    const ascending = this.direction === SortDirection.Ascending;
    const goalsStore = useGoalsStore();

    if (a.goalId == b.goalId) return 0;
    if (!a.goalId) return ascending ? -1 : 1;
    if (!b.goalId) return ascending ? 1 : -1;

    const aGoal = goalsStore.goalFromId(a.goalId);
    const bGoal = goalsStore.goalFromId(b.goalId);

    if (!aGoal?.title) return ascending ? -1 : 1;
    if (!bGoal?.title) return ascending ? 1 : -1;

    const rawCompare = aGoal.title.localeCompare(bGoal.title);
    const descendingMultiplier = ascending ? 1 : -1;
    return rawCompare * descendingMultiplier;
  };
}
