export enum Property {
  Due = "Due",
  Folder = "Folder",
  Goal = "Goal",
  Context = "Context",
  Priority = "Priority",
  Urgency = "Urgency",
  Start = "Start",
  Title = "Title",
  None = "None",
}
