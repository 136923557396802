import { v4 } from 'uuid';
import { TaskObject } from "./TaskObject";

export class TaskGroup {
    type: "TaskGroup"
    title?: string
    value?: any
    items?: TaskObject[]
    count: number
    id: string

    constructor(title: string, value?: any) {
        this.id = v4() as string;
        this.type = "TaskGroup"
        this.title = title;
        this.value = value
        this.items = [];
    }
}