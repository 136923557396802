import { Constants } from "@/constants/constants";
import {
  db,
  foldersCollectionRef,
  getCollectionDocs,
  listenForCollectionChanges,
} from "@/firebase/firebase-auth";
import { FolderObject } from "@/models/FolderModel";
import { MyNotification } from "@/models/Notification";
import { useNotificationsStore } from "@/stores/useNotifications";
import {
  collection,
  doc,
  DocumentChange,
  DocumentData,
  onSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { defineStore } from "pinia";
import { v4 as generateUUID } from "uuid";

import { Logger } from "@/helpers/Logger";
import { Sorter } from "@/sorters/Sorter";
import { useUserStore } from "./useUserStore";

let firebaseFoldersUnsubscribe: { (): void; (): void } | null = null;

/** Maintain folder list in Pinia store
 * @remarks We are going to keep the folder list sorted alphetically
 */
export const useFoldersStore = defineStore("foldersStore", {
  state: () => ({
    folders: Array<FolderObject>(),
    isLoadingFolders: false,
    haveFoldersLoaded: false,
    mostRecentId: "",
    areFirebaseFoldersSubscribed: false,
  }),

  /** Getters should be fat arrow functions unless they need to call other getters. That requires the use of this, which is only available in non-arrow functions.
   *
   */
  getters: {
    getFolders: (state) => {
      const result = state.folders ?? [];
      return result;
    },
    allFolders: (state) => {
      const f = state.folders ?? [];
      //   Sorter.sortGoalsInPlace(f);
      return f;
    },

    folderFromId:
      (state) =>
      (id: string): FolderObject | undefined => {
        if (state.folders?.length > 0) {
          for (let i = 0; i < state.folders.length; i += 1) {
            if (state.folders[i].id === id) {
              return state.folders[i];
            }
          }
        }
        return undefined;
      },
    folderFromTitle:
      (state) =>
      (title: string): FolderObject | undefined => {
        const candidates =
          state.folders && state.folders.filter((f) => f.title === title);
        return (candidates && candidates[0]) || undefined;
      },

    allFoldersCount: (state) => {
      return state.folders?.length || 0;
    },
  },
  actions: {
    // async subscribeToFirestoreFolders() {
    //   return new Promise<FolderObject[]>((resolve, reject) => {
    //     if (!foldersCollectionRef) {
    //       reject(new Error(`subscribeToFirestoreTasks has no collection ref`));
    //       return;
    //     }
    //     try {
    //       firebaseFoldersUnsubscribe = onSnapshot(
    //         foldersCollectionRef,
    //         async (snap: QuerySnapshot) => {
    //           try {
    //             if (this.haveFoldersLoaded) {
    //               const changes = snap.docChanges();
    //               if (!Array.isArray(changes)) return;
    //               if (changes.length === 0) return;
    //               changes.forEach((change) => {
    //                 this.processFirestoreChange(change);
    //               });

    //               this.folders = Sorter.copyAndSortFolders(this.folders);
    //               resolve(this.folders);
    //               return;
    //             }

    //             const docs = snap.docs;
    //             if (!Array.isArray(docs)) {
    //               reject(
    //                 new Error(
    //                   "subscribeToFirestoreFolders snapshot sees docs are not an array"
    //                 )
    //               );
    //             }
    //             if (docs.length === 0) {
    //               console.log(
    //                 "subscribeToFirestoreContexts snapshot sees there are no docs"
    //               );
    //               this.folders = [];
    //             }

    //             const foldersForSort = this.convertFirestoreDocs(docs);
    //             this.folders = Sorter.copyAndSortFolders(foldersForSort);
    //             this.haveFoldersLoaded = true;
    //             this.areFirebaseFoldersSubscribed = true;
    //             resolve(this.folders);
    //             return;
    //           } catch (innerError) {
    //             Logger.logError(
    //               `Error in firebase folders onSnapshot, rejecting promise`,
    //               innerError
    //             );
    //             reject(innerError);
    //           }
    //         }
    //       );
    //     } catch (error) {
    //       Logger.logError(
    //         `Error in firebase tasks onSnapshot, rejecting promise`,
    //         error
    //       );
    //       reject(error);
    //     }
    //   });
    // },

    processFirestoreChanges(
      changes: DocumentChange<DocumentData, DocumentData>[]
    ) {
      for (const change of changes) {
        this.processFirestoreChange(change);
      }
      console.log(
        `after processFirestoreChanges we now have ${this.folders.length} folders`
      );
    },

    processFirestoreChange(change: DocumentChange<DocumentData, DocumentData>) {
      const data = change.doc.data();
      const id = change.doc.id;
      const folder = FolderObject.fromFirestoreObject(data, id);
      if (change.type === "added") this.processFirestoreAdded(folder);
      else if (change.type === "modified")
        this.processFirestoreModified(folder);
      else if (change.type === "removed") this.processFirestoreRemoved(folder);
    },

    processFirestoreAdded(folder: FolderObject) {
      const exists = !!this.folderFromId(folder.id);
      if (!exists) this.folders.push(folder);
    },

    processFirestoreModified(folder: FolderObject) {
      folder.modified = new Date();
      const folderIndex = this.folders.findIndex(
        (t: FolderObject) => t.id == folder.id
      );
      if (folderIndex < 1) return;

      this.folders.splice(folderIndex, 1);
      this.folders.push(folder);
    },

    processFirestoreRemoved(folder: FolderObject) {
      const folderIndex = this.folders.findIndex(
        (t: FolderObject) => t.id == folder.id
      );
      if (folderIndex < 1) {
        return;
      }
      this.folders.splice(folderIndex, 1);
    },

    convertFirestoreDocs(
      docs: QueryDocumentSnapshot<DocumentData, DocumentData>[]
    ) {
      const result = Array<FolderObject>();
      docs.forEach((doc: QueryDocumentSnapshot<DocumentData, DocumentData>) => {
        const folder = this.convertFirestoreDoc(doc);
        result.push(folder);
      });
      return result;
    },

    convertFirestoreDoc(
      doc: QueryDocumentSnapshot<DocumentData, DocumentData>
    ) {
      const data = doc.data();
      const id = doc.id;
      return FolderObject.fromFirestoreObject(data, id);
    },

    listenForFirestoreFolderChanges() {
      firebaseFoldersUnsubscribe = listenForCollectionChanges(
        "folders",
        (changes: DocumentChange<DocumentData, DocumentData>[]) => {
          this.processFirestoreChanges(changes);
        }
      );
    },

    async loadFolders() {
      try {
        console.log("loadFolders is starting");
        Logger.log(`loadFolders is starting`);

        console.log(`loadFolders is calling getCollectionDocs for folders`);
        const docs = await getCollectionDocs("folders");
        console.log(`loadFolders has got ${docs.length} docs`);

        const fetched = this.convertFirestoreDocs(docs);
        this.$patch({ folders: fetched, haveFoldersLoaded: true });

        console.log(
          `loadFolders has converted ${this.folders.length} folders and starts listening for changes`
        );
        this.haveFoldersLoaded = true;
        this.listenForFirestoreFolderChanges();
        console.log(
          `loadFolders is listening for changes. There are currently ${this.folders.length} folders.`
        );
      } catch (error) {
        Logger.logError("Error in loadFolders", error);
        throw error;
      }
    },

    // async loadFolders(): Promise<FolderObject[]> {
    //   if (this.isLoadingFolders) {
    //     throw new Error(`Error: loadFolders sees folders are already loading`);
    //   }
    //   if (this.haveFoldersLoaded) {
    //     throw new Error(`Error: loadFolders sees folders have already loaded`);
    //   }
    //   const userStore = useUserStore();
    //   if (!userStore.user)
    //     throw new Error(`Uable to load folders as there is no user`);
    //   const result = await this.subscribeToFirestoreFolders();
    //   this.$patch({
    //     haveFoldersLoaded: true,
    //     isLoadingFolders: false,
    //   });
    //   return result;
    // },
    unsubscribeFolders() {
      if (firebaseFoldersUnsubscribe) {
        // console.log('pinia unsubscribing folders')
        firebaseFoldersUnsubscribe();
        this.haveFoldersLoaded = false;
      }
    },
    clearFolders() {
      this.folders = [];
      this.haveFoldersLoaded = false;
    },

    addFolderToStateAndSort(folder: FolderObject) {
      const exists = !!this.folderFromId(folder.id);
      if (exists) return;
      this.folders.push(folder);
      this.folders = Sorter.copyAndSortFolders(this.folders);
    },

    addFolderToState(folder: FolderObject) {
      const exists = !!this.folderFromId(folder.id);
      if (exists) return;
      this.folders.push(folder);
    },

    async addFolder(folder: FolderObject) {
      if (!folder.id) folder.id = generateUUID();
      this.addFolderToState(folder);
      this.writeFolderToFirestore(folder);
    },

    async writeFolderToFirestore(folder: FolderObject) {
      const userStore = useUserStore();
      if (!userStore.user?.uid) return false;
      const userDocRef = doc(db, "users", userStore.user.uid);
      const collectionRef = collection(userDocRef, "folders");
      const docRef = doc(collectionRef, folder.id);
      const obj = folder.copyToFirestoreObject();
      await setDoc(docRef, obj);
    },

    async editFolder(folder: FolderObject) {
      try {
        const userStore = useUserStore();
        if (!userStore.user?.uid) return false;
        const userDocRef = doc(db, "users", userStore.user.uid);

        if (!userDocRef) {
          Logger.log(Constants.ERROR_NOT_LOGGED_IN);
          return false;
        }

        if (folder.id == null) {
          return;
        }

        folder.modified = new Date();

        const folderIndex: number = this.folders.findIndex(
          (t: FolderObject) => t.id == folder.id
        );

        if (folderIndex < 0) {
          return;
        }

        this.removeFolderFromStateAtIndex(folderIndex);
        this.folders.push(folder);

        await this.writeFolderToFirestore(folder);
      } catch (error: any) {
        const notification = MyNotification.fromError(
          error,
          "in editFolder while writing to fs"
        );
        const notifications = useNotificationsStore();
        notifications.addNotification(notification);
      }
    },

    makeDefaultFolder() {
      const folder = new FolderObject({});
      return folder;
    },

    removeFolderFromStateAtIndex(index: number) {
      this.folders.splice(index, 1);
    },

    removeFolderWithIdFromState(id: string) {
      const foundIndex = this.folders.findIndex((folder) => folder.id === id);
      if (foundIndex >= 0) this.removeFolderFromStateAtIndex(foundIndex);
    },

    async removeFolder(id: string) {
      await this.removeFolders([id]);
    },

    async removeFolders(ids: string[]) {
      if (!ids) return;
      const userStore = useUserStore();
      for (const id of ids) {
        const folderIndex: number = this.folders.findIndex(
          (t: FolderObject) => t.id == id
        );
        this.removeFolderFromStateAtIndex(folderIndex);
      }
      try {
        let batch = writeBatch(db);
        let itemsInBatch = 0;

        for (const id of ids) {
          itemsInBatch += 1;
          if (itemsInBatch >= Constants.MAX_BATCH_SIZE) {
            await batch.commit();
            batch = writeBatch(db);
            itemsInBatch = 1;
          }

          const userDocRef = doc(db, "users", userStore.user.uid);
          const collectionRef = collection(userDocRef, "folders");
          const docRef = doc(collectionRef, id);

          batch.delete(docRef);
        }

        await batch.commit();
      } catch (error: any) {
        Logger.logError(`Error in removeFolders`, error);
        throw error;
      }
    },

    unsubscribeFirestore() {
      if (firebaseFoldersUnsubscribe) {
        firebaseFoldersUnsubscribe();
        this.areFirebaseFoldersSubscribed = false;
      }
    },
  },
});
