import { TaskObject } from "../TaskObject";
import { DateFilterOption } from "../filters/DateFilterOption";
import { Filter } from "./Filter";
import startOfDay from "date-fns/startOfDay";
import { addDays, addMonths, addYears } from "date-fns";

/**
 * Filters tasks by start date, using friendly terms like 'overstart', 'today' and 'undated'
 */
export class ImportedDateFilter extends Filter {
  public get debugDescription(): string {
    return `Imported date filter: ${this.option}`;
  }
  option: DateFilterOption;
  constructor(option: DateFilterOption) {
    // console.log(`Constructing imported date filter for date option ${value}`)
    super(option);
    this.option = option;
  }

  /**
   * Returns true if a task matches the value expressed as Today, Yesterday or whatever
   * @param task - the task
   *
   * @remarks
   * The value for comparison is set in the constructor
   */
  public isFiltered(task: TaskObject): boolean {
    let result = false;
    const now = new Date();
    const today = startOfDay(now);
    const imported = task.imported;

    if (!imported) {
      return this.option === DateFilterOption.Undated;
    }

    switch (this.option) {
      case DateFilterOption.Today:
        result = !!imported && imported > today && imported < addDays(today, 1);
        break;
      case DateFilterOption.Yesterday:
        result =
          !!imported && imported > addDays(today, -1) && imported < today;
        break;
      case DateFilterOption.Week:
        result =
          !!imported &&
          imported < addDays(today, 1) &&
          imported > addDays(today, -7);
        break;
      case DateFilterOption.Month:
        result =
          !!imported &&
          imported < addDays(today, 1) &&
          imported > addMonths(today, -1);
        break;
      case DateFilterOption.Quarter:
        result =
          !!imported &&
          imported < addDays(today, 1) &&
          imported > addMonths(today, -3);
        break;
      case DateFilterOption.Year:
        result =
          !!imported &&
          imported < addDays(today, 1) &&
          imported > addYears(today, -1);
        break;
      case DateFilterOption.Older:
        result = !!imported && imported < addYears(today, -1);
        break;
    }

    return result;
  }

  public get description() {
    switch (this.option) {
      case DateFilterOption.Undated:
        return "not imported";
      case DateFilterOption.Today:
        return "imported today";
      case DateFilterOption.Yesterday:
        return "imported yesterday";
      case DateFilterOption.Week:
        return "imported in past week";
      case DateFilterOption.Month:
        return "imported in past month";
      case DateFilterOption.Quarter:
        return "imported in past 3 months";
      case DateFilterOption.Year:
        return "imported in past year";
      case DateFilterOption.Older:
        return "imported more than a year ago";
      default:
        return undefined;
    }
  }
}
