import { Property } from "./Property";

export const PropertyUtils = {
  propertyFromString(stringIn: string): Property | undefined {
    const propertyKey = Object.keys(Property).find(
      (key) => Property[key] === stringIn
    );
    return propertyKey ? Property[propertyKey] : undefined;
  },
};
