import { Filter } from "./Filter";
import { TaskObject } from "../TaskObject";

export class PriorityFilter extends Filter{
    public get debugDescription(): string {
        return `Priority filter: priority ${this.value}`
    }
    value: string;
    constructor(value: string) {
        super(value);
        this.value = value;
    }

    public isFiltered(item: TaskObject): boolean {
        const result =  (this.value === item.priority);
         return result;
    }
    
    public get description(){
        return this.value.toLowerCase();
    }

    
}