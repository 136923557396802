export class MenuItemObject {
    id: string;
    title: string;
    status: string;
    count?: number;
    children?: MenuItemObject[];
    isGroup: boolean;

    constructor(id: string, title: string, count?: number, isGroup?: boolean, status?: string ) {
        this.title = title;
        this.id = id;
        this.status = status;
        this.count = count;
        this.isGroup = !!isGroup
    }

}