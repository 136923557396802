import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ion-padding" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

import { Constants } from "@/constants/constants";
import { MenuGroupObject } from "@/models/MenuGroupObject";
import { MenuItemObject } from "@/models/MenuItemObject";
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonTitle,
} from "@ionic/vue";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import FilterMenuItem from "../components/FilterMenuItem.vue";
// import { useStore } from "vuex";
import { useContextsStore } from "@/stores/useContextsStore";
import { useFeatureFlagsStore } from "@/stores/useFeatureFlagsStore";
import { useFiltersStore } from "@/stores/useFiltersStore";
import { useFoldersStore } from "@/stores/useFoldersStore";
import { useGoalsStore } from "@/stores/useGoalsStore";
import { useTasksStore } from "@/stores/useTasksStore";
import { Sorter } from "@/sorters/Sorter";

// import { useFiltersStore } from "@/stores/useFilters";
// import { Filter } from "@/models/filters/Filter";
import { close } from "ionicons/icons";
import FilterMenuGroup from "./FilterMenuGroup.vue";

import { useSettingsStore } from "@/stores/useSettingsStore";
import { storeToRefs } from "pinia";

// import { useLDReady, useLDFlag } from 'launchdarkly-vue-client-sdk'
//   const ldReady = useLDReady()

// const unfilteredSearchKey = 'unfiltered-search-results'
// const unfilteredSearchFlag = useLDFlag(unfilteredSearchKey, false /* default flag value, set to true when we are ready to launch? */)

// export default defineComponent({
//   name: "FilterPanel",
//   components: {
//     FilterMenuItem,
//     FilterMenuGroup,
//     IonHeader,
//     // IonToolbar,
//     // IonButton,
//     // IonIcon,
//     // IonLabel,
//     IonTitle,
//     IonContent,
//     IonItem,
//   },


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterPanel',
  emits: ["selected", "closed"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
// emits: ["selected"],
// props: {
//   multipleChoice: {
//     type: Boolean,
//     default: false,
//   },
// },
// setup: (_, ctx: SetupContext) => {
// const store = useStore();

// Pinea store
const settings = useSettingsStore();

const {
  useMYN,
  openFilterGroup,
  currentFilterId,
  tasksSearchText,
  showFilterMenu,
  // showFutureStarts,
  // showCompleted,
  // showNegative,
} = storeToRefs(settings);

const featureFlags = useFeatureFlagsStore();
const { addedDateFilterFlag, newHamburgerMenuFlag } = storeToRefs(featureFlags);

const firestore = useTasksStore();
const { tasks, hasImportedTasks } = storeToRefs(firestore);
const { countTasksFilteredBy } = firestore;

const openAccordion = ref("");

const priorityTitle = computed<string>(() => {
  return (useMYN.value && "Urgency") || "Priority";
});

const priorityTitleOf = (priority: string) => {
  const result = (useMYN.value && Constants.URGENCY_MAP[priority]) || priority;
  return result;
};

const foldersStore = useFoldersStore();
const { folders } = storeToRefs(foldersStore);

const goalsStore = useGoalsStore();
const { goals } = storeToRefs(goalsStore);

const contextsStore = useContextsStore();
const { contexts } = storeToRefs(contextsStore);

// selected filters

const filtersStore = useFiltersStore();
const {
  selectedStatusFilter,
  selectedStatusTitle,
  selectedStarFilter,
  selectedStarTitle,
  selectedDueFilter,
  selectedDueTitle,
  selectedUrgencyFilter,
  selectedUrgencyTitle,
  selectedStartFilter,
  selectedStartTitle,
  selectedAddedFilter,
  selectedAddedTitle,
  selectedImportedFilter,
  selectedImportedTitle,
  selectedFolderFilter,
  selectedFolderTitle,
  selectedGoalFilter,
  selectedGoalTitle,
  selectedContextFilter,
  selectedContextTitle,
  selectedPriorityFilter,
  selectedPriorityTitle,
} = storeToRefs(filtersStore);
const { clearSelectedFilters } = filtersStore;

// const selectedStatusFilter = ref("any");
// const selectedStarFilter = ref("filter.star.any");
// const selectedDueFilter = ref("any");
// const selectedUrgencyFilter = ref("any");
// const selectedStartFilter = ref("any");
// const selectedAddedFilter = ref("any");

// new menu change events

const onReset = () => {
  // console.log(`FilterPanel is resetting selected filters`);
  clearSelectedFilters();
};

// user wants to close the filter
const onClose = () => {
  // console.log(`close button clicked on filter menu`);
  showFilterMenu.value = false;
  emit("closed");
};

const statusGroup = ref(null);

const onStatusFilterChange = (id: string) => {
  // console.log(`selectedStatusFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedStatusFilter: id,
  //   selectedStatusTitle: title,
  // });

  selectedStatusFilter.value = id;
  // selectedStatusTitle.value = title;

  // selectedStatusFilter.value = id;

  // toggle the accordion
  // console.log(`openAccordion is ${openAccordion.value}`);
  if (openAccordion.value == "statusAccordion") {
    openAccordion.value = "";
    // console.log(`openAccordion is now ${openAccordion.value}`);
  }

  // if (!statusGroup.value) {
  //   // console.log("statusGroup ref is not set");
  //   return;
  // }

  // console.log("statusGroup ref is set");
  // const native = statusGroup.value.$el;
  // // console.log(`statusGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const starGroup = ref(null);
const onStarFilterChange = (id: string) => {
  // console.log(`selectedStarFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedStarFilter: id,
  //   selectedStarTitle: title,
  // });
  selectedStarFilter.value = id;
  // selectedStarTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";

  // // toggle the accordion
  // if (!starGroup.value) {
  //   // console.log("starGroup ref is not set");
  //   return;
  // }

  // // console.log("starGroup ref is set");
  // const native = starGroup.value.$el;
  // // console.log(`starGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const dueGroup = ref(null);
const onDueFilterChange = (id: string) => {
  // console.log(`selectedDueFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedDueFilter: id,
  //   selectedDueTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedDueFilter.value = id;
  // selectedDueTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!dueGroup.value) {
  //   // console.log("dueGroup ref is not set");
  //   return;
  // }

  // // console.log("dueGroup ref is set");
  // const native = dueGroup.value.$el;
  // // console.log(`dueGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const startGroup = ref(null);
const onStartFilterChange = (id: string) => {
  // console.log(`selectedStartFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedStartFilter: id,
  //   selectedStartTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedStartFilter.value = id;
  // selectedStartTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!startGroup.value) {
  //   // console.log("startGroup ref is not set");
  //   return;
  // }

  // // console.log("startGroup ref is set");
  // const native = startGroup.value.$el;
  // // console.log(`startGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const addedGroup = ref(null);
const onAddedFilterChange = (id: string) => {
  // console.log(`selectedAddedFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedAddedFilter: id,
  //   selectedAddedTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedAddedFilter.value = id;
  // selectedAddedTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!addedGroup.value) {
  //   // console.log("addedGroup ref is not set");
  //   return;
  // }

  // // console.log("addedGroup ref is set");
  // const native = addedGroup.value.$el;
  // // console.log(`addedGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const importedGroup = ref(null);
const onImportedFilterChange = (id: string) => {
  // console.log(`selectedAddedFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedAddedFilter: id,
  //   selectedAddedTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedImportedFilter.value = id;
  // selectedAddedTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!addedGroup.value) {
  //   // console.log("addedGroup ref is not set");
  //   return;
  // }

  // // console.log("addedGroup ref is set");
  // const native = addedGroup.value.$el;
  // // console.log(`addedGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const priorityGroup = ref(null);
const onPriorityFilterChange = (id: string) => {
  // console.log(
  //   `selectedPriorityFilter will change to ${id} with title ${title}`
  // );

  // filtersStore.$patch({
  //   selectedPriorityFilter: id,
  //   selectedPriorityTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedPriorityFilter.value = id;
  // selectedPriorityTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!priorityGroup.value) {
  //   // console.log("priorityGroup ref is not set");
  //   return;
  // }

  // // console.log("priorityGroup ref is set");
  // const native = priorityGroup.value.$el;
  // // console.log(`priorityGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const urgencyGroup = ref(null);
const onUrgencyFilterChange = (id: string) => {
  // console.log(`selectedUrgencyFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedUrgencyFilter: id,
  //   selectedUrgencyTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedUrgencyFilter.value = id;
  // selectedUrgencyTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!urgencyGroup.value) {
  //   // console.log("urgencyGroup ref is not set");
  //   return;
  // }

  // // console.log("urgencyGroup ref is set");
  // const native = priorityGroup.value.$el;
  // // console.log(`urgencyGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const folderGroup = ref(null);
const onFolderFilterChange = (id: string, title: string) => {
  // console.log(`selectedFolderFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedFolderFilter: id,
  //   selectedFolderTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedFolderFilter.value = id;
  selectedFolderTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!folderGroup.value) {
  //   // console.log("folderGroup ref is not set");
  //   return;
  // }

  // // console.log("folderGroup ref is set");
  // const native = folderGroup.value.$el;
  // // console.log(`folderGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const goalGroup = ref(null);
const onGoalFilterChange = (id: string, title: string) => {
  // console.log(`selectedGoalFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedGoalFilter: id,
  //   selectedGoalTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedGoalFilter.value = id;
  selectedGoalTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!goalGroup.value) {
  //   // console.log("goalGroup ref is not set");
  //   return;
  // }

  // // console.log("goalGroup ref is set");
  // const native = goalGroup.value.$el;
  // // console.log(`goalGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const contextGroup = ref(null);
const onContextFilterChange = (id: string, title: string) => {
  // console.log(`selectedContextFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedContextFilter: id,
  //   selectedContextTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedContextFilter.value = id;
  selectedContextTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!contextGroup.value) {
  //   // console.log("contextGroup ref is not set");
  //   return;
  // }

  // // console.log("contextGroup ref is set");
  // const native = contextGroup.value.$el;
  // // console.log(`contextGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

// const onDueFilterChange = () => {
//   // console.log(`selectedDueFilter is changed to ${selectedDueFilter.value}`);
// };
// const onUrgencyFilterChange = () => {
// };
// const onStartFilterChange = () => {
//   // console.log(`selectedStartFilter is changed to ${selectedStartFilter.value}`);
// };
// const onAddedFilterChange = () => {
//   // console.log(`selectedAddedFilter is changed to ${selectedAddedFilter.value}`);
// };

// const onFolderFilterChange = () => {
//   console.log(
//     `selectedFolderFilter is changed to ${selectedFolderFilter.value}`
//   );
// };
// const onClose = () => {
//   // console.log(`close button pressed on the new hamburger menu`);
// };

// list data for menu items

const filterMenuData = ref(null);

const makeMenuData = () => {
  const result = Array<MenuItemObject>();

  result.push(
    new MenuItemObject(
      Constants.ALL_TASKS_FILTER,
      "All Tasks",
      tasks.value?.length ?? 0
    )
  );
  result.push(
    new MenuItemObject(
      Constants.COMPLETED_FILTER,
      "Completed",
      countTasksFilteredBy(Constants.COMPLETED_FILTER) ?? 0
    )
  );

  result.push(
    new MenuItemObject(
      Constants.STARRED_FILTER,
      "Starred",
      countTasksFilteredBy(Constants.STARRED_FILTER) ?? 0
    )
  );

  // priority group

  // .. or maybe urgency

  if (useMYN.value) {
    // create urgency group

    const urgencyGroup = new MenuGroupObject(
      Constants.URGENCY_GROUP_FILTER,
      "Urgency",
      openFilterGroup.value === Constants.URGENCY_GROUP_FILTER
        ? "open"
        : "closed",
      [
        new MenuItemObject(
          Constants.URGENCY_SOC_FILTER,
          Constants.URGENCY_SOC,
          countTasksFilteredBy(Constants.URGENCY_SOC_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.URGENCY_CRITICAL_NOW_FILTER,
          Constants.URGENCY_CRITICAL_NOW,
          countTasksFilteredBy(Constants.URGENCY_CRITICAL_NOW_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.URGENCY_OPPORTUNITY_NOW_FILTER,
          Constants.URGENCY_OPPORTUNITY_NOW,
          countTasksFilteredBy(Constants.URGENCY_OPPORTUNITY_NOW_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.URGENCY_OVER_THE_HORIZON_FILTER,
          Constants.URGENCY_OVER_THE_HORIZON,
          countTasksFilteredBy(Constants.URGENCY_OVER_THE_HORIZON_FILTER) ?? 0
        ),
      ]
    ); // created urgency group object
    result.push(urgencyGroup);
  } else {
    const priorityGroup = new MenuGroupObject(
      Constants.PRIORITY_GROUP_FILTER,
      priorityTitle.value,
      openFilterGroup.value === Constants.PRIORITY_GROUP_FILTER
        ? "open"
        : "closed",
      [
        new MenuItemObject(
          Constants.PRIORITY_TOP_FILTER,
          priorityTitleOf(Constants.PRIORITY_TOP),
          countTasksFilteredBy(Constants.PRIORITY_TOP_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.PRIORITY_HIGH_FILTER,
          priorityTitleOf(Constants.PRIORITY_HIGH),
          countTasksFilteredBy(Constants.PRIORITY_HIGH_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.PRIORITY_MEDIUM_FILTER,
          priorityTitleOf(Constants.PRIORITY_MEDIUM),
          countTasksFilteredBy(Constants.PRIORITY_MEDIUM_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.PRIORITY_LOW_FILTER,
          priorityTitleOf(Constants.PRIORITY_LOW),
          countTasksFilteredBy(Constants.PRIORITY_LOW_FILTER) ?? 0
        ),
      ]
    ); // created priority group object

    // do we need a negative element? Not if we are using MYN
    if (!useMYN.value) {
      priorityGroup.children.push(
        new MenuItemObject(
          Constants.PRIORITY_NEGATIVE_FILTER,
          priorityTitleOf(Constants.PRIORITY_NEGATIVE),
          countTasksFilteredBy(Constants.PRIORITY_NEGATIVE_FILTER) ?? 0
        )
      );
    }
    result.push(priorityGroup);
  } // not using MYN

  // make Start filters
  result.push(
    new MenuGroupObject(
      Constants.START_GROUP_FILTER,
      "Start",
      openFilterGroup.value === Constants.START_GROUP_FILTER
        ? "open"
        : "closed",
      [
        new MenuItemObject(
          Constants.START_OVERDUE_FILTER,
          "Overdue",
          countTasksFilteredBy(Constants.START_OVERDUE_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_TODAY_FILTER,
          "Today",
          countTasksFilteredBy(Constants.START_TODAY_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_TOMORROW_FILTER,
          "Tomorrow",
          countTasksFilteredBy(Constants.START_TOMORROW_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_WEEK_FILTER,
          "Within a week",
          countTasksFilteredBy(Constants.START_WEEK_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_MONTH_FILTER,
          "Within a month",
          countTasksFilteredBy(Constants.START_MONTH_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_3_MONTHS_FILTER,
          "Within 3 months",
          countTasksFilteredBy(Constants.START_3_MONTHS_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_YEAR_FILTER,
          "Within a year",
          countTasksFilteredBy(Constants.START_YEAR_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_UNDATED_FILTER,
          "Undated",
          countTasksFilteredBy(Constants.START_UNDATED_FILTER) ?? 0
        ),
      ]
    )
  );

  // make Due filters

  // console.log(`FilterPanel makeMenuData starts making due filters`);

  result.push(
    new MenuGroupObject(
      Constants.DUE_GROUP_FILTER,
      "Due",
      openFilterGroup.value === Constants.DUE_GROUP_FILTER ? "open" : "closed",
      [
        new MenuItemObject(
          Constants.DUE_OVERDUE_FILTER,
          "Overdue",
          countTasksFilteredBy(Constants.DUE_OVERDUE_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_TODAY_FILTER,
          "Today",
          countTasksFilteredBy(Constants.DUE_TODAY_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_TOMORROW_FILTER,
          "Tomorrow",
          countTasksFilteredBy(Constants.DUE_TOMORROW_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_WEEK_FILTER,
          "Within a week",
          countTasksFilteredBy(Constants.DUE_WEEK_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_MONTH_FILTER,
          "Within a month",
          countTasksFilteredBy(Constants.DUE_MONTH_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_3_MONTHS_FILTER,
          "Within 3 months",
          countTasksFilteredBy(Constants.DUE_3_MONTHS_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_YEAR_FILTER,
          "Within a year",
          countTasksFilteredBy(Constants.DUE_YEAR_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_UNDATED_FILTER,
          "Undated",
          countTasksFilteredBy(Constants.DUE_UNDATED_FILTER)
        ),
      ]
    )
  );

  // make added filters

  if (addedDateFilterFlag.value) {
    result.push(
      new MenuGroupObject(
        Constants.ADDED_GROUP_FILTER,
        "Added",
        openFilterGroup.value === Constants.DUE_GROUP_FILTER
          ? "open"
          : "closed",
        [
          new MenuItemObject(
            Constants.ADDED_TODAY_FILTER,
            "Today",
            countTasksFilteredBy(Constants.ADDED_TODAY_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_YESTERDAY_FILTER,
            "Yesterday",
            countTasksFilteredBy(Constants.ADDED_YESTERDAY_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_WEEK_FILTER,
            "Within a week",
            countTasksFilteredBy(Constants.ADDED_WEEK_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_MONTH_FILTER,
            "Within a month",
            countTasksFilteredBy(Constants.ADDED_MONTH_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_3_MONTHS_FILTER,
            "Within 3 months",
            countTasksFilteredBy(Constants.ADDED_3_MONTHS_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_YEAR_FILTER,
            "Within a year",
            countTasksFilteredBy(Constants.ADDED_YEAR_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_OLDER_FILTER,
            "More than a year ago",
            countTasksFilteredBy(Constants.ADDED_OLDER_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_UNDATED_FILTER,
            "Undated",
            countTasksFilteredBy(Constants.ADDED_UNDATED_FILTER)
          ),
        ]
      )
    );
  } // added filter feature flag

  // make goal filters
  const goalMenuItems = new Array<MenuItemObject>();

  const goals = useGoalsStore().goals;
  if (goals.length > 0) {
    const sorted = [...goals];
    Sorter.sortGoalsInPlace(sorted);
    for (let i = 0; i < sorted.length; i++) {
      const goal = sorted[i];
      const filterId = Constants.GOAL_FILTER_PREFIX + goal.id;

      const menuItem = new MenuItemObject(
        filterId,
        goal.title,
        countTasksFilteredBy(filterId)
      );
      goalMenuItems.push(menuItem);
    }
  }

  goalMenuItems.push(
    new MenuItemObject(
      Constants.GOAL_NONE_FILTER,
      "No goal",
      countTasksFilteredBy(Constants.GOAL_NONE_FILTER)
    )
  );

  const goalGroup = new MenuGroupObject(
    Constants.GOAL_GROUP_FILTER,
    "Goals",
    openFilterGroup.value === Constants.GOAL_GROUP_FILTER ? "open" : "closed",
    goalMenuItems
  );

  result.push(goalGroup);

  // make folder filters
  const folderMenuItems = new Array<MenuItemObject>();

  folderMenuItems.push(
    new MenuItemObject(
      Constants.FOLDER_NONE_FILTER,
      Constants.INBOX_TITLE,
      countTasksFilteredBy(Constants.FOLDER_NONE_FILTER)
    )
  );

  const folders = useFoldersStore().folders;
  // copy for the folder list
  const sorted = [...folders];
  Sorter.sortGoalsInPlace(sorted);
  if (sorted.length > 0) {
    for (let i = 0; i < sorted.length; i++) {
      const folder = sorted[i];
      const filterId = Constants.FOLDER_FILTER_PREFIX + folder.id;

      const menuItem = new MenuItemObject(
        filterId,
        folder.title,
        countTasksFilteredBy(filterId)
      );
      folderMenuItems.push(menuItem);
    }
  }

  const folderGroup = new MenuGroupObject(
    Constants.FOLDER_GROUP_FILTER,
    "Folders",
    openFilterGroup.value === Constants.FOLDER_GROUP_FILTER ? "open" : "closed",
    folderMenuItems
  );

  result.push(folderGroup);

  // make context filters

  const contextMenuItems = new Array<MenuItemObject>();

  contextMenuItems.push(
    new MenuItemObject(
      Constants.CONTEXT_NONE_FILTER,
      "None",
      countTasksFilteredBy(Constants.CONTEXT_NONE_FILTER)
    )
  );

  const contexts = useContextsStore().contexts;
  if (contexts.length > 0) {
    const sorted = [...contexts];
    for (let i = 0; i < sorted.length; i++) {
      const context = sorted[i];
      const filterId = Constants.CONTEXT_FILTER_PREFIX + context.id;

      const menuItem = new MenuItemObject(
        filterId,
        context.title,
        countTasksFilteredBy(filterId)
      );
      contextMenuItems.push(menuItem);
    }
  }

  const contextGroup = new MenuGroupObject(
    Constants.CONTEXT_GROUP_FILTER,
    "Contexts",
    openFilterGroup.value === Constants.CONTEXT_GROUP_FILTER
      ? "open"
      : "closed",
    contextMenuItems
  );

  result.push(contextGroup);

  // return filter menu data

  return result;
};

// end of filterMenuData()

/**
 * A key used to cause the list to refresh itself
 *
 * @remarks
 * Each item in the template is given a key made up of listKey and a filter id.
 * We can refresh the list by incrementing the list key.
 *
 */
const listKey = ref(0);

// computed properties

// functions

function isGroup(item: MenuItemObject) {
  return item.id.indexOf("group") >= 0;
}

function isOpen(item: MenuItemObject) {
  return item.id === openFilterGroup.value;
}

// events

/**
 * Handle All Tasks button
 *
 * @remarks
 * This clears the filters and then applies presets in Settings
 */

/**
 * Present the user with filter panel options
 *
 * @remarks
 * The options are:
 * Multiple filters
 * Simple filters
 *
 *
 */

const onItemSelected = (ev) => {
  // console.log(`FilterPanel onItemSelected`);
  // console.log(`FilterPanel onItemSelected sees event: ${JSON.stringify(ev)}`)
  // // console.log(`Filter panel calls store to select filter: ${ev.id}`);
  // if (addedDateFilterFlag.value) {
  //   console.log(`FilterPanel calls setCurrentFilterId with ${ev.id}`)
  // }

  tasksSearchText.value = "";
  currentFilterId.value = ev.id;

  emit("selected", ev.id); // debugging 11/9/22
};

// const handleClickedRefresh = async () => {
//   // console.log("FilterPanel sees clicked refresh");
//   isRefreshing.value = true
//   await store.dispatch("refreshUserData")
//   // console.log("MasterPage sees refresh finished");
//   // console.log("Hello");
//   // setTimeout(() => {
//   //   console.log("World!");
//     isRefreshing.value = false

//     // emit event to close filter panel
//     ctx.emit("refreshed");

//     // }, 500);

// };

// /**
//  * Handles itemChanged event.
//  *
//  * @remarks
//  * The event has a payload of id and value.
//  * We can can pass that directly into state.
//  */
// const onItemChanged = (ev: any) => {
//   // console.log(
//   //   `FilterPanel onItemChanged sees event: ${JSON.stringify(ev)}`
//   // );

//   useSettingsStore().setSetting(ev.id, ev.value)
//   // store.dispatch("setSetting", {
//   //   key: ev.id,
//   //   value: ev.value,
//   // });
// };

/**
 * Handle when use clicks on open/close for a filter group.
 *
 * @remarks
 * There can be only one filter group open at once. This is saved in settings.
 * This function changes that settings value
 */
const onGroupChanged = (ev: any) => {
  // console.log(
  //   `FilterPanel onGroupChanged sees event: ${JSON.stringify(ev)}`
  // );
  switch (ev.value) {
    case "open":
      openFilterGroup.value = ev.id;
      // setSetting(
      //   Constants.OPEN_FILTER_GROUP,
      //   ev.id,
      // );
      break;
    case "closed":
      openFilterGroup.value = "none";
      // setSetting(
      //   Constants.OPEN_FILTER_GROUP,
      //   ev.id,
      // );
      break;
  }

  // console.log(`FilterPanel onGroupChanged has set openFilterGroup setting to ${openFilterGroup.value}`)
};

// const filterDescription = computed(() => {
//   const filterId = currentFilterId.value;
//   const filter: Filter = taskFilterFromId(filterId);
//   // console.log(`FilterPanel sees filter: ${JSON.stringify(filter)}`);
//   let result: string | undefined = filter?.description;
//   if (!result) result = "No Filter";
//   // console.log(`FilterPanel sees filter description: ${result}`);
//   return result;
// });

// const unsubscribe = store.subscribe((mutation) => {
//   // if (!mutation.type.includes("Note") && store.getters.tasks?.length > 0) {
//     // ignore note mutations, and don't run if there are no tasks
//     // || mutation.type.includes("Goal")
//     // || mutation.type.includes("Setting")
//     // || mutation.type.includes("Folder")) {
//     // calculate all the filter menu data including counts
//     // console.log(
//     //   `FilterPanel sees mutation ${mutation.type} and starts to make menu data`
//     // );

//     // filterMenuData.value = makeMenuData();
//     // console.log(
//     //   `FilterPanel sees ${mutation.type} and makes menu data ${JSON.stringify(
//     //     filterMenuData.value
//     //   )}`
//     // );
//     // listKey.value += 1; // refresh the filter menu
//   // }
// });

const unsubscribeSettings = settings.$subscribe(() => {
  //   // subscribing to pinia state changes

  // console.log(
  //   `FilterPanel subscription sees pinia state change with mutation type  ${
  //     mutation.type
  //   } and payload ${JSON.stringify(mutation.payload)}}  ***`
  // );

  filterMenuData.value = makeMenuData();

  listKey.value += 1;

  //   // areTasksShowing.value = currentTab.value === "tasks";
  //   //  console.log(`App sees currentTab is ${currentTab.value} and sets areTasksShowing to ${areTasksShowing.value}`
  //   // );
  // });
});

onMounted(() => {
  // console.log(`FilterPanel is mounted and starts to make menu data`);
  filterMenuData.value = makeMenuData();
  // console.log(`FilterPanel returned from make menu data`);
  listKey.value += 1; // refresh the filter menu
});

onBeforeUnmount(() => {
  // console.log("unmounting FilterPanel");
  unsubscribeSettings(); // stop listing to mutations
});

// return {
//   // event handlers
//   onItemChanged,
//   onGroupChanged,
//   onItemSelected,
//   handleAllTasks,
//   handleOptionsClick,
//   // handleClickedRefresh,

//   // icons
//   closeOutline,
//   closeCircleOutline,
//   saveOutline,
//   refreshOutline,
//   ellipsisVerticalOutline,
//   filterOutline,
//   listOutline,
//   openFilterGroup,

//   // properties
//   filterMenuData,
//   // filterMenuItems,
//   listKey,
//   filterDescription,
//   isGroup,
//   isOpen,
//   // multipleChoice,
// };

return (_ctx: any,_cache: any) => {
  return (_unref(newHamburgerMenuFlag))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(IonItem), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonTitle), null, {
              default: _withCtx(() => _cache[59] || (_cache[59] = [
                _createTextVNode(" Filters")
              ])),
              _: 1
            }),
            _createVNode(_unref(IonButton), {
              "icon-only": "",
              fill: "clear",
              slot: "end",
              onClick: onClose
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), { icon: _unref(close) }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(IonItem), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonButton), {
              style: {"margin-left":"1em"},
              onClick: onReset
            }, {
              default: _withCtx(() => _cache[60] || (_cache[60] = [
                _createTextVNode(" Clear Filters ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(IonAccordionGroup), {
            modelValue: openAccordion.value,
            "onUpdate:modelValue": _cache[58] || (_cache[58] = ($event: any) => ((openAccordion).value = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonAccordion), {
                value: "statusAccordion",
                ref_key: "statusGroup",
                ref: statusGroup
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          (_unref(selectedStatusFilter) === _unref(Constants).STATUS_ANY_FILTER)
                            ? (_openBlock(), _createBlock(_unref(IonText), {
                                key: 0,
                                color: "medium"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Status: " + _toDisplayString(_unref(selectedStatusTitle)), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Status: " + _toDisplayString(_unref(selectedStatusTitle)), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonList), { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (onStatusFilterChange(_unref(Constants).ACTIVE_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[61] || (_cache[61] = [
                              _createTextVNode("Active")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (onStatusFilterChange(_unref(Constants).COMPLETED_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[62] || (_cache[62] = [
                              _createTextVNode("Completed")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (onStatusFilterChange(_unref(Constants).STATUS_ANY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[63] || (_cache[63] = [
                              _createTextVNode("Any")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512),
              (_unref(useMYN))
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_unref(IonAccordion), {
                      value: "urgencyAccordion",
                      ref_key: "urgencyGroup",
                      ref: urgencyGroup
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonItem), { slot: "header" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonLabel), null, {
                              default: _withCtx(() => [
                                (_unref(selectedUrgencyFilter) === _unref(Constants).URGENCY_ANY_FILTER)
                                  ? (_openBlock(), _createBlock(_unref(IonText), {
                                      key: 0,
                                      color: "medium"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Urgency: " + _toDisplayString(_unref(selectedUrgencyTitle)), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Urgency: " + _toDisplayString(_unref(selectedUrgencyTitle)), 1)
                                      ]),
                                      _: 1
                                    }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(IonList), { slot: "content" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (onUrgencyFilterChange(_unref(Constants).URGENCY_ANY_FILTER)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[64] || (_cache[64] = [
                                    _createTextVNode("Any")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (onUrgencyFilterChange(_unref(Constants).URGENCY_SOC_FILTER)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[65] || (_cache[65] = [
                                    _createTextVNode("Significant Outcome")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (
                  onUrgencyFilterChange(_unref(Constants).URGENCY_CRITICAL_NOW_FILTER)
                ))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[66] || (_cache[66] = [
                                    _createTextVNode("Critical Now")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (
                  onUrgencyFilterChange(
                    _unref(Constants).URGENCY_OPPORTUNITY_NOW_FILTER
                  )
                ))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[67] || (_cache[67] = [
                                    _createTextVNode("Opportunity Now")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (
                  onUrgencyFilterChange(
                    _unref(Constants).URGENCY_OVER_THE_HORIZON_FILTER
                  )
                ))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[68] || (_cache[68] = [
                                    _createTextVNode("Over the Horizon")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_unref(IonAccordion), {
                      value: "priorityAccordion",
                      ref_key: "priorityGroup",
                      ref: priorityGroup
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonItem), { slot: "header" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonLabel), null, {
                              default: _withCtx(() => [
                                (
                    _unref(selectedPriorityFilter) === _unref(Constants).PRIORITY_ANY_FILTER
                  )
                                  ? (_openBlock(), _createBlock(_unref(IonText), {
                                      key: 0,
                                      color: "medium"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Priority: " + _toDisplayString(_unref(selectedPriorityTitle)), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Priority: " + _toDisplayString(_unref(selectedPriorityTitle)), 1)
                                      ]),
                                      _: 1
                                    }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(IonList), { slot: "content" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[8] || (_cache[8] = ($event: any) => (onPriorityFilterChange(_unref(Constants).PRIORITY_ANY_FILTER)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[69] || (_cache[69] = [
                                    _createTextVNode("Any")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (onPriorityFilterChange(_unref(Constants).PRIORITY_TOP_FILTER)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[70] || (_cache[70] = [
                                    _createTextVNode("Top")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => (onPriorityFilterChange(_unref(Constants).PRIORITY_HIGH_FILTER)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[71] || (_cache[71] = [
                                    _createTextVNode("High")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[11] || (_cache[11] = ($event: any) => (
                  onPriorityFilterChange(_unref(Constants).PRIORITY_MEDIUM_FILTER)
                ))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[72] || (_cache[72] = [
                                    _createTextVNode("Medium")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[12] || (_cache[12] = ($event: any) => (onPriorityFilterChange(_unref(Constants).PRIORITY_LOW_FILTER)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[73] || (_cache[73] = [
                                    _createTextVNode("Low")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonItem), {
                              button: "",
                              onClick: _cache[13] || (_cache[13] = ($event: any) => (
                  onPriorityFilterChange(_unref(Constants).PRIORITY_NEGATIVE_FILTER)
                ))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonLabel), null, {
                                  default: _withCtx(() => _cache[74] || (_cache[74] = [
                                    _createTextVNode("Negative")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512)
                  ])),
              _createVNode(_unref(IonAccordion), {
                value: "starAccordion",
                ref_key: "starGroup",
                ref: starGroup
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          (_unref(selectedStarFilter) === _unref(Constants).STARRED_ANY_FILTER)
                            ? (_openBlock(), _createBlock(_unref(IonText), {
                                key: 0,
                                color: "medium"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Star: " + _toDisplayString(_unref(selectedStarTitle)), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Star: " + _toDisplayString(_unref(selectedStarTitle)), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonList), { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[14] || (_cache[14] = ($event: any) => (onStarFilterChange(_unref(Constants).STARRED_ANY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[75] || (_cache[75] = [
                              _createTextVNode("Any")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[15] || (_cache[15] = ($event: any) => (onStarFilterChange(_unref(Constants).STARRED_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[76] || (_cache[76] = [
                              _createTextVNode("Starred")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[16] || (_cache[16] = ($event: any) => (onStarFilterChange(_unref(Constants).UNSTARRED_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[77] || (_cache[77] = [
                              _createTextVNode("Unstarred")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512),
              _createVNode(_unref(IonAccordion), {
                value: "dueAccordion",
                ref_key: "dueGroup",
                ref: dueGroup
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          (_unref(selectedDueFilter) === _unref(Constants).DUE_ANY_FILTER)
                            ? (_openBlock(), _createBlock(_unref(IonText), {
                                key: 0,
                                color: "medium"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Due: " + _toDisplayString(_unref(selectedDueTitle)), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Due: " + _toDisplayString(_unref(selectedDueTitle)), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonList), { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[17] || (_cache[17] = ($event: any) => (onDueFilterChange(_unref(Constants).DUE_ANY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[78] || (_cache[78] = [
                              _createTextVNode("Any")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[18] || (_cache[18] = ($event: any) => (onDueFilterChange(_unref(Constants).DUE_OVERDUE_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[79] || (_cache[79] = [
                              _createTextVNode("Overdue")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[19] || (_cache[19] = ($event: any) => (onDueFilterChange(_unref(Constants).DUE_BY_TODAY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[80] || (_cache[80] = [
                              _createTextVNode("By Today")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[20] || (_cache[20] = ($event: any) => (onDueFilterChange(_unref(Constants).DUE_TODAY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[81] || (_cache[81] = [
                              _createTextVNode("Today")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[21] || (_cache[21] = ($event: any) => (onDueFilterChange(_unref(Constants).DUE_TOMORROW_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[82] || (_cache[82] = [
                              _createTextVNode("Tomorrow")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[22] || (_cache[22] = ($event: any) => (onDueFilterChange(_unref(Constants).DUE_WEEK_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[83] || (_cache[83] = [
                              _createTextVNode("Within a week")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[23] || (_cache[23] = ($event: any) => (onDueFilterChange(_unref(Constants).DUE_MONTH_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[84] || (_cache[84] = [
                              _createTextVNode("Within a month")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[24] || (_cache[24] = ($event: any) => (onDueFilterChange(_unref(Constants).DUE_3_MONTHS_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[85] || (_cache[85] = [
                              _createTextVNode("Within 3 months")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[25] || (_cache[25] = ($event: any) => (onDueFilterChange(_unref(Constants).DUE_YEAR_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[86] || (_cache[86] = [
                              _createTextVNode("Within a year")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512),
              _createVNode(_unref(IonAccordion), {
                value: "startAccordion",
                ref_key: "startGroup",
                ref: startGroup
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          (_unref(selectedStartFilter) === _unref(Constants).START_ANY_FILTER)
                            ? (_openBlock(), _createBlock(_unref(IonText), {
                                key: 0,
                                color: "medium"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Start: " + _toDisplayString(_unref(selectedStartTitle)), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Start: " + _toDisplayString(_unref(selectedStartTitle)), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonList), { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[26] || (_cache[26] = ($event: any) => (onStartFilterChange(_unref(Constants).START_ANY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[87] || (_cache[87] = [
                              _createTextVNode("Any")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[27] || (_cache[27] = ($event: any) => (onStartFilterChange(_unref(Constants).START_BY_TODAY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[88] || (_cache[88] = [
                              _createTextVNode("By Today")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[28] || (_cache[28] = ($event: any) => (onStartFilterChange(_unref(Constants).START_TODAY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[89] || (_cache[89] = [
                              _createTextVNode("Today")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[29] || (_cache[29] = ($event: any) => (onStartFilterChange(_unref(Constants).START_TOMORROW_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[90] || (_cache[90] = [
                              _createTextVNode("Tomorrow")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[30] || (_cache[30] = ($event: any) => (onStartFilterChange(_unref(Constants).START_WEEK_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[91] || (_cache[91] = [
                              _createTextVNode("Within a week")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[31] || (_cache[31] = ($event: any) => (onStartFilterChange(_unref(Constants).START_MONTH_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[92] || (_cache[92] = [
                              _createTextVNode("Within a month")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[32] || (_cache[32] = ($event: any) => (onStartFilterChange(_unref(Constants).START_3_MONTHS_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[93] || (_cache[93] = [
                              _createTextVNode("Within 3 months")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[33] || (_cache[33] = ($event: any) => (onStartFilterChange(_unref(Constants).START_YEAR_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[94] || (_cache[94] = [
                              _createTextVNode("Within a year")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[34] || (_cache[34] = ($event: any) => (onStartFilterChange(_unref(Constants).START_UNDATED_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[95] || (_cache[95] = [
                              _createTextVNode("Undated")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512),
              _createVNode(_unref(IonAccordion), {
                value: "addedAccordion",
                ref_key: "addedGroup",
                ref: addedGroup
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          (_unref(selectedAddedFilter) === _unref(Constants).ADDED_ANY_FILTER)
                            ? (_openBlock(), _createBlock(_unref(IonText), {
                                key: 0,
                                color: "medium"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Added: " + _toDisplayString(_unref(selectedAddedTitle)), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Added: " + _toDisplayString(_unref(selectedAddedTitle)), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonList), { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[35] || (_cache[35] = ($event: any) => (onAddedFilterChange(_unref(Constants).ADDED_ANY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[96] || (_cache[96] = [
                              _createTextVNode("Any")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[36] || (_cache[36] = ($event: any) => (onAddedFilterChange(_unref(Constants).ADDED_TODAY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[97] || (_cache[97] = [
                              _createTextVNode("Today")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[37] || (_cache[37] = ($event: any) => (onAddedFilterChange(_unref(Constants).ADDED_YESTERDAY_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[98] || (_cache[98] = [
                              _createTextVNode("Yesterday")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[38] || (_cache[38] = ($event: any) => (onAddedFilterChange(_unref(Constants).ADDED_WEEK_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[99] || (_cache[99] = [
                              _createTextVNode("In past week")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[39] || (_cache[39] = ($event: any) => (onAddedFilterChange(_unref(Constants).ADDED_MONTH_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[100] || (_cache[100] = [
                              _createTextVNode("In past month")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[40] || (_cache[40] = ($event: any) => (onAddedFilterChange(_unref(Constants).ADDED_3_MONTHS_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[101] || (_cache[101] = [
                              _createTextVNode("In past 3 months")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[41] || (_cache[41] = ($event: any) => (onAddedFilterChange(_unref(Constants).ADDED_YEAR_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[102] || (_cache[102] = [
                              _createTextVNode("In past year")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[42] || (_cache[42] = ($event: any) => (onAddedFilterChange(_unref(Constants).ADDED_OLDER_FILTER)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[103] || (_cache[103] = [
                              _createTextVNode("Over a year ago")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512),
              (_unref(hasImportedTasks))
                ? (_openBlock(), _createBlock(_unref(IonAccordion), {
                    key: 2,
                    value: "importedAccordion",
                    ref_key: "importedGroup",
                    ref: importedGroup
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonItem), { slot: "header" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => [
                              (_unref(selectedImportedFilter) === _unref(Constants).IMPORTED_ANY_FILTER)
                                ? (_openBlock(), _createBlock(_unref(IonText), {
                                    key: 0,
                                    color: "medium"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Imported: " + _toDisplayString(_unref(selectedImportedTitle)), 1)
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Imported: " + _toDisplayString(_unref(selectedImportedTitle)), 1)
                                    ]),
                                    _: 1
                                  }))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonList), { slot: "content" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: _cache[43] || (_cache[43] = ($event: any) => (onImportedFilterChange(_unref(Constants).IMPORTED_ANY_FILTER)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => _cache[104] || (_cache[104] = [
                                  _createTextVNode("Any")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: _cache[44] || (_cache[44] = ($event: any) => (onImportedFilterChange(_unref(Constants).IMPORTED_TODAY_FILTER)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => _cache[105] || (_cache[105] = [
                                  _createTextVNode("Today")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: _cache[45] || (_cache[45] = ($event: any) => (
                onImportedFilterChange(_unref(Constants).IMPORTED_YESTERDAY_FILTER)
              ))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => _cache[106] || (_cache[106] = [
                                  _createTextVNode("Yesterday")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: _cache[46] || (_cache[46] = ($event: any) => (onImportedFilterChange(_unref(Constants).IMPORTED_WEEK_FILTER)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => _cache[107] || (_cache[107] = [
                                  _createTextVNode("In past week")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: _cache[47] || (_cache[47] = ($event: any) => (onImportedFilterChange(_unref(Constants).IMPORTED_MONTH_FILTER)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => _cache[108] || (_cache[108] = [
                                  _createTextVNode("In past month")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: _cache[48] || (_cache[48] = ($event: any) => (
                onImportedFilterChange(_unref(Constants).IMPORTED_3_MONTHS_FILTER)
              ))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => _cache[109] || (_cache[109] = [
                                  _createTextVNode("In past 3 months")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: _cache[49] || (_cache[49] = ($event: any) => (onImportedFilterChange(_unref(Constants).IMPORTED_YEAR_FILTER)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => _cache[110] || (_cache[110] = [
                                  _createTextVNode("In past year")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: _cache[50] || (_cache[50] = ($event: any) => (onImportedFilterChange(_unref(Constants).IMPORTED_OLDER_FILTER)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => _cache[111] || (_cache[111] = [
                                  _createTextVNode("Over a year ago")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: _cache[51] || (_cache[51] = ($event: any) => (onImportedFilterChange(_unref(Constants).NOT_IMPORTED_FILTER)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => _cache[112] || (_cache[112] = [
                                  _createTextVNode("Not imported")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512))
                : _createCommentVNode("", true),
              _createVNode(_unref(IonAccordion), {
                value: "folderAccordion",
                ref_key: "folderGroup",
                ref: folderGroup
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          (_unref(selectedFolderFilter) === _unref(Constants).FOLDER_ANY_FILTER)
                            ? (_openBlock(), _createBlock(_unref(IonText), {
                                key: 0,
                                color: "medium"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Folder: " + _toDisplayString(_unref(selectedFolderTitle)), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Folder: " + _toDisplayString(_unref(selectedFolderTitle)), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonList), { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[52] || (_cache[52] = ($event: any) => (onFolderFilterChange(_unref(Constants).FOLDER_ANY_FILTER, 'Any')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[113] || (_cache[113] = [
                              _createTextVNode("Any")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(folders), (folder) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: folder.id
                        }, [
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: ($event: any) => (
                  onFolderFilterChange(
                    _unref(Constants).FOLDER_FILTER_PREFIX + folder.id,
                    folder.title
                  )
                )
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(folder.title), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]))
                      }), 128)),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[53] || (_cache[53] = ($event: any) => (
                onFolderFilterChange(_unref(Constants).FOLDER_NONE_FILTER, 'None')
              ))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[114] || (_cache[114] = [
                              _createTextVNode("None")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512),
              _createVNode(_unref(IonAccordion), {
                value: "goalAccordion",
                ref_key: "goalGroup",
                ref: goalGroup
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          (_unref(selectedGoalFilter) === _unref(Constants).GOAL_ANY_FILTER)
                            ? (_openBlock(), _createBlock(_unref(IonText), {
                                key: 0,
                                color: "medium"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Goal: " + _toDisplayString(_unref(selectedGoalTitle)), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Goal: " + _toDisplayString(_unref(selectedGoalTitle)), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonList), { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[54] || (_cache[54] = ($event: any) => (onGoalFilterChange(_unref(Constants).GOAL_ANY_FILTER, 'Any')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[115] || (_cache[115] = [
                              _createTextVNode("Any")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(goals), (goal) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: goal.id
                        }, [
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: ($event: any) => (
                  onGoalFilterChange(
                    _unref(Constants).GOAL_FILTER_PREFIX + goal.id,
                    goal.title
                  )
                )
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(goal.title), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]))
                      }), 128)),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[55] || (_cache[55] = ($event: any) => (onGoalFilterChange(_unref(Constants).GOAL_NONE_FILTER, 'None')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[116] || (_cache[116] = [
                              _createTextVNode("None")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512),
              _createVNode(_unref(IonAccordion), {
                value: "contextAccordion",
                ref_key: "contextGroup",
                ref: contextGroup
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          (_unref(selectedContextFilter) === _unref(Constants).CONTEXT_ANY_FILTER)
                            ? (_openBlock(), _createBlock(_unref(IonText), {
                                key: 0,
                                color: "medium"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Context: " + _toDisplayString(_unref(selectedContextTitle)), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_unref(IonText), { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Context: " + _toDisplayString(_unref(selectedContextTitle)), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonList), { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[56] || (_cache[56] = ($event: any) => (
                onContextFilterChange(_unref(Constants).CONTEXT_ANY_FILTER, 'Any')
              ))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[117] || (_cache[117] = [
                              _createTextVNode("Any")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(contexts), (context) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: context.id
                        }, [
                          _createVNode(_unref(IonItem), {
                            button: "",
                            onClick: ($event: any) => (
                  onContextFilterChange(
                    _unref(Constants).CONTEXT_FILTER_PREFIX + context.id,
                    context.title
                  )
                )
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(context.title), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]))
                      }), 128)),
                      _createVNode(_unref(IonItem), {
                        button: "",
                        onClick: _cache[57] || (_cache[57] = ($event: any) => (
                onContextFilterChange(_unref(Constants).CONTEXT_NONE_FILTER, 'None')
              ))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonLabel), null, {
                            default: _withCtx(() => _cache[118] || (_cache[118] = [
                              _createTextVNode("None")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createVNode(_unref(IonHeader), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonItem), null, {
              default: _withCtx(() => [
                _createVNode(_unref(IonTitle), null, {
                  default: _withCtx(() => _cache[119] || (_cache[119] = [
                    _createTextVNode("Task Filters")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterMenuData.value, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: listKey.value + item.id
          }, [
            (isGroup(item))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(FilterMenuGroup, {
                    itemData: item,
                    onChanged: onGroupChanged
                  }, null, 8, ["itemData"]),
                  (isOpen(item))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: listKey.value + child.id
                          }, [
                            _createVNode(FilterMenuItem, {
                              itemData: child,
                              filterId: child.id,
                              title: child.title,
                              status: child.status,
                              onSelected: onItemSelected
                            }, null, 8, ["itemData", "filterId", "title", "status"])
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(FilterMenuItem, {
                    itemData: item,
                    filterId: item.id,
                    title: item.title,
                    status: item.status,
                    isGroup: item.isGroup,
                    onSelected: onItemSelected
                  }, null, 8, ["itemData", "filterId", "title", "status", "isGroup"])
                ]))
          ]))
        }), 128))
      ]))
}
}

})