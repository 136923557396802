import { removeLastComma } from "@/views/pages/TaskListUtils";
import { TaskObject } from "../TaskObject";
import { Filter } from "./Filter";

/**
 * Combines a list of filters and passes a task as filtered if all of the filters are passing the task
 * child filter can be provided in the constructor and / or added later
 */
export class AllFilter extends Filter {
    public get debugDescription(): string {
        let result = "AllFilter: "
        result += `name: ${this.name}, applies all child filters: `
        if (!this.children?.length) {
            result += 'none'
        }
        for (const child of this.children) {
            result += child.debugDescription + ", "
        }

        return result;
    }

    children: Filter[];
    constructor(name: string, value?: Filter[]) {
        super(name);
        this.children = value ?? new Array<Filter>();
    }

    public add(filter: Filter) {
        this.children.push(filter);
    }

    /**
     * Return true if all child filters return true
     * 
     * @param item 
     */
    public isFiltered(item: TaskObject): boolean {

        let result = true;
        // we can't break out of a forEach loop so we have to do this..
        if (this.children?.length > 0) {
            // console.log(`AllFilter has ${this.children.length} children and applies them to this task`)
            for (let i = 0; i < this.children.length; i++) {
                if (!this.children[i]?.isFiltered(item)) {
                    // console.log(`AllFilter child ${this.children[i].description} blocks task ${JSON.stringify(item)}`);
                    result = false;
                    break;
                }
            }
        }
        // else {
        //     console.log(`AllFilter has no children so passes this task`)
        // }
        // if (result) console.log(`AllFilter ✅ passes item ${JSON.stringify(item)}`)
        return result;
    }



    public get length() {
        return this.children?.length ?? 0;
    }

    // public get description() { return 'All Filters'}

    public get description() {
        // console.log(`AllFilter description sees ${this.children.length} children`)
        if (this.children.length == 0) {
            return "";
        }
        let result = '';
        for (const child of this.children) {

            // const child = this.children[i];
            const desc = child.description + ', ';
            result += desc;
        }
        // console.log(`AllFilter has description: ${result}`)
        return removeLastComma(result);
    }

    public get debugDecription() {
        return this.description
    }


}