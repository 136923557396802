import { SortDirection } from "@/models/SortDirection";
import { TaskObject } from "@/models/TaskObject";
import { TaskComparer } from "./TaskComparer";

export class TaskStartComparer extends TaskComparer {
  constructor(direction: SortDirection) {
    super(direction);
  }
  compare = (a: TaskObject, b: TaskObject) => {
    const ascending = this.direction === SortDirection.Ascending;

    if (a.start == b.start) {
      return 0;
    }
    if (a.start == null) {
      return 1;
    }
    if (b.start == null) {
      return -1;
    }
    if (a.start < b.start) {
      const result = ascending ? -1 : 1;

      return result;
    }

    const defaultValue = ascending ? 1 : -1;
    return defaultValue;
  };
}
