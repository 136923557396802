import { SortDirection } from "@/models/SortDirection";
import { TaskObject } from "@/models/TaskObject";
import { TaskComparer } from "./TaskComparer";

export class TaskTitleComparer extends TaskComparer {
  constructor(direction: SortDirection) {
    super(direction);
  }
  compare = (a: TaskObject, b: TaskObject) => {
    const ascending = this.direction === SortDirection.Ascending;
    if (a.title == b.title) return 0;
    if (!a.title) return ascending ? -1 : 1;
    if (!b.title) return ascending ? 1 : -1;
    const rawCompare = a.title.localeCompare(b.title);
    const descendingMultiplier = ascending ? 1 : -1;
    return rawCompare * descendingMultiplier;
  };
}
