import { Filter } from "./Filter";
import { TaskObject } from "../TaskObject";
import { DateFilterOption } from "../filters/DateFilterOption";
import { startOfDay, addDays } from "date-fns";
import { addMonths } from "date-fns";
import { addYears } from "date-fns";

/**
 * Filters tasks by start date, using friendly terms like 'overstart', 'today' and 'undated'
 */
export class AddedDateFilter extends Filter {
  public get debugDescription(): string {
    return `Added date filter: ${this.option}`;
  }
  option: DateFilterOption;
  constructor(option: DateFilterOption) {
    super(option);
    this.option = option;
  }

  public isFiltered(task: TaskObject): boolean {
    let result = false;
    const now = new Date();
    const today = startOfDay(now);
    const added = task.added;

    if (!added) {
      return this.option === DateFilterOption.Undated;
    }

    switch (this.option) {
      case DateFilterOption.Today:
        result = !!added && added > today && added < addDays(today, 1);
        break;
      case DateFilterOption.Yesterday:
        result = !!added && added > addDays(today, -1) && added < today;
        break;
      case DateFilterOption.Week:
        result =
          !!added && added < addDays(today, 1) && added > addDays(today, -7);
        break;
      case DateFilterOption.Month:
        result =
          !!added && added < addDays(today, 1) && added > addMonths(today, -1);
        break;
      case DateFilterOption.Quarter:
        result =
          !!added && added < addDays(today, 1) && added > addMonths(today, -3);
        break;
      case DateFilterOption.Year:
        result =
          !!added && added < addDays(today, 1) && added > addYears(today, -1);
        break;
      case DateFilterOption.Older:
        result = !!added && added < addYears(today, -1);
        break;
    }

    // console.log(`Added date filter for date option ${this.value} gives result ${result} for task ${item.title} with added date ${added}`)
    return result;
  }

  /**
   * Calculates a description from the comparison value
   *
   * @remarks
   * Quarter is translated to '3 months'
   */
  public get description() {
    switch (this.option) {
      case DateFilterOption.Undated:
        return "no added date";
      case DateFilterOption.Today:
        return "added today";
      case DateFilterOption.Yesterday:
        return "added yesterday";
      case DateFilterOption.Week:
        return "added within a week";
      case DateFilterOption.Month:
        return "added within a month";
      case DateFilterOption.Quarter:
        return "added within 3 months";
      case DateFilterOption.Year:
        return "added within a year";
      case DateFilterOption.Older:
        return "added more than a year ago";
      default:
        return undefined;
    }
  }
}
