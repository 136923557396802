// Sorter.ts
import { Constants } from "@/constants/constants";
import { ContextObject } from "@/models/ContextModel";
import { FolderObject } from "@/models/FolderModel";
import { GoalObject } from "@/models/GoalModel";
import { NoteObject } from "@/models/NoteObject";
import { TaskObject } from "@/models/TaskObject";
import { Property } from "@/models/Property";
import { SortDirection } from "@/models/SortDirection";

// import Pinia stores
import { useContextsStore } from "@/stores/useContextsStore";
import { useFeatureFlagsStore } from "@/stores/useFeatureFlagsStore";
import { useFoldersStore } from "@/stores/useFoldersStore";
import { useGoalsStore } from "@/stores/useGoalsStore";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/useSettingsStore";
// const foldersStore = useFoldersStore();
// const { folderFromId, } = foldersStore;
// const goalsStore = useGoalsStore();
// const { goalFromId, } = goalsStore;

/**
 * Describes what property to sort on, and if the sort is ascending
 */
export class SortDescriptor {
  property!: string;
  ascending!: boolean;
}

export class Sorter {
  static priorityOrder = [
    Constants.PRIORITY_NEGATIVE,
    Constants.PRIORITY_LOW,
    Constants.PRIORITY_MEDIUM,
    Constants.PRIORITY_HIGH,
    Constants.PRIORITY_TOP,
  ];

  static urgencyOrder = [
    Constants.URGENCY_OVER_THE_HORIZON,
    Constants.URGENCY_OPPORTUNITY_NOW,
    Constants.URGENCY_CRITICAL_NOW,
    Constants.URGENCY_SOC,
  ];

  static compareTasksByTitle = (
    a: TaskObject,
    b: TaskObject,
    ascending: boolean
  ) => {
    if (a.title == b.title) {
      return 0;
    }
    if (a.title == null) {
      return ascending ? -1 : 1; // a < b
    }
    if (b.title == null) {
      return ascending ? 1 : -1;
    }

    return new Intl.Collator().compare(a.title, b.title) * (ascending ? 1 : -1);
  };

  static compareTasksByPriority = (
    a: TaskObject,
    b: TaskObject,
    ascending: boolean
  ) => {
    // console.log(`compare tasks by priority for priorities ${a.priority} and ${b.priority}`)
    if (a.priority == b.priority) {
      // console.log(`they are equal, return 0`)
      return 0;
    }
    if (a.priority == null) {
      // console.log(`a has no priority set, return -1`)
      return ascending ? -1 : 1; // a < b
    }
    if (b.priority == null) {
      // console.log(`b has no priority set, return +1`)
      return ascending ? 1 : -1;
    }
    if (
      Sorter.priorityOrder.indexOf(a.priority) <
      Sorter.priorityOrder.indexOf(b.priority)
    ) {
      // console.log(`a is earlier in priority order, return -1`)
      // console.log(`a is not earlier in priority order, return +1`)
      return ascending ? -1 : 1;
    }

    // console.log(`a must be greater, return 1`)
    return ascending ? 1 : -1;
  };

  static compareTasksByUrgency = (
    a: TaskObject,
    b: TaskObject,
    ascending: boolean,
    autoUrgency: boolean
  ) => {
    // console.log(`compare tasks by urgency for urgencies ${a.urgency} and ${b.urgency}`)
    const ua = a.getUrgency(autoUrgency);
    const ub = b.getUrgency(autoUrgency);

    // console.log(`Sorter sees urgencySortIndex for ${a.title} is ${ua} and for ${b.title} is ${ub}`)
    if (ua === ub) return 0;
    if (!ua && !ub) return 0;

    if (Sorter.urgencyOrder.indexOf(ua) < Sorter.urgencyOrder.indexOf(ub))
      return ascending ? -1 : 1;
    return ascending ? 1 : -1;
  };

  static compareTasksByDueDate = (
    a: TaskObject,
    b: TaskObject,
    ascending: boolean
  ) => {
    if (a.due == b.due) {
      return 0;
    }
    if (a.due == null) {
      return 1; // we want a null date to go to the end of the list. was ascending ? -1: 1 // a < b
    }
    if (b.due == null) {
      return -1; // we want a null date to go to the end of the list. was ascending? 1 : -1;
    }
    if (a.due < b.due) return ascending ? -1 : 1;
    return ascending ? 1 : -1;
  };

  static compareTasksByStartDate = (
    a: TaskObject,
    b: TaskObject,
    ascending: boolean
  ) => {
    if (a.start == b.start) {
      return 0;
    }
    if (a.start == null) {
      return 1; // we want a null date to go to the end of the list. was ascending ? -1: 1 // a < b
    }

    if (b.start == null) {
      return -1; // we want a null date to go to the end of the list. was ascending? 1 : -1;
    }
    if (a.start < b.start) return ascending ? -1 : 1;
    return ascending ? 1 : -1;
  };

  static compareTasksByFolder = (
    a: TaskObject,
    b: TaskObject,
    ascending: boolean
  ) => {
    if (a.folderId == b.folderId) {
      return 0;
    }
    if (a.folderId == null) {
      return ascending ? -1 : 1; // a < b
    }
    if (b.folderId == null) {
      return ascending ? 1 : -1;
    }

    const foldersStore = useFoldersStore();
    const bfname = foldersStore.folderFromId(b.folderId)?.title || "";
    const afname = foldersStore.folderFromId(a.folderId)?.title || "";

    // const bIndex = store.getters.folderIndexFromId(b.folderId)
    // const aIndex = store.getters.folderIndexFromId(a.folderId)

    return afname.localeCompare(bfname) * (ascending ? 1 : -1);

    // if (afname < bfname) return -1
    // return 1
  };

  static compareTasksByGoal = (
    a: TaskObject,
    b: TaskObject,
    ascending: boolean
  ) => {
    if (a.goalId == b.goalId) {
      return 0;
    }
    if (a.goalId == null) {
      return ascending ? -1 : 1; // a < b
    }
    if (b.goalId == null) {
      return ascending ? 1 : -1;
    }

    const goalsStore = useGoalsStore();

    const bname = goalsStore.goalFromId(b.goalId)?.title || "";
    const aname = goalsStore.goalFromId(a.goalId)?.title || "";

    return aname.localeCompare(bname) * (ascending ? 1 : -1);

    // const bIndex = store.getters.goalIndexFromId(b.goalId)
    // const aIndex = store.getters.goalIndexFromId(a.goalId)

    // if (aIndex < bIndex) return -1
    // return 1
  };

  static compareTasksByContext = (
    a: TaskObject,
    b: TaskObject,
    ascending: boolean
  ) => {
    if (a.contextId == b.contextId) {
      return 0;
    }
    if (a.contextId == null) {
      return ascending ? -1 : 1; // a < b
    }
    if (b.contextId == null) {
      return ascending ? 1 : -1;
    }

    const contextsStore = useContextsStore();

    const bname = contextsStore.contextFromId(b.contextId)?.title || "";
    const aname = contextsStore.contextFromId(a.contextId)?.title || "";

    return aname.localeCompare(bname) * (ascending ? 1 : -1);

    // const bIndex = store.getters.goalIndexFromId(b.goalId)
    // const aIndex = store.getters.goalIndexFromId(a.goalId)

    // if (aIndex < bIndex) return -1
    // return 1
  };

  static copyAndSortTasks(
    source: TaskObject[],
    autoUrgency: boolean,
    descriptors?: Array<SortDescriptor>
  ) {
    const copy = [...source];
    Sorter.sortTasksInPlace(copy, autoUrgency, descriptors);
    return copy;
  }

  static copyAndSortTasksBy(
    source: TaskObject[],
    property: Property,
    direction: SortDirection
  ) {
    const sort1 = new SortDescriptor();
    const { autoUrgency } = useSettingsStore();
    sort1.property = property;
    sort1.ascending = direction === SortDirection.Ascending;
    const copy = [...source];
    Sorter.sortTasksInPlace(copy, !!autoUrgency, [sort1]);
    return copy;
  }

  static sortTasksByPriorityInPlace(
    source: TaskObject[],
    direction: SortDirection
  ) {
    source.sort((a, b) => {
      return this.compareTasksByPriority(
        a,
        b,
        direction === SortDirection.Ascending
      );
    });
  }

  static copyAndSortTasksByPriority(
    source: TaskObject[],
    direction: SortDirection
  ) {
    const copy = [...source];
    Sorter.sortTasksByPriorityInPlace(copy, direction);
    return copy;
  }

  static sortTasksInPlace(
    source: Array<TaskObject>,
    autoUrgency: boolean,
    descriptors?: Array<SortDescriptor>
  ) {
    //  console.log(`Sorter is sorting tasks with sort descriptors as follows: ${JSON.stringify(descriptors)}. TaskObject.autoUrgency is ${TaskObject.autoUrgency}`)
    // console.log(`sortTasksInPlace is starting with a source array containing ${source.length} tasks`)
    if (!descriptors || descriptors.length < 0) {
      // console.log(`sortTasksInPlace has no descriptors so it does nothing`)
      return source; // nothing to do
    }

    // construct an array of comparison functions

    const comparators: {
      (
        a: TaskObject,
        b: TaskObject,
        ascending: boolean,
        autoUrgency?: boolean
      ): number;
    }[] = [];
    const directions: boolean[] = [];

    descriptors.forEach((descriptor) => {
      switch (descriptor.property) {
        case "None":
        case "none":
          //  console.log(`Sorter sees 'none' so skips comparator`)
          break; // no comparator

        case "title":
        case "Title":
          comparators.push(Sorter.compareTasksByTitle);
          directions.push(descriptor.ascending);
          break;
        case "priority":
        case "Priority":
          //  console.log(`Sorter is adding a priority comparator`)
          comparators.push(Sorter.compareTasksByPriority);
          directions.push(descriptor.ascending);
          break;
        case "urgency":
        case "Urgency":
          //  console.log(`Sorter is adding an urgency comparator`)
          comparators.push(Sorter.compareTasksByUrgency);
          directions.push(descriptor.ascending);
          break;
        case "due":
        case "Due":
          comparators.push(Sorter.compareTasksByDueDate);
          directions.push(descriptor.ascending);
          break;
        case "start":
        case "Start":
          comparators.push(Sorter.compareTasksByStartDate);
          directions.push(descriptor.ascending);
          break;
        case "folder":
        case "Folder":
          comparators.push(Sorter.compareTasksByFolder);
          directions.push(descriptor.ascending);
          break;
        case "goal":
        case "Goal":
          comparators.push(Sorter.compareTasksByGoal);
          directions.push(descriptor.ascending);
          break;
        case "context":
        case "Context":
          comparators.push(Sorter.compareTasksByContext);
          directions.push(descriptor.ascending);
          break;
      }
    });

    if (comparators.length == 0) {
      // console.log(`sortTasksInPlace has no comparators so it does nothing`)
      return source; // nothing to do
    }

    // do the sort

    //  console.log(`Sorter has constructed ${comparators.length} comparators`)
    //  console.log(`Sorter sees autoUrgency is ${autoUrgency}`)

    source.sort((a, b) => {
      // call the comparators one by one
      // a comparator with a non-zero result stops the loop

      const featureFlagsStore = useFeatureFlagsStore();
      const { sortUndatedAtEndFlag } = storeToRefs(featureFlagsStore);
      for (let i = 0; i < comparators.length; i++) {
        // we have a feature flag sortUndatedAtEndFlag which governs whether or not we always sort undated tasks at the of of the task list.
        // this works by telling the dueDate and startDate comparators if we are sorting ascending or descending.
        // previously we sorted by ascending and reversed the sort for descending
        // so when the feature flag is not set, we tell the comparators to sort ascending, then reverse the sort order

        let result: number = comparators[i](
          a,
          b,
          sortUndatedAtEndFlag.value ? directions[i] : true,
          autoUrgency
        );
        // console.log(`sort comparator returns ${result}`)
        if (result != 0) {
          if (!directions[i] && !sortUndatedAtEndFlag.value) {
            // direction is true when ascending, otherwise it's false
            // we are descending, so invert the result
            // except when sortUndatedAtEndFlag is set, in which case we don't need to do the inversion

            result *= -1;
            // console.log(`sort is descending for descriptor ${JSON.stringify(descriptors[i])} so result is reversed to  ${result}`)
          }

          // at this point we have done the comparison and reversed it if necessary when the sort is descending

          // now we want to make sure if the sort is a date and one of the dates is null, the null date

          // console.log(`sort sees inequality and uses result  ${result}`)

          return result;
        }
      }

      // all comparators have been applied but none found inequality
      // so we can return zero for equality

      return 0;
    });

    //  console.log(`Sorter has sorted tasks`)

    // console.log(`sortTasksInPlace completes with ${source.length} tasks`)
    return source;
  } // end of sortTasksInPlace

  // Sort in priority order...
  // const startTime = Date.now()
  //   source.sort((a, b) => {
  //     if (a.priority == b.priority) { // loose comparison with ==
  //       if (a.due == b.due) {
  //         // priority and due date are the same
  //         // sort by start date descending so later start dates appear on top
  //         if (a.start) {
  //           if (b.start) {
  //             if(a.start == b.start) {
  //               return 0
  //             }
  //             if (a.start > b.start) {
  //               return -1
  //             }
  //             return 1
  //           } else {
  //             return -1 // a has start date and b doesn't, so b comes later
  //           }

  //         } else {
  //           return 1; // a has no start date so it sorts later
  //         }
  //     }
  //     if (a.due) {
  //       if (b.due) {
  //         if (a.due > b.due) {
  //           return 1;
  //         }
  //         return -1;
  //       } else {
  //         return -1; // a has a due date and b doesn't, so b comes later
  //       }
  //     } else {
  //       return 1; // a has no due date so it sorts later
  //     }

  //   }

  //     // when we get here, priorities are different so we can ignore due and start dates
  //     if (a.priority) {
  //     if (!b.priority) return -1
  //     if (this.priorityOrder.indexOf(a.priority) < this.priorityOrder.indexOf(b.priority)) return -1
  //     return 1
  //   }
  //   if (b.priority) return 1
  //   return 0
  // });

  // const endTime = Date.now()
  // const duration = endTime - startTime
  // console.log(`Sort at ${Date.now()}, took ${duration}ms to sort ${source?.length ?? 0} tasks`)

  // } // end of sortTasksInPlace

  static copyAndSortNotes(
    source: NoteObject[],
    property: string,
    direction: string
  ) {
    const copy = [...source];
    Sorter.sortNotesInPlace(copy, property, direction);
    return copy;
  }

  static sortNotesInPlace(
    source: Array<NoteObject>,
    property: string,
    direction: string
  ) {
    if (!direction) {
      return; // nothing to do
    }

    const reverser = (direction === "ascending" && 1) || -1; // to multiply the comparator

    if (property === "title" || property === Constants.SORT_ALPHA) {
      source.sort((a, b) => {
        return a.title.localeCompare(b.title) * reverser;
      });
      return;
    }

    if (property === "modified" || property === "Modified") {
      source.sort((a, b) => {
        return (
          (!!a.modified && !!b.modified && a.modified > b.modified
            ? 1
            : a.modified < b.modified
            ? -1
            : 0 || // both modified
              (!a.modified && 1) || // only a modified
              (!b.modified && -1)) * // only b modified
          reverser
        );
      });
    }
  }

  static copyAndSortFolders(source: FolderObject[]) {
    const copy = [...source];
    Sorter.sortFoldersInPlace(copy);
    return copy;
  }
  static sortFoldersInPlace(source: Array<FolderObject>) {
    source.sort((a, b) =>
      (a.title?.toLowerCase() ?? "").localeCompare(b.title?.toLowerCase() ?? "")
    );
  }

  static copyAndSortGoals(source: GoalObject[]) {
    const copy = [...source];
    Sorter.sortGoalsInPlace(copy);
    return copy;
  }

  static sortGoalsInPlace(source: Array<GoalObject>) {
    source.sort((a, b) =>
      (a.title?.toLowerCase() ?? "").localeCompare(b.title?.toLowerCase() ?? "")
    );
  }

  static copyAndSortContexts(source: ContextObject[]) {
    const copy = [...source];
    Sorter.sortContextsInPlace(copy);
    return copy;
  }

  static sortContextsInPlace(source: Array<ContextObject>) {
    source.sort((a, b) =>
      (a.title?.toLowerCase() ?? "").localeCompare(b.title?.toLowerCase() ?? "")
    );
  }

  static compareTasks(a: TaskObject, b: TaskObject, byProperty: string) {
    // extract properties by property name
    const ap = (a as any)[byProperty];
    const bp = (b as any)[byProperty];

    switch (byProperty) {
      case "priority":
        if (!a.priority && !b.priority) {
          return 0;
        }
        if (a.priority === b.priority) {
          return 0;
        }

        if (!a.priority) {
          return 1;
        }

        if (!b.priority) {
          return -1;
        }

        if (
          Sorter.priorityOrder.indexOf(a.priority ?? Constants.PRIORITY_LOW) >
          Sorter.priorityOrder.indexOf(b.priority ?? Constants.PRIORITY_LOW)
        ) {
          return 1;
        }
        return -1;

      case "due":
        // if neither task has due set, they are equal
        if (!a.due && !b.due) {
          return 0;
        }
        if (a.due === b.due) {
          return 0;
        }

        // if one task has no due date and the other does, we want the one without the due date to sort later
        if (a.due) {
          if (b.due) {
            if (a.due > b.due) {
              return 1;
            }
            return -1;
          } else {
            return -1; // a has a due date and b doesn't, so b comes later
          }
        }
        return 1; // a has no due date so it sorts later

      default:
        if (!ap && !bp) {
          return 0;
        }
        if (ap === bp) {
          return 0;
        }

        if (ap) {
          if (bp) {
            if (ap > bp) {
              return 1;
            }
            return -1;
          } else {
            return -1;
          }
        }
        return 1;
    }
  }
}
