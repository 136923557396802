import { addDays, addMonths, addYears } from "date-fns";
import startOfDay from "date-fns/startOfDay";
import { DateFilterOption } from "../filters/DateFilterOption";
import { TaskObject } from "../TaskObject";
import { Filter } from "./Filter";

export class DueDateFilter extends Filter {
  public get debugDescription(): string {
    return `Due date filter: option ${this.value}`;
  }
  value: DateFilterOption;
  constructor(value: DateFilterOption) {
    super(value);
    this.value = value;
  }

  public isFiltered(task: TaskObject): boolean {
    let result = false;
    const now = new Date();
    const today = startOfDay(now);
    const due = task.due;

    if (!due) {
      return this.value === DateFilterOption.Undated;
    }

    switch (this.value) {
      case DateFilterOption.Overdue:
        result = due < today;
        break;
      case DateFilterOption.Today:
        result = due > today && due < addDays(today, 1);
        break;
      case DateFilterOption.ByToday:
        result = due < addDays(today, 1);
        break;
      case DateFilterOption.Tomorrow:
        result = due > addDays(today, 1) && due < addDays(today, 2);
        break;
      case DateFilterOption.Week:
        result = due > today && due < addDays(today, 7);
        break;
      case DateFilterOption.Month:
        result = due > today && due < addMonths(today, 1);
        break;
      case DateFilterOption.Quarter:
        result = due > today && due < addMonths(today, 3);
        break;
      case DateFilterOption.Year:
        result = due > today && due < addYears(today, 1);
        break;
    }

    return result;
  }

  public get description(): string {
    switch (this.value) {
      case DateFilterOption.Undated:
        return "due undated";
      case DateFilterOption.Overdue:
        return "overdue";
      case DateFilterOption.Today:
        return "due today";
      case DateFilterOption.ByToday:
        return "due by today";
      case DateFilterOption.Tomorrow:
        return "due tomorrow";
      case DateFilterOption.Week:
        return "due within a week";
      case DateFilterOption.Month:
        return "due within a month";
      case DateFilterOption.Quarter:
        return "due within 3 months";
      case DateFilterOption.Year:
        return "due within a year";
    }
  }
}
