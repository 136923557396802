import * as Sentry from "@sentry/vue";

const isDebugging =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";
export class Logger {
  static log(message: string) {
    if (isDebugging) {
      // eslint-disable-next-line no-console
      console.log(message);
      return;
    }
    // eslint-disable-next-line no-console
    console.log(message);
  }

  static logError(context: string, error?: any) {
    const errorMessage = `${context}: ${error?.message ?? error}`;
    // eslint-disable-next-line no-console
    console.log(errorMessage);

    Sentry.captureException(error, {
      contexts: {
        context: { context },
      },
    });
  }
}
