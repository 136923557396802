import * as Sentry from "@sentry/vue";
import { TaskObject } from "../TaskObject";
import { Filter } from "./Filter";


export class TextFilter extends Filter {
    public get debugDescription(): string {
        return `Textfilter ${this.name}: ${this.value}`
    }
    value: string;
    constructor(value: string) {
        super(value);
        this.value = value;
    }

    public isFiltered(item: TaskObject): boolean {
        try {
            if (!item.title || !this.value) {
                return false;
            }
            if (item.title.toString().toLowerCase().indexOf(this.value.toString().toLowerCase()) >= 0) {
                return true;
            }
            if (!item.note) {
                return false;
            }
            if (item.note.toString().toLowerCase().indexOf(this.value.toString().toLowerCase()) >= 0) {
                return true;
            }
            return false;
        } catch (error) {
            // console.error(`TextFilter error ${error.message}. Title is ${item.title} and value is ${this.value}`)
            // Sentry.captureMessage(`TextFilter error ${error.message}`)
            Sentry.captureException(error, {
                tags: {
                    section: "TextFilter",
                },
            })
        }
    }

    public get description() {
        return this.value;
    }


}