export function removeLastComma(text: string) {
    const trimmed = text.trim();
    let result = trimmed;
    if (trimmed.endsWith(",")) {
        result = trimmed.substring(0, trimmed.length - 1);
    }
    return result;
}

export function truncateString(str: string, maxlength: number) {
    if (str.length > maxlength) {
        return str.slice(0, maxlength - 3) + '...';
    }
    return str;
}
