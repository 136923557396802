import * as Sentry from "@sentry/vue";
import { createApp, markRaw } from "vue";

import App from "./App.vue";
import router from "./router";

import { createPinia, PiniaPluginContext } from "pinia";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */

import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";
import "../src/style/normalize.css"; // my copy to fix hr etc

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/display.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";

/* Theme variables */
import "./theme/variables.css";

import "./assets/css/global.css"; // Import my global CSS file

import "./firebase/firebase"; // This initializes Firebase

import { useFirebaseAuth, db } from "./firebase/firebase-auth";

const { authCheck } = useFirebaseAuth();

import "./registerServiceWorker";

import VCalendar from "v-calendar";

import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { Logger } from "./helpers/Logger";

let routerHasInitialised: boolean;

const pinia = createPinia();
pinia.use(({ store }: PiniaPluginContext) => {
  store.router = markRaw(router);
});
const app = createApp(App)
  .use(IonicVue)
  .use(pinia)
  .use(VueVirtualScroller)
  .use(VCalendar, {
    componentPrefix: "vc",
  });

Sentry.init({
  release: `taskangel-online ${process.env.VUE_APP_VERSION || "0"}`,
  app,
  dsn: "https://8fc4298c063a44f8a65152d198aee684@o344095.ingest.sentry.io/5699281",
  ignoreErrors: ["TypeError: Load failed"],
  sampleRate: 1.0,
  tracesSampleRate: 1.0,
});

authCheck()
  .then(() => {
    if (!routerHasInitialised) {
      routerHasInitialised = true;
      app.use(router);
      return router.isReady();
    }
    return;
  })
  .then(() => {
    app.mount("#app");
  })
  .catch((error) => {
    router.push({ name: "login" });
  });
