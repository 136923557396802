/**
 * A notification object to use when popping up messages to the user
 * 
 * 
 */


export class MyNotification {
    id: number;
    type: string;
    message: string;

    static nextId = 1


    /**
     * 
     * @param type {string} - the type of the notification
     * @param message {string} - the message to show to the user
     */
    constructor(type: string, message: string){
        this.type = type
        this.message = message
        this.id = MyNotification.nextId++
    }

    static fromError(error: Error, context?: string): MyNotification {
        const message = `Error ${context ??  ''}: ${error?.message ?? error ?? 'unknown'}`
        return new MyNotification('error', message)
    }

}