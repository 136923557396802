export enum RepeatPattern {
  Never = "Never",
  Daily = "Daily",
  Weekdays = "Weekdays",
  Weekly = "Weekly",
  Biweekly = "Biweekly",
  Monthly = "Monthly",
  Bimonthly = "Bimonthly",
  Quarterly = "Quarterly",
  Semiannually = "Semiannually",
  Yearly = "Yearly",
  WithParent = "WithParent",
}
