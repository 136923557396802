<template>
  <ion-header>
    <ion-item>
      <ion-title>Note Filters</ion-title>
    </ion-item>
  </ion-header>
  <!-- <p>NoteFilterPanel shows its items here</p> -->
  <div v-if="filterMenuData">
    <div v-for="item in filterMenuData" :key="listKey + item.id + item.status">
      <!-- <p>{{ item.id }}</p> -->
      <div v-if="isGroup(item)">
        <filter-menu-group
          :itemData="item"
          @changed="onGroupChanged($event)"
        ></filter-menu-group>
        <div v-if="isOpen(item)">
          <div v-for="child in item.children" :key="listKey + child.id">
            <filter-menu-item
              isForNotes
              :itemData="child"
              :filterId="child.id"
              :title="child.title"
              :status="child.status"
              @changed="onItemChanged($event)"
              @selected="onItemSelected($event)"
            ></filter-menu-item>
          </div>
        </div>
      </div>
      <div v-else>
        <filter-menu-item
          isForNotes
          :itemData="item"
          :filterId="item.id"
          :title="item.title"
          :status="item.status"
          :isGroup="item.isGroup"
          :count="item.count"
          @changed="onItemChanged($event)"
          @selected="onItemSelected($event)"
        ></filter-menu-item>
      </div>
    </div>
  </div>
  <div v-else>
    <p>NoteFilterPanel has no filterMenuData</p>
  </div>
</template>
<script lang="ts" setup>
import { Constants } from "@/constants/constants";
import { MenuGroupObject } from "@/models/MenuGroupObject";
import { MenuItemObject } from "@/models/MenuItemObject";
import {
  // IonButtons,
  IonHeader,
  IonItem,
  // IonToolbar,
  // IonButton,
  // IonIcon,
  // IonLabel,
  IonTitle,
} from "@ionic/vue";
import {
  // computed,
  defineEmits,
  onBeforeUnmount,
  onMounted,
  // Ref,
  ref,
} from "vue";
import FilterMenuItem from "../components/FilterMenuItem.vue";
// import { useStore } from "vuex";
// import { NoteFilter } from "@/models/filters/NoteFilter";
import FilterMenuGroup from "./FilterMenuGroup.vue";

import { useFoldersStore } from "@/stores/useFoldersStore";
import { useNotesStore } from "@/stores/useNotesStore";
import { useSettingsStore } from "@/stores/useSettingsStore";
import { storeToRefs } from "pinia";

// setup: (_, ctx: SetupContext) => {
// const isRefreshing = ref(false)

// Pinea store
const settings = useSettingsStore();
// const filterStore = useFiltersStore();
// const store = useStore();
const {
  notesSearchText,
  sortNotesBy,
  sortNotesDirection,
  currentNoteFilterId,
  openNoteFilterGroup,
} = storeToRefs(settings);

const emit = defineEmits(["selected"]);

// const { loadSettings, } = settings;

const notesStore = useNotesStore();
const { notes } = storeToRefs(notesStore);

// const { countNotesFilteredBy } = filterStore;

// list data for menu items

const filterMenuData = ref(null);

const makeMenuData = async () => {
  // console.log(`NoteFilterPanel makeMenuData is making menu data`);
  // console.log(
  //   `NoteFilterPanel makeMenuData sees settingsLoaded is ${settingsLoaded.value} and settingsLoading is ${settingsLoading.value}`
  // );

  // if (!settingsLoaded.value && !settingsLoading.value) {
  //   // console.log(`NoteFilterPanel asks for settings to be loaded`);
  //   await loadSettings();
  // }
  const result = Array<MenuItemObject>();
  result.push(
    new MenuItemObject(
      Constants.ALL_NOTES_FILTER,
      "All Notes",
      notes.value?.length ?? 0
    )
  );

  // make folder filters
  const folderMenuItems = new Array<MenuItemObject>();

  const noFolderNotes = notes.value.filter((n) => !n.folderId);
  // console.log(`NoteFilterPanel sees no-folder notes ${JSON.stringify(noFolderNotes)}`)

  folderMenuItems.push(
    new MenuItemObject(
      Constants.FOLDER_NONE_NOTE_FILTER,
      Constants.INBOX_TITLE,
      // null,
      noFolderNotes.length
    )
  );

  // console.log(`NoteFilterPanel is calling vuex to get folders`);
  const folders = useFoldersStore().folders;
  // console.log(`NoteFilterPanel called vuex and got ${folders.length} folders`);
  if (folders.length > 0) {
    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i];
      const folderId = folder.id;
      const notesInFolder = notes.value.filter((n) => n.folderId === folderId);

      const menuItem = new MenuItemObject(
        Constants.FOLDER_NOTE_FILTER_PREFIX + folderId,
        folder.title,
        // null,
        notesInFolder.length
      );
      folderMenuItems.push(menuItem);
    }
  }

  // console.log(
  //   `NoteFilterPanel has made ${folderMenuItems.length} folder menu items and is making a folder group`
  // );

  const folderGroup = new MenuGroupObject(
    Constants.FOLDER_GROUP_NOTE_FILTER,
    "Folders",
    openNoteFilterGroup.value === Constants.FOLDER_GROUP_NOTE_FILTER
      ? "open"
      : "closed",
    folderMenuItems
  );

  result.push(folderGroup);

  // console.log(`NoteFilterPanel made filter menu data ${JSON.stringify(result)}`);
  // return filter menu data
  return result;
};

// filterMenuData.value = await makeMenuData();

/**
 * A key used to cause the list to refresh itself
 *
 * @remarks
 * Each item in the template is given a key made up of listKey and a filter id.
 * We can refresh the list by incrementing the list key.
 *
 */
const listKey = ref(0);

// computed properties

// functions

function isGroup(item: MenuItemObject) {
  return item.id.indexOf("group") >= 0;
}

function isOpen(item: MenuItemObject) {
  return item.id === openNoteFilterGroup.value;
}

// const filterMenuLength = computed(()=>{
//   return filterMenuData.value?.length ?? 0;
// })

// events

/**
 * Handle All Notes button
 *
 * @remarks
 * This clears the filters and then applies presets in Settings
 */
// const handleAllNotes = async () => {
//   // console.log(`Filter panel sees AllNotes filter tapped`)
//   const settings = useSettingsStore();
//   await settings.setCurrentNoteFilterId(Constants.ALL_NOTES_FILTER);
// };

const onItemSelected = async (ev: any) => {
  // console.log(`Note Filter panel calls store to select filter: ${ev.id}`);
  notesSearchText.value = "";
  sortNotesBy.value = Constants.SORT_ALPHA;
  sortNotesDirection.value = "ascending";
  currentNoteFilterId.value = ev.id;
  notesSearchText.value = "";

  emit("selected", ev.id);
};

/**
 * Handle when use clicks on open/close for a filter group.
 *
 * @remarks
 * There can be only one filter group open at once. This is saved in settings.
 * This function changes that settings value
 */
const onGroupChanged = (ev: any) => {
  // console.log(
  //   `FilterPanel onGroupChanged sees event: ${JSON.stringify(ev)}`
  // );
  switch (ev.value) {
    case "open":
      openNoteFilterGroup.value = ev.id;
      break;
    case "closed":
      openNoteFilterGroup.value = "";
      break;
  }
};

const unsubscribeSettings = settings.$subscribe(async () => {
  filterMenuData.value = await makeMenuData();

  listKey.value += 1;
});

const unsubscribeFolders = () => {
  return useFoldersStore().$subscribe(async () => {
    filterMenuData.value = await makeMenuData();
    // console.log(
    //   `NoteFilterPanel sees notes mutation and makes menu data ${JSON.stringify(
    //     filterMenuData.value
    //   )}`
    // );
    listKey.value += 1; // refresh the filter menu
    // }
  });
};

onMounted(async () => {
  // console.log(`NoteFilterPanel is mounted and starts to make menu data`);
  filterMenuData.value = await makeMenuData();
  listKey.value += 1; // refresh the filter menu
});

onBeforeUnmount(() => {
  // console.log("unmounting NoteFilterPanel");
  unsubscribeSettings(); // stop listing to mutations
  unsubscribeFolders();
});
</script>

<style scoped>
/* #container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
} */

#container p {
  font-size: 11px;
  line-height: 22px;
  color: #e01818;
  margin: 0;
}

.filter-menu-item {
  font-size: 11px;
  line-height: 22px;
  color: #e01818;
  margin: 0;
}

/* .ion-label {
  margin-top: 10px;
  font-size: 10px;
  word-wrap: break-word;
  /* overflow-wrap: break-word;
  white-space: normal;
  word-break: break-all; 
} */

/* #container a {
  text-decoration: none;
} */
</style>
