import { format, isThisYear } from "date-fns";
export class DateFormatter {
  static formatDate(date: Date) {
    const formatString = isThisYear(date) ? "EEEE do MMM" : "EEEE do MMM, yyyy";
    return format(date, formatString);
  }
  static formatDateAndTime(date: Date) {
    const formatString = isThisYear(date)
      ? "EEEE do MMM hh:mm:ss a"
      : "EEEE do MMM, yyyy  hh:mm:ss a";
    return format(date, formatString);
  }
}
