import { Filter } from "./Filter";
import { TaskObject } from "../TaskObject";
import { Constants } from "@/constants/constants";

export class UrgencyFilter extends Filter{
    public get debugDescription(): string {
        return `Urgency filter: urgency ${this.value}`
    }
    value: string;
    auto: boolean;
    constructor(value: string, autoUrgency: boolean) {
        super(value);
        this.value = value;
        this.auto = autoUrgency;
    }

    public isFiltered(item: TaskObject): boolean {
        const result =  (this.value === item.getUrgency(this.auto));
         return result;
    }
    
    public get description(){
        switch(this.value) {
            case Constants.URGENCY_SOC:
                return "Significant Outcome"
            case Constants.URGENCY_CRITICAL_NOW:
                return "Critical Now"
            case Constants.URGENCY_OPPORTUNITY_NOW:
                return "Opportunity Now"
            case Constants.URGENCY_OVER_THE_HORIZON_FILTER:
                return "Over-the-Horizon"
        }
        return this.value.toLowerCase();
    }

    
}