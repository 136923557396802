import { Filter } from "./Filter";
import { TaskObject } from "../TaskObject";
import { ContextObject } from "../ContextModel";
// import { Constants } from "@/constants/constants";

/**
 * Filters tasks that have context Id set to a given vaue
 *
 * @param value - the context Id to be filtered on
 *
 * @remarks
 * If value is null or an empty string, tasks are filtered in when they have no contextId
 */
export class ContextFilter extends Filter {
  public get debugDescription(): string {
   return `Context filter: ${this.value?.title}`
  }
  value: ContextObject;
  constructor(value: ContextObject) {
    super(value?.title ?? "None");
    this.value = value;
  }

  public isFiltered(item: TaskObject): boolean {
    if(item == null){
        // console.log(`ContextFilter isFiltered is examining a null task and returns false`)
        return false
    }
    // console.log(`ContextFilter is examining task with contextId ${item.contextId}`)
    if (this.value == null) {
        if(item.contextId == null || item.contextId === ""){
            // console.log(`ContextFilter is filtering for no context, sees task with null contextId and returns true`)
            return true
        }
        // console.log(`ContextFilter is filtering for no context, sees task with contextId ${item.contextId} and returns false`)
        return false
    }
    const result = this.value.id === item.contextId;
    // console.log(`ContextFilter sees task with contextId ${item.contextId} and returns ${result}`)
    return result;
  }

  public get description() {
    if (this.value == null) return "No Context";
    return `Context: ${this.value.title}`;
  }

}
