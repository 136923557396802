<template>
  <div v-if="!cancelled && !!deferredPrompt">
    <ion-card>
      <ion-card-header>
        <ion-card-title>Install TaskAngel</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-button v-if="deferredPrompt" @click="installPWA"
          >Install</ion-button
        >
        <ion-button @click="onCancel" fill="clear">Cancel</ion-button>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script setup lang="ts">
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
// import { useUserStore } from "@/stores/useUserStore";

// export default defineComponent({
//   name: "InstallPWA",
//   components: {
//     IonCard,
//     IonCardHeader,
//     IonCardTitle,
//     IonCardContent,
//     IonButton,
//   },
//   setup() {
// const deferredPrompt = ref(null); // debugging set to true. - for production, set to null
// the following code has been moved to App.vue
// console.log(`InstallPWA starts listening for beforeinstallprompt event`);
const deferredPrompt = ref(null);
const cancelled = ref(false);

// const userStore = useUserStore();

const installPWA = async () => {
  if (deferredPrompt.value) {
    deferredPrompt.value.prompt();
    const { outcome } = await deferredPrompt.value.userChoice;
    if (outcome === "accepted") {
      // console.log("User accepted the install prompt");
    } else {
      // console.log("User dismissed the install prompt");
    }
    deferredPrompt.value = null;
  }
};

const onCancel = () => {
  // console.log(`User cancelled InstallPWA`);
  deferredPrompt.value = null;
  cancelled.value = true;
};

onMounted(async () => {
  window.addEventListener("beforeinstallprompt", (e) => {
    // console.log(`InstallPWA sees beforeinstallprompt event`);
    e.preventDefault();
    deferredPrompt.value = e;
  });
});
// return { deferredPrompt, installPWA };
//   },
// });
</script>
