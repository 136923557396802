<template>
  <div id="app">
    <ion-app id="app">
      <ion-header>
        <InstallPWA v-if="installPWAFlag" />
      </ion-header>
      <ion-split-pane
        v-if="isWideScreen"
        content-id="main"
        when="md"
        :disabled="!isFilterMenuShowing"
      >
        <ion-menu side="start" menu-id="first" content-id="main">
          <ion-content>
            <div v-if="areTasksShowing">
              <FilterPanel></FilterPanel>
            </div>
            <div v-else>
              <NoteFilterPanel> </NoteFilterPanel>
            </div>
          </ion-content>
        </ion-menu>

        <ion-router-outlet id="main"></ion-router-outlet>
      </ion-split-pane>

      <div v-else>
        <ion-menu side="start" menu-id="first" content-id="main">
          <ion-header>
            <ion-toolbar>
              <ion-title>Menu</ion-title>
            </ion-toolbar>
          </ion-header>
          <ion-content>
            <div v-if="areTasksShowing">
              <FilterPanel
                @selected="handleFilterSelected"
                @closed="handleFilterSelected"
              ></FilterPanel>
            </div>
            <div v-if="areNotesShowing">
              <NoteFilterPanel @selected="handleFilterSelected">
              </NoteFilterPanel>
            </div>
            <!-- <ion-router-outlet /> -->
          </ion-content>
        </ion-menu>
        <ion-router-outlet id="main"></ion-router-outlet>
      </div>
      <div>
        <NotificationContainer />
      </div>
    </ion-app>
  </div>
</template>

<script lang="ts" setup>
import { useSettingsStore } from "@/stores/useSettingsStore";
import { useTasksStore } from "@/stores/useTasksStore";
import {
  IonApp,
  IonContent,
  IonHeader,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
  menuController,
  toastController,
} from "@ionic/vue";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import FilterPanel from "./views/components/FilterPanel.vue";
import NoteFilterPanel from "./views/components/NoteFilterPanel.vue";
import NotificationContainer from "./views/components/NotificationContainer.vue";

import * as Sentry from "@sentry/vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useFeatureFlagsStore } from "./stores/useFeatureFlagsStore";
import { useFiltersStore } from "./stores/useFiltersStore";
import InstallPWA from "./views/components/InstallPWA.vue";
import { Logger } from "@/helpers/Logger";

let refreshing = false;
let newRegistration: any;
let hasUpdate = false;

const currentPath = ref("");

const settings = useSettingsStore();
const tasksStore = useTasksStore();
const { unsubscribeFirestore } = tasksStore;

const { showFilterMenu } = storeToRefs(settings);
const featureFlagsStore = useFeatureFlagsStore();
const filtersStore = useFiltersStore();
const { autoUpdate, installPWAFlag } = storeToRefs(featureFlagsStore);

const refreshApp = async () => {
  if (refreshing) {
    return;
  }
  refreshing = true;
  if (!newRegistration || !newRegistration.waiting) {
    return;
  }

  try {
    await newRegistration?.waiting?.postMessage("skipWaiting");
    Logger.log("App is reloading itself");
    window.location.reload();
  } catch (error) {
    Logger.logError(`Error in App.refreshApp`, error);
    throw error;
  }
};

const openToastOptions = async () => {
  const toast = await toastController.create({
    header: "New Version Available",
    message: `Do you want to update TaskAngel now?`,
    position: "top",
    cssClass: "toast-custom-class",
    buttons: [
      {
        side: "end",
        icon: "star",
        text: "Yes",
        handler: () => {
          refreshApp();
        },
      },
      {
        text: "Later",
        role: "cancel",
        handler: () => {
          refreshing = false;
        },
      },
    ],
  });
  await toast.present();

  await toast.onDidDismiss();
};

const updateAvailable = (event: any) => {
  newRegistration = event.detail;
  if (!newRegistration) {
    return;
  }
  if (autoUpdate.value) {
    hasUpdate = true;
    return;
  }
  openToastOptions();
};

const onNavigated = async () => {
  if (hasUpdate) {
    hasUpdate = false;
    refreshApp();
    return;
  }
};

const onControllerChange = () => {
  refreshing = false;
  document.removeEventListener("swUpdated", updateAvailable);
  document.removeEventListener("controllerchange", onControllerChange);
  window.location.reload();
};

document?.addEventListener("swUpdated", updateAvailable, { once: true });
document?.addEventListener("navigated", onNavigated);

navigator.serviceWorker?.addEventListener(
  "controllerchange",
  onControllerChange
);

const areTasksShowing = ref(false);
const areNotesShowing = ref(false);

const canShowFilterMenu = ref(true);

const isFilterMenuShowing = computed(() => {
  const result = canShowFilterMenu.value && showFilterMenu.value;
  return result;
});

const userHidMenu = ref(false);
const windowWidth = ref(window.innerWidth);

const isWideScreen = computed(() => {
  return windowWidth.value > 768;
});

const route = useRoute();
watch(
  route,
  (to) => {
    currentPath.value = to.path;
    areTasksShowing.value =
      currentPath.value.includes("tasks") || to.path.includes("tab1");
    areNotesShowing.value =
      currentPath.value.includes("notes") || to.path.includes("tab2");
    canShowFilterMenu.value = areTasksShowing.value || areNotesShowing.value;
  },
  { flush: "pre", immediate: true, deep: true }
);

const handleFilterSelected = async () => {
  if (userHidMenu.value) {
    userHidMenu.value = false;
  } else {
    userHidMenu.value = true;
  }

  if (!isWideScreen.value) {
    await menuController.close();
  }
};

onBeforeUnmount(() => {
  unsubscribeFirestore();
});

onMounted(async () => {
  await settings.init();
  await featureFlagsStore.init();
  await filtersStore.init();

  window?.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}

hr {
  height: 1px;
  border-width: 1px;
}

.toast-custom-class {
  --color: #ffffff;
  --border-color: #000000;
  --border-radius: 3px;
  --border-style: solid;
  --border-width: 3px;
  --background: #1833ff;
  --button-color: #ffffff;
}
</style>
