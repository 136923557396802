import { Filter } from "./Filter";
import { TaskObject } from "../TaskObject";
import { GoalObject } from "../GoalModel";

/**
 * Filters tasks that have goal Id set to a given vaue
 *
 * @param value - the goal Id to be filtered on
 *
 * @remarks
 * If value is null or an empty string, tasks are filtered in when they have no goalId
 */
export class GoalFilter extends Filter {
  public get debugDescription(): string {
    if (!this.value) return "No goal";
    return `Goal filter: goal ${this.value.title}`
  }
  value?: GoalObject;
  constructor(value?: GoalObject) {
    super(value?.title ?? "no goal");
    this.value = value;
  }

  public isFiltered(item: TaskObject): boolean {
    if (this.value == null) return item.goalId == null || item.goalId === "";
    const result = this.value.id === item.goalId;
    return result;
  }

  public get description() {
    if (this.value == null) return "No goal";
    return this.value.title;
  }

  
}
