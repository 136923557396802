import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_menu_group = _resolveComponent("filter-menu-group")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isGroup)
      ? (_openBlock(), _createBlock(_component_filter_menu_group, {
          key: 'group' + _ctx.myFilterId + _ctx.status,
          filterId: _ctx.myFilterId,
          title: _ctx.titleWithCount,
          status: _ctx.myStatus,
          itemData: _ctx.myItemData
        }, null, 8, ["filterId", "title", "status", "itemData"]))
      : (_openBlock(), _createBlock(_component_ion_item, {
          key: _ctx.myFilterId,
          button: "",
          detail: "false",
          mode: "md"
        }, {
          default: _withCtx(() => [
            (_ctx.useFilterLink)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  fill: "clear",
                  onClick: _ctx.onLinkClicked
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.link,
                      slot: "start",
                      size: "small"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : (_openBlock(), _createBlock(_component_ion_icon, {
                  key: 1,
                  icon: _ctx.filterOutline,
                  size: "small",
                  slot: "start"
                }, null, 8, ["icon"])),
            _createVNode(_component_ion_label, {
              className: "ion-text-wrap",
              onClick: _ctx.onClicked
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.titleWithCount), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }))
  ]))
}