import { TaskObject } from "@/models/TaskObject";
import {
  addDays,
  addMonths,
  addYears,
  differenceInDays,
  startOfDay,
  isSaturday,
  isSunday,
} from "date-fns";
import { Logger } from "./Logger";
import { RepeatPattern } from "./RepeatPattern";
import { v4 as generateUUID } from "uuid";

export class RepeatManager {
  static getRepeatPatternFromTask(task: TaskObject): RepeatPattern {
    if (!task.repeat) return RepeatPattern.Never;
    const lowerCase = task.repeat.toLowerCase();
    switch (lowerCase) {
      case "daily":
      case "FREQ=DAILY":
        return RepeatPattern.Daily;
      case "weekly":
      case "FREQ=WEEKLY":
        return RepeatPattern.Weekly;
      case "weekdays":
      case "BYDAY=MO,TU,WE,TH,FR":
        return RepeatPattern.Weekdays;
      case "biweekly":
      case "FREQ=WEEKLY;INTERVAL=2":
        return RepeatPattern.Biweekly;
      case "monthly":
      case "FREQ=MONTHLY":
        return RepeatPattern.Monthly;
      case "bimonthly":
      case "FREQ=MONTHLY;INTERVAL=2":
        return RepeatPattern.Bimonthly;
      case "quarterly":
      case "FREQ=MONTHLY;INTERVAL=3":
        return RepeatPattern.Quarterly;
      case "semiannually":
      case "FREQ=MONTHLY;INTERVAL=6":
        return RepeatPattern.Semiannually;
      case "yearly":
      case "FREQ=YEARLY":
        return RepeatPattern.Yearly;
      case "withparent":
      case "PARENT":
        return RepeatPattern.WithParent;
    }
    throw new Error(
      `Repeat Manager did not recognise repeat pattern: ${task.repeat}`
    );
  }

  static makeRepeatedTask(fromTask: TaskObject) {
    if (!fromTask.repeat) return undefined;
    let increaseInDays: number;
    if (fromTask.due) {
      increaseInDays = this.calcRepeatedIncreaseInDays(
        fromTask.due,
        this.getRepeatPatternFromTask(fromTask)
      );
    } else if (fromTask.start) {
      increaseInDays = this.calcRepeatedIncreaseInDays(
        fromTask.start,
        this.getRepeatPatternFromTask(fromTask)
      );
    }
    if (increaseInDays) {
      const repeatedTask = new TaskObject({
        ...fromTask,
        id: generateUUID(),
        due: addDays(fromTask.due, increaseInDays),
        start: fromTask.start
          ? addDays(fromTask.start, increaseInDays)
          : undefined,
      });
      return repeatedTask;
    }
  }

  static calcPastRepeat(fromDate: Date, pattern: RepeatPattern) {
    if (pattern === RepeatPattern.Daily) return addDays(fromDate, 1);
    if (pattern === RepeatPattern.Weekly) return addDays(fromDate, 7);
    if (pattern === RepeatPattern.Biweekly) return addDays(fromDate, 14);
    if (pattern === RepeatPattern.Monthly) {
      return addMonths(fromDate, 1);
    }
    if (pattern === RepeatPattern.Bimonthly) {
      return addMonths(fromDate, 2);
    }
    if (pattern == RepeatPattern.Quarterly) {
      return addMonths(fromDate, 3);
    }
    if (pattern == RepeatPattern.Semiannually) {
      return addMonths(fromDate, 6);
    }
    if (pattern == RepeatPattern.Yearly) {
      return addYears(fromDate, 1);
    }
    if (pattern == RepeatPattern.Weekdays) {
      let toDate = addDays(fromDate, 1);
      if (isSaturday(toDate)) toDate = addDays(toDate, 2);
      else if (isSunday(toDate)) toDate = addDays(toDate, 1);
      return toDate;
    }
    const errorMessage = `calcOneRepeat can not process repeat pattern ${pattern}`;
    Logger.logError(errorMessage);
    throw new Error(errorMessage);
  }

  static calcRepeatedDate(fromDate: Date, pattern: RepeatPattern): Date {
    if (!pattern || pattern === RepeatPattern.Never) return undefined;

    const addedDays = this.calcRepeatedIncreaseInDays(fromDate, pattern);
    return addDays(fromDate, addedDays);
  }

  static calcRepeatedIncreaseInDays(
    fromDate: Date,
    pattern: RepeatPattern
  ): number {
    const today = startOfDay(new Date());
    let targetDate = this.calcPastRepeat(fromDate, pattern);
    while (targetDate < today) {
      targetDate = this.calcPastRepeat(targetDate, pattern);
    }
    return differenceInDays(targetDate, fromDate);
  }
}
