// set up the model

// import { Constants } from '@/constants/constants';
// import { Constants } from "@/constants/constants";
// import { Priority } from './Priority';
// import { Status } from "./Status";

export interface SubscriptionType {
  id?: string;
  title?: string;
  role?: string;
  currentPeriodStart?: Date;
  currentPeriodEnd?: Date;
  trialEnd?: Date;
  status: string;
}

export class SubscriptionObject implements SubscriptionType {
  id?: string;
  title?: string;
  role?: string;
  currentPeriodStart?: Date;
  currentPeriodEnd?: Date;
  trialEnd?: Date;
  status: string;

  static copyFromAnyObject(source: any, withId?: string): SubscriptionObject {
    const t = new SubscriptionObject();
    if (withId) {
      t.id = withId;
    } else {
      t.id = source.id;
    }
    if (source.title) {
      t.title = source.title;
    }

    if (source.role) {
      t.role = source.role;
    }

    if (source.currentPeriodStart) {
      t.currentPeriodStart = new Date(source.currentPeriodStart);
    }
    if (source.currentPeriodEnd) {
      t.currentPeriodEnd = new Date(source.currentPeriodEnd);
    }

    if (source.current_period_start?.seconds) {
      t.currentPeriodStart = new Date(source.current_period_start);
    }

    if (source.current_period_end?.seconds) {
      t.currentPeriodEnd = new Date(source.current_period_end);
    }

    if (source.trial_end?.seconds) {
      t.trialEnd = new Date(source.trial_end);
    }

    if (source.status) {
      t.status = source.status;
    }

    return t;
  }
}
