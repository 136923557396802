<template>
  <div v-if="newHamburgerMenuFlag">
    <ion-item>
      <ion-title> Filters</ion-title>
      <ion-button icon-only fill="clear" slot="end" @click="onClose">
        <ion-icon :icon="close"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button style="margin-left: 1em" @click="onReset">
        Clear Filters
      </ion-button>
    </ion-item>
    <div class="ion-padding">
      <ion-accordion-group v-model="openAccordion">
        <ion-accordion value="statusAccordion" ref="statusGroup">
          <ion-item slot="header">
            <ion-label>
              <ion-text
                v-if="selectedStatusFilter === Constants.STATUS_ANY_FILTER"
                color="medium"
              >
                Status: {{ selectedStatusTitle }}
              </ion-text>
              <ion-text v-else> Status: {{ selectedStatusTitle }}</ion-text>
            </ion-label>
          </ion-item>
          <ion-list slot="content">
            <ion-item
              button
              @click="onStatusFilterChange(Constants.ACTIVE_FILTER)"
            >
              <ion-label>Active</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onStatusFilterChange(Constants.COMPLETED_FILTER)"
            >
              <ion-label>Completed</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onStatusFilterChange(Constants.STATUS_ANY_FILTER)"
            >
              <ion-label>Any</ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <div v-if="useMYN">
          <ion-accordion value="urgencyAccordion" ref="urgencyGroup">
            <ion-item slot="header">
              <ion-label>
                <ion-text
                  v-if="selectedUrgencyFilter === Constants.URGENCY_ANY_FILTER"
                  color="medium"
                >
                  Urgency: {{ selectedUrgencyTitle }}
                </ion-text>
                <ion-text v-else>
                  Urgency: {{ selectedUrgencyTitle }}
                </ion-text>
              </ion-label>
            </ion-item>
            <!-- <ion-item slot="header">
            <ion-label>Urgency: {{ selectedUrgencyTitle }}</ion-label>
          </ion-item> -->
            <ion-list slot="content">
              <ion-item
                button
                @click="onUrgencyFilterChange(Constants.URGENCY_ANY_FILTER)"
              >
                <ion-label>Any</ion-label>
              </ion-item>
              <ion-item
                button
                @click="onUrgencyFilterChange(Constants.URGENCY_SOC_FILTER)"
              >
                <ion-label>Significant Outcome</ion-label>
              </ion-item>
              <ion-item
                button
                @click="
                  onUrgencyFilterChange(Constants.URGENCY_CRITICAL_NOW_FILTER)
                "
              >
                <ion-label>Critical Now</ion-label>
              </ion-item>
              <ion-item
                button
                @click="
                  onUrgencyFilterChange(
                    Constants.URGENCY_OPPORTUNITY_NOW_FILTER
                  )
                "
              >
                <ion-label>Opportunity Now</ion-label>
              </ion-item>
              <ion-item
                button
                @click="
                  onUrgencyFilterChange(
                    Constants.URGENCY_OVER_THE_HORIZON_FILTER
                  )
                "
              >
                <ion-label>Over the Horizon</ion-label>
              </ion-item>
            </ion-list>
          </ion-accordion>
        </div>
        <div v-else>
          <ion-accordion value="priorityAccordion" ref="priorityGroup">
            <ion-item slot="header">
              <ion-label>
                <ion-text
                  v-if="
                    selectedPriorityFilter === Constants.PRIORITY_ANY_FILTER
                  "
                  color="medium"
                >
                  Priority: {{ selectedPriorityTitle }}
                </ion-text>
                <ion-text v-else>
                  Priority: {{ selectedPriorityTitle }}
                </ion-text>
              </ion-label>
            </ion-item>
            <!-- <ion-item slot="header">
            <ion-label>Priority: {{ selectedPriorityTitle }}</ion-label>
          </ion-item> -->
            <ion-list slot="content">
              <ion-item
                button
                @click="onPriorityFilterChange(Constants.PRIORITY_ANY_FILTER)"
              >
                <ion-label>Any</ion-label>
              </ion-item>
              <ion-item
                button
                @click="onPriorityFilterChange(Constants.PRIORITY_TOP_FILTER)"
              >
                <ion-label>Top</ion-label>
              </ion-item>
              <ion-item
                button
                @click="onPriorityFilterChange(Constants.PRIORITY_HIGH_FILTER)"
              >
                <ion-label>High</ion-label>
              </ion-item>
              <ion-item
                button
                @click="
                  onPriorityFilterChange(Constants.PRIORITY_MEDIUM_FILTER)
                "
              >
                <ion-label>Medium</ion-label>
              </ion-item>
              <ion-item
                button
                @click="onPriorityFilterChange(Constants.PRIORITY_LOW_FILTER)"
              >
                <ion-label>Low</ion-label>
              </ion-item>
              <ion-item
                button
                @click="
                  onPriorityFilterChange(Constants.PRIORITY_NEGATIVE_FILTER)
                "
              >
                <ion-label>Negative</ion-label>
              </ion-item>
            </ion-list>
          </ion-accordion>
        </div>
        <ion-accordion value="starAccordion" ref="starGroup">
          <ion-item slot="header">
            <ion-label>
              <ion-text
                v-if="selectedStarFilter === Constants.STARRED_ANY_FILTER"
                color="medium"
              >
                Star: {{ selectedStarTitle }}
              </ion-text>
              <ion-text v-else> Star: {{ selectedStarTitle }}</ion-text>
            </ion-label>
          </ion-item>
          <!-- <ion-item slot="header">
          <ion-label>Star: {{ selectedStarTitle }}</ion-label>
        </ion-item> -->
          <ion-list slot="content">
            <ion-item
              button
              @click="onStarFilterChange(Constants.STARRED_ANY_FILTER)"
            >
              <ion-label>Any</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onStarFilterChange(Constants.STARRED_FILTER)"
            >
              <ion-label>Starred</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onStarFilterChange(Constants.UNSTARRED_FILTER)"
            >
              <ion-label>Unstarred</ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <ion-accordion value="dueAccordion" ref="dueGroup">
          <ion-item slot="header">
            <ion-label>
              <ion-text
                v-if="selectedDueFilter === Constants.DUE_ANY_FILTER"
                color="medium"
              >
                Due: {{ selectedDueTitle }}
              </ion-text>
              <ion-text v-else> Due: {{ selectedDueTitle }}</ion-text>
            </ion-label>
          </ion-item>
          <!-- <ion-item slot="header">
          <ion-label>Due: {{ selectedDueTitle }}</ion-label>
        </ion-item> -->
          <ion-list slot="content">
            <ion-item
              button
              @click="onDueFilterChange(Constants.DUE_ANY_FILTER)"
            >
              <ion-label>Any</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onDueFilterChange(Constants.DUE_OVERDUE_FILTER)"
            >
              <ion-label>Overdue</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onDueFilterChange(Constants.DUE_BY_TODAY_FILTER)"
            >
              <ion-label>By Today</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onDueFilterChange(Constants.DUE_TODAY_FILTER)"
            >
              <ion-label>Today</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onDueFilterChange(Constants.DUE_TOMORROW_FILTER)"
            >
              <ion-label>Tomorrow</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onDueFilterChange(Constants.DUE_WEEK_FILTER)"
            >
              <ion-label>Within a week</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onDueFilterChange(Constants.DUE_MONTH_FILTER)"
            >
              <ion-label>Within a month</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onDueFilterChange(Constants.DUE_3_MONTHS_FILTER)"
            >
              <ion-label>Within 3 months</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onDueFilterChange(Constants.DUE_YEAR_FILTER)"
            >
              <ion-label>Within a year</ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <ion-accordion value="startAccordion" ref="startGroup">
          <ion-item slot="header">
            <ion-label>
              <ion-text
                v-if="selectedStartFilter === Constants.START_ANY_FILTER"
                color="medium"
              >
                Start: {{ selectedStartTitle }}
              </ion-text>
              <ion-text v-else> Start: {{ selectedStartTitle }}</ion-text>
            </ion-label>
          </ion-item>
          <!-- <ion-item slot="header">
          <ion-label>Start: {{ selectedStartTitle }}</ion-label>
        </ion-item> -->
          <ion-list slot="content">
            <ion-item
              button
              @click="onStartFilterChange(Constants.START_ANY_FILTER)"
            >
              <ion-label>Any</ion-label>
            </ion-item>

            <ion-item
              button
              @click="onStartFilterChange(Constants.START_BY_TODAY_FILTER)"
            >
              <ion-label>By Today</ion-label>
            </ion-item>

            <ion-item
              button
              @click="onStartFilterChange(Constants.START_TODAY_FILTER)"
            >
              <ion-label>Today</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onStartFilterChange(Constants.START_TOMORROW_FILTER)"
            >
              <ion-label>Tomorrow</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onStartFilterChange(Constants.START_WEEK_FILTER)"
            >
              <ion-label>Within a week</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onStartFilterChange(Constants.START_MONTH_FILTER)"
            >
              <ion-label>Within a month</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onStartFilterChange(Constants.START_3_MONTHS_FILTER)"
            >
              <ion-label>Within 3 months</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onStartFilterChange(Constants.START_YEAR_FILTER)"
            >
              <ion-label>Within a year</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onStartFilterChange(Constants.START_UNDATED_FILTER)"
            >
              <ion-label>Undated</ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <ion-accordion value="addedAccordion" ref="addedGroup">
          <ion-item slot="header">
            <ion-label>
              <ion-text
                v-if="selectedAddedFilter === Constants.ADDED_ANY_FILTER"
                color="medium"
              >
                Added: {{ selectedAddedTitle }}
              </ion-text>
              <ion-text v-else> Added: {{ selectedAddedTitle }}</ion-text>
            </ion-label>
          </ion-item>
          <!-- <ion-item slot="header">
          <ion-label>Added: {{ selectedAddedTitle }}</ion-label>
        </ion-item> -->
          <ion-list slot="content">
            <ion-item
              button
              @click="onAddedFilterChange(Constants.ADDED_ANY_FILTER)"
            >
              <ion-label>Any</ion-label>
            </ion-item>

            <ion-item
              button
              @click="onAddedFilterChange(Constants.ADDED_TODAY_FILTER)"
            >
              <ion-label>Today</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onAddedFilterChange(Constants.ADDED_YESTERDAY_FILTER)"
            >
              <ion-label>Yesterday</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onAddedFilterChange(Constants.ADDED_WEEK_FILTER)"
            >
              <ion-label>In past week</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onAddedFilterChange(Constants.ADDED_MONTH_FILTER)"
            >
              <ion-label>In past month</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onAddedFilterChange(Constants.ADDED_3_MONTHS_FILTER)"
            >
              <ion-label>In past 3 months</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onAddedFilterChange(Constants.ADDED_YEAR_FILTER)"
            >
              <ion-label>In past year</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onAddedFilterChange(Constants.ADDED_OLDER_FILTER)"
            >
              <ion-label>Over a year ago</ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <ion-accordion
          v-if="hasImportedTasks"
          value="importedAccordion"
          ref="importedGroup"
        >
          <ion-item slot="header">
            <ion-label>
              <ion-text
                v-if="selectedImportedFilter === Constants.IMPORTED_ANY_FILTER"
                color="medium"
              >
                Imported: {{ selectedImportedTitle }}
              </ion-text>
              <ion-text v-else>
                Imported: {{ selectedImportedTitle }}
              </ion-text>
            </ion-label>
          </ion-item>
          <!-- <ion-item slot="header">
          <ion-label>Added: {{ selectedAddedTitle }}</ion-label>
        </ion-item> -->
          <ion-list slot="content">
            <ion-item
              button
              @click="onImportedFilterChange(Constants.IMPORTED_ANY_FILTER)"
            >
              <ion-label>Any</ion-label>
            </ion-item>

            <ion-item
              button
              @click="onImportedFilterChange(Constants.IMPORTED_TODAY_FILTER)"
            >
              <ion-label>Today</ion-label>
            </ion-item>
            <ion-item
              button
              @click="
                onImportedFilterChange(Constants.IMPORTED_YESTERDAY_FILTER)
              "
            >
              <ion-label>Yesterday</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onImportedFilterChange(Constants.IMPORTED_WEEK_FILTER)"
            >
              <ion-label>In past week</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onImportedFilterChange(Constants.IMPORTED_MONTH_FILTER)"
            >
              <ion-label>In past month</ion-label>
            </ion-item>
            <ion-item
              button
              @click="
                onImportedFilterChange(Constants.IMPORTED_3_MONTHS_FILTER)
              "
            >
              <ion-label>In past 3 months</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onImportedFilterChange(Constants.IMPORTED_YEAR_FILTER)"
            >
              <ion-label>In past year</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onImportedFilterChange(Constants.IMPORTED_OLDER_FILTER)"
            >
              <ion-label>Over a year ago</ion-label>
            </ion-item>
            <ion-item
              button
              @click="onImportedFilterChange(Constants.NOT_IMPORTED_FILTER)"
            >
              <ion-label>Not imported</ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <ion-accordion value="folderAccordion" ref="folderGroup">
          <ion-item slot="header">
            <ion-label>
              <ion-text
                v-if="selectedFolderFilter === Constants.FOLDER_ANY_FILTER"
                color="medium"
              >
                Folder: {{ selectedFolderTitle }}
              </ion-text>
              <ion-text v-else> Folder: {{ selectedFolderTitle }}</ion-text>
            </ion-label>
          </ion-item>
          <!-- <ion-item slot="header">
          <ion-label>Folder: {{ selectedFolderTitle }}</ion-label>
        </ion-item> -->
          <ion-list slot="content">
            <ion-item
              button
              @click="onFolderFilterChange(Constants.FOLDER_ANY_FILTER, 'Any')"
            >
              <ion-label>Any</ion-label>
            </ion-item>

            <div v-for="folder of folders" :key="folder.id">
              <ion-item
                button
                @click="
                  onFolderFilterChange(
                    Constants.FOLDER_FILTER_PREFIX + folder.id,
                    folder.title
                  )
                "
              >
                <ion-label>{{ folder.title }}</ion-label>
              </ion-item>
            </div>
            <ion-item
              button
              @click="
                onFolderFilterChange(Constants.FOLDER_NONE_FILTER, 'None')
              "
            >
              <ion-label>None</ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <ion-accordion value="goalAccordion" ref="goalGroup">
          <ion-item slot="header">
            <ion-label>
              <ion-text
                v-if="selectedGoalFilter === Constants.GOAL_ANY_FILTER"
                color="medium"
              >
                Goal: {{ selectedGoalTitle }}
              </ion-text>
              <ion-text v-else> Goal: {{ selectedGoalTitle }}</ion-text>
            </ion-label>
          </ion-item>
          <!-- <ion-item slot="header">
          <ion-label>Goal: {{ selectedGoalTitle }}</ion-label>
        </ion-item> -->
          <ion-list slot="content">
            <ion-item
              button
              @click="onGoalFilterChange(Constants.GOAL_ANY_FILTER, 'Any')"
            >
              <ion-label>Any</ion-label>
            </ion-item>

            <div v-for="goal of goals" :key="goal.id">
              <ion-item
                button
                @click="
                  onGoalFilterChange(
                    Constants.GOAL_FILTER_PREFIX + goal.id,
                    goal.title
                  )
                "
              >
                <ion-label>{{ goal.title }}</ion-label>
              </ion-item>
            </div>
            <ion-item
              button
              @click="onGoalFilterChange(Constants.GOAL_NONE_FILTER, 'None')"
            >
              <ion-label>None</ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <ion-accordion value="contextAccordion" ref="contextGroup">
          <ion-item slot="header">
            <ion-label>
              <ion-text
                v-if="selectedContextFilter === Constants.CONTEXT_ANY_FILTER"
                color="medium"
              >
                Context: {{ selectedContextTitle }}
              </ion-text>
              <ion-text v-else> Context: {{ selectedContextTitle }}</ion-text>
            </ion-label>
          </ion-item>
          <!-- <ion-item slot="header">
          <ion-label>Context: {{ selectedContextTitle }}</ion-label>
        </ion-item> -->
          <ion-list slot="content">
            <ion-item
              button
              @click="
                onContextFilterChange(Constants.CONTEXT_ANY_FILTER, 'Any')
              "
            >
              <ion-label>Any</ion-label>
            </ion-item>

            <div v-for="context of contexts" :key="context.id">
              <ion-item
                button
                @click="
                  onContextFilterChange(
                    Constants.CONTEXT_FILTER_PREFIX + context.id,
                    context.title
                  )
                "
              >
                <ion-label>{{ context.title }}</ion-label>
              </ion-item>
            </div>
            <ion-item
              button
              @click="
                onContextFilterChange(Constants.CONTEXT_NONE_FILTER, 'None')
              "
            >
              <ion-label>None</ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
      </ion-accordion-group>
    </div>
  </div>
  <!-- ----------------------------------------------------------------------------------- -->
  <!-- end of new hamburger menu -->
  <!-- Original filter panel -->
  <div v-else>
    <ion-header>
      <ion-item>
        <ion-title>Task Filters</ion-title>
      </ion-item>
    </ion-header>
    <div v-for="item in filterMenuData" :key="listKey + item.id">
      <div v-if="isGroup(item)">
        <filter-menu-group
          :itemData="item"
          @changed="onGroupChanged"
        ></filter-menu-group>
        <div v-if="isOpen(item)">
          <div v-for="child in item.children" :key="listKey + child.id">
            <filter-menu-item
              :itemData="child"
              :filterId="child.id"
              :title="child.title"
              :status="child.status"
              @selected="onItemSelected"
            ></filter-menu-item>
          </div>
        </div>
      </div>
      <div v-else>
        <filter-menu-item
          :itemData="item"
          :filterId="item.id"
          :title="item.title"
          :status="item.status"
          :isGroup="item.isGroup"
          @selected="onItemSelected"
        ></filter-menu-item>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Constants } from "@/constants/constants";
import { MenuGroupObject } from "@/models/MenuGroupObject";
import { MenuItemObject } from "@/models/MenuItemObject";
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonTitle,
} from "@ionic/vue";
import { computed, defineEmits, onBeforeUnmount, onMounted, ref } from "vue";
import FilterMenuItem from "../components/FilterMenuItem.vue";
// import { useStore } from "vuex";
import { useContextsStore } from "@/stores/useContextsStore";
import { useFeatureFlagsStore } from "@/stores/useFeatureFlagsStore";
import { useFiltersStore } from "@/stores/useFiltersStore";
import { useFoldersStore } from "@/stores/useFoldersStore";
import { useGoalsStore } from "@/stores/useGoalsStore";
import { useTasksStore } from "@/stores/useTasksStore";
import { Sorter } from "@/sorters/Sorter";

// import { useFiltersStore } from "@/stores/useFilters";
// import { Filter } from "@/models/filters/Filter";
import { close } from "ionicons/icons";
import FilterMenuGroup from "./FilterMenuGroup.vue";

import { useSettingsStore } from "@/stores/useSettingsStore";
import { storeToRefs } from "pinia";

// import { useLDReady, useLDFlag } from 'launchdarkly-vue-client-sdk'
//   const ldReady = useLDReady()

// const unfilteredSearchKey = 'unfiltered-search-results'
// const unfilteredSearchFlag = useLDFlag(unfilteredSearchKey, false /* default flag value, set to true when we are ready to launch? */)

// export default defineComponent({
//   name: "FilterPanel",
//   components: {
//     FilterMenuItem,
//     FilterMenuGroup,
//     IonHeader,
//     // IonToolbar,
//     // IonButton,
//     // IonIcon,
//     // IonLabel,
//     IonTitle,
//     IonContent,
//     IonItem,
//   },

const emit = defineEmits(["selected", "closed"]);
// emits: ["selected"],
// props: {
//   multipleChoice: {
//     type: Boolean,
//     default: false,
//   },
// },
// setup: (_, ctx: SetupContext) => {
// const store = useStore();

// Pinea store
const settings = useSettingsStore();

const {
  useMYN,
  openFilterGroup,
  currentFilterId,
  tasksSearchText,
  showFilterMenu,
  // showFutureStarts,
  // showCompleted,
  // showNegative,
} = storeToRefs(settings);

const featureFlags = useFeatureFlagsStore();
const { addedDateFilterFlag, newHamburgerMenuFlag } = storeToRefs(featureFlags);

const firestore = useTasksStore();
const { tasks, hasImportedTasks } = storeToRefs(firestore);
const { countTasksFilteredBy } = firestore;

const openAccordion = ref("");

const priorityTitle = computed<string>(() => {
  return (useMYN.value && "Urgency") || "Priority";
});

const priorityTitleOf = (priority: string) => {
  const result = (useMYN.value && Constants.URGENCY_MAP[priority]) || priority;
  return result;
};

const foldersStore = useFoldersStore();
const { folders } = storeToRefs(foldersStore);

const goalsStore = useGoalsStore();
const { goals } = storeToRefs(goalsStore);

const contextsStore = useContextsStore();
const { contexts } = storeToRefs(contextsStore);

// selected filters

const filtersStore = useFiltersStore();
const {
  selectedStatusFilter,
  selectedStatusTitle,
  selectedStarFilter,
  selectedStarTitle,
  selectedDueFilter,
  selectedDueTitle,
  selectedUrgencyFilter,
  selectedUrgencyTitle,
  selectedStartFilter,
  selectedStartTitle,
  selectedAddedFilter,
  selectedAddedTitle,
  selectedImportedFilter,
  selectedImportedTitle,
  selectedFolderFilter,
  selectedFolderTitle,
  selectedGoalFilter,
  selectedGoalTitle,
  selectedContextFilter,
  selectedContextTitle,
  selectedPriorityFilter,
  selectedPriorityTitle,
} = storeToRefs(filtersStore);
const { clearSelectedFilters } = filtersStore;

// const selectedStatusFilter = ref("any");
// const selectedStarFilter = ref("filter.star.any");
// const selectedDueFilter = ref("any");
// const selectedUrgencyFilter = ref("any");
// const selectedStartFilter = ref("any");
// const selectedAddedFilter = ref("any");

// new menu change events

const onReset = () => {
  // console.log(`FilterPanel is resetting selected filters`);
  clearSelectedFilters();
};

// user wants to close the filter
const onClose = () => {
  // console.log(`close button clicked on filter menu`);
  showFilterMenu.value = false;
  emit("closed");
};

const statusGroup = ref(null);

const onStatusFilterChange = (id: string) => {
  // console.log(`selectedStatusFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedStatusFilter: id,
  //   selectedStatusTitle: title,
  // });

  selectedStatusFilter.value = id;
  // selectedStatusTitle.value = title;

  // selectedStatusFilter.value = id;

  // toggle the accordion
  // console.log(`openAccordion is ${openAccordion.value}`);
  if (openAccordion.value == "statusAccordion") {
    openAccordion.value = "";
    // console.log(`openAccordion is now ${openAccordion.value}`);
  }

  // if (!statusGroup.value) {
  //   // console.log("statusGroup ref is not set");
  //   return;
  // }

  // console.log("statusGroup ref is set");
  // const native = statusGroup.value.$el;
  // // console.log(`statusGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const starGroup = ref(null);
const onStarFilterChange = (id: string) => {
  // console.log(`selectedStarFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedStarFilter: id,
  //   selectedStarTitle: title,
  // });
  selectedStarFilter.value = id;
  // selectedStarTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";

  // // toggle the accordion
  // if (!starGroup.value) {
  //   // console.log("starGroup ref is not set");
  //   return;
  // }

  // // console.log("starGroup ref is set");
  // const native = starGroup.value.$el;
  // // console.log(`starGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const dueGroup = ref(null);
const onDueFilterChange = (id: string) => {
  // console.log(`selectedDueFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedDueFilter: id,
  //   selectedDueTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedDueFilter.value = id;
  // selectedDueTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!dueGroup.value) {
  //   // console.log("dueGroup ref is not set");
  //   return;
  // }

  // // console.log("dueGroup ref is set");
  // const native = dueGroup.value.$el;
  // // console.log(`dueGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const startGroup = ref(null);
const onStartFilterChange = (id: string) => {
  // console.log(`selectedStartFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedStartFilter: id,
  //   selectedStartTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedStartFilter.value = id;
  // selectedStartTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!startGroup.value) {
  //   // console.log("startGroup ref is not set");
  //   return;
  // }

  // // console.log("startGroup ref is set");
  // const native = startGroup.value.$el;
  // // console.log(`startGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const addedGroup = ref(null);
const onAddedFilterChange = (id: string) => {
  // console.log(`selectedAddedFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedAddedFilter: id,
  //   selectedAddedTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedAddedFilter.value = id;
  // selectedAddedTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!addedGroup.value) {
  //   // console.log("addedGroup ref is not set");
  //   return;
  // }

  // // console.log("addedGroup ref is set");
  // const native = addedGroup.value.$el;
  // // console.log(`addedGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const importedGroup = ref(null);
const onImportedFilterChange = (id: string) => {
  // console.log(`selectedAddedFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedAddedFilter: id,
  //   selectedAddedTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedImportedFilter.value = id;
  // selectedAddedTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!addedGroup.value) {
  //   // console.log("addedGroup ref is not set");
  //   return;
  // }

  // // console.log("addedGroup ref is set");
  // const native = addedGroup.value.$el;
  // // console.log(`addedGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const priorityGroup = ref(null);
const onPriorityFilterChange = (id: string) => {
  // console.log(
  //   `selectedPriorityFilter will change to ${id} with title ${title}`
  // );

  // filtersStore.$patch({
  //   selectedPriorityFilter: id,
  //   selectedPriorityTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedPriorityFilter.value = id;
  // selectedPriorityTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!priorityGroup.value) {
  //   // console.log("priorityGroup ref is not set");
  //   return;
  // }

  // // console.log("priorityGroup ref is set");
  // const native = priorityGroup.value.$el;
  // // console.log(`priorityGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const urgencyGroup = ref(null);
const onUrgencyFilterChange = (id: string) => {
  // console.log(`selectedUrgencyFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedUrgencyFilter: id,
  //   selectedUrgencyTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedUrgencyFilter.value = id;
  // selectedUrgencyTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!urgencyGroup.value) {
  //   // console.log("urgencyGroup ref is not set");
  //   return;
  // }

  // // console.log("urgencyGroup ref is set");
  // const native = priorityGroup.value.$el;
  // // console.log(`urgencyGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const folderGroup = ref(null);
const onFolderFilterChange = (id: string, title: string) => {
  // console.log(`selectedFolderFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedFolderFilter: id,
  //   selectedFolderTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedFolderFilter.value = id;
  selectedFolderTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!folderGroup.value) {
  //   // console.log("folderGroup ref is not set");
  //   return;
  // }

  // // console.log("folderGroup ref is set");
  // const native = folderGroup.value.$el;
  // // console.log(`folderGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const goalGroup = ref(null);
const onGoalFilterChange = (id: string, title: string) => {
  // console.log(`selectedGoalFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedGoalFilter: id,
  //   selectedGoalTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedGoalFilter.value = id;
  selectedGoalTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!goalGroup.value) {
  //   // console.log("goalGroup ref is not set");
  //   return;
  // }

  // // console.log("goalGroup ref is set");
  // const native = goalGroup.value.$el;
  // // console.log(`goalGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

const contextGroup = ref(null);
const onContextFilterChange = (id: string, title: string) => {
  // console.log(`selectedContextFilter will change to ${id} with title ${title}`);

  // filtersStore.$patch({
  //   selectedContextFilter: id,
  //   selectedContextTitle: title,
  // });
  // selectedStatusFilter.value = id;

  selectedContextFilter.value = id;
  selectedContextTitle.value = title;

  // toggle the accordion
  openAccordion.value = "";
  // if (!contextGroup.value) {
  //   // console.log("contextGroup ref is not set");
  //   return;
  // }

  // // console.log("contextGroup ref is set");
  // const native = contextGroup.value.$el;
  // // console.log(`contextGroup native value is ${native.value}`);
  // if (native.value === title) {
  //   native.value = undefined;
  // } else {
  //   native.value = title;
  // }
};

// const onDueFilterChange = () => {
//   // console.log(`selectedDueFilter is changed to ${selectedDueFilter.value}`);
// };
// const onUrgencyFilterChange = () => {
// };
// const onStartFilterChange = () => {
//   // console.log(`selectedStartFilter is changed to ${selectedStartFilter.value}`);
// };
// const onAddedFilterChange = () => {
//   // console.log(`selectedAddedFilter is changed to ${selectedAddedFilter.value}`);
// };

// const onFolderFilterChange = () => {
//   console.log(
//     `selectedFolderFilter is changed to ${selectedFolderFilter.value}`
//   );
// };
// const onClose = () => {
//   // console.log(`close button pressed on the new hamburger menu`);
// };

// list data for menu items

const filterMenuData = ref(null);

const makeMenuData = () => {
  const result = Array<MenuItemObject>();

  result.push(
    new MenuItemObject(
      Constants.ALL_TASKS_FILTER,
      "All Tasks",
      tasks.value?.length ?? 0
    )
  );
  result.push(
    new MenuItemObject(
      Constants.COMPLETED_FILTER,
      "Completed",
      countTasksFilteredBy(Constants.COMPLETED_FILTER) ?? 0
    )
  );

  result.push(
    new MenuItemObject(
      Constants.STARRED_FILTER,
      "Starred",
      countTasksFilteredBy(Constants.STARRED_FILTER) ?? 0
    )
  );

  // priority group

  // .. or maybe urgency

  if (useMYN.value) {
    // create urgency group

    const urgencyGroup = new MenuGroupObject(
      Constants.URGENCY_GROUP_FILTER,
      "Urgency",
      openFilterGroup.value === Constants.URGENCY_GROUP_FILTER
        ? "open"
        : "closed",
      [
        new MenuItemObject(
          Constants.URGENCY_SOC_FILTER,
          Constants.URGENCY_SOC,
          countTasksFilteredBy(Constants.URGENCY_SOC_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.URGENCY_CRITICAL_NOW_FILTER,
          Constants.URGENCY_CRITICAL_NOW,
          countTasksFilteredBy(Constants.URGENCY_CRITICAL_NOW_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.URGENCY_OPPORTUNITY_NOW_FILTER,
          Constants.URGENCY_OPPORTUNITY_NOW,
          countTasksFilteredBy(Constants.URGENCY_OPPORTUNITY_NOW_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.URGENCY_OVER_THE_HORIZON_FILTER,
          Constants.URGENCY_OVER_THE_HORIZON,
          countTasksFilteredBy(Constants.URGENCY_OVER_THE_HORIZON_FILTER) ?? 0
        ),
      ]
    ); // created urgency group object
    result.push(urgencyGroup);
  } else {
    const priorityGroup = new MenuGroupObject(
      Constants.PRIORITY_GROUP_FILTER,
      priorityTitle.value,
      openFilterGroup.value === Constants.PRIORITY_GROUP_FILTER
        ? "open"
        : "closed",
      [
        new MenuItemObject(
          Constants.PRIORITY_TOP_FILTER,
          priorityTitleOf(Constants.PRIORITY_TOP),
          countTasksFilteredBy(Constants.PRIORITY_TOP_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.PRIORITY_HIGH_FILTER,
          priorityTitleOf(Constants.PRIORITY_HIGH),
          countTasksFilteredBy(Constants.PRIORITY_HIGH_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.PRIORITY_MEDIUM_FILTER,
          priorityTitleOf(Constants.PRIORITY_MEDIUM),
          countTasksFilteredBy(Constants.PRIORITY_MEDIUM_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.PRIORITY_LOW_FILTER,
          priorityTitleOf(Constants.PRIORITY_LOW),
          countTasksFilteredBy(Constants.PRIORITY_LOW_FILTER) ?? 0
        ),
      ]
    ); // created priority group object

    // do we need a negative element? Not if we are using MYN
    if (!useMYN.value) {
      priorityGroup.children.push(
        new MenuItemObject(
          Constants.PRIORITY_NEGATIVE_FILTER,
          priorityTitleOf(Constants.PRIORITY_NEGATIVE),
          countTasksFilteredBy(Constants.PRIORITY_NEGATIVE_FILTER) ?? 0
        )
      );
    }
    result.push(priorityGroup);
  } // not using MYN

  // make Start filters
  result.push(
    new MenuGroupObject(
      Constants.START_GROUP_FILTER,
      "Start",
      openFilterGroup.value === Constants.START_GROUP_FILTER
        ? "open"
        : "closed",
      [
        new MenuItemObject(
          Constants.START_OVERDUE_FILTER,
          "Overdue",
          countTasksFilteredBy(Constants.START_OVERDUE_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_TODAY_FILTER,
          "Today",
          countTasksFilteredBy(Constants.START_TODAY_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_TOMORROW_FILTER,
          "Tomorrow",
          countTasksFilteredBy(Constants.START_TOMORROW_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_WEEK_FILTER,
          "Within a week",
          countTasksFilteredBy(Constants.START_WEEK_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_MONTH_FILTER,
          "Within a month",
          countTasksFilteredBy(Constants.START_MONTH_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_3_MONTHS_FILTER,
          "Within 3 months",
          countTasksFilteredBy(Constants.START_3_MONTHS_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_YEAR_FILTER,
          "Within a year",
          countTasksFilteredBy(Constants.START_YEAR_FILTER) ?? 0
        ),
        new MenuItemObject(
          Constants.START_UNDATED_FILTER,
          "Undated",
          countTasksFilteredBy(Constants.START_UNDATED_FILTER) ?? 0
        ),
      ]
    )
  );

  // make Due filters

  // console.log(`FilterPanel makeMenuData starts making due filters`);

  result.push(
    new MenuGroupObject(
      Constants.DUE_GROUP_FILTER,
      "Due",
      openFilterGroup.value === Constants.DUE_GROUP_FILTER ? "open" : "closed",
      [
        new MenuItemObject(
          Constants.DUE_OVERDUE_FILTER,
          "Overdue",
          countTasksFilteredBy(Constants.DUE_OVERDUE_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_TODAY_FILTER,
          "Today",
          countTasksFilteredBy(Constants.DUE_TODAY_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_TOMORROW_FILTER,
          "Tomorrow",
          countTasksFilteredBy(Constants.DUE_TOMORROW_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_WEEK_FILTER,
          "Within a week",
          countTasksFilteredBy(Constants.DUE_WEEK_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_MONTH_FILTER,
          "Within a month",
          countTasksFilteredBy(Constants.DUE_MONTH_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_3_MONTHS_FILTER,
          "Within 3 months",
          countTasksFilteredBy(Constants.DUE_3_MONTHS_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_YEAR_FILTER,
          "Within a year",
          countTasksFilteredBy(Constants.DUE_YEAR_FILTER)
        ),
        new MenuItemObject(
          Constants.DUE_UNDATED_FILTER,
          "Undated",
          countTasksFilteredBy(Constants.DUE_UNDATED_FILTER)
        ),
      ]
    )
  );

  // make added filters

  if (addedDateFilterFlag.value) {
    result.push(
      new MenuGroupObject(
        Constants.ADDED_GROUP_FILTER,
        "Added",
        openFilterGroup.value === Constants.DUE_GROUP_FILTER
          ? "open"
          : "closed",
        [
          new MenuItemObject(
            Constants.ADDED_TODAY_FILTER,
            "Today",
            countTasksFilteredBy(Constants.ADDED_TODAY_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_YESTERDAY_FILTER,
            "Yesterday",
            countTasksFilteredBy(Constants.ADDED_YESTERDAY_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_WEEK_FILTER,
            "Within a week",
            countTasksFilteredBy(Constants.ADDED_WEEK_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_MONTH_FILTER,
            "Within a month",
            countTasksFilteredBy(Constants.ADDED_MONTH_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_3_MONTHS_FILTER,
            "Within 3 months",
            countTasksFilteredBy(Constants.ADDED_3_MONTHS_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_YEAR_FILTER,
            "Within a year",
            countTasksFilteredBy(Constants.ADDED_YEAR_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_OLDER_FILTER,
            "More than a year ago",
            countTasksFilteredBy(Constants.ADDED_OLDER_FILTER)
          ),
          new MenuItemObject(
            Constants.ADDED_UNDATED_FILTER,
            "Undated",
            countTasksFilteredBy(Constants.ADDED_UNDATED_FILTER)
          ),
        ]
      )
    );
  } // added filter feature flag

  // make goal filters
  const goalMenuItems = new Array<MenuItemObject>();

  const goals = useGoalsStore().goals;
  if (goals.length > 0) {
    const sorted = [...goals];
    Sorter.sortGoalsInPlace(sorted);
    for (let i = 0; i < sorted.length; i++) {
      const goal = sorted[i];
      const filterId = Constants.GOAL_FILTER_PREFIX + goal.id;

      const menuItem = new MenuItemObject(
        filterId,
        goal.title,
        countTasksFilteredBy(filterId)
      );
      goalMenuItems.push(menuItem);
    }
  }

  goalMenuItems.push(
    new MenuItemObject(
      Constants.GOAL_NONE_FILTER,
      "No goal",
      countTasksFilteredBy(Constants.GOAL_NONE_FILTER)
    )
  );

  const goalGroup = new MenuGroupObject(
    Constants.GOAL_GROUP_FILTER,
    "Goals",
    openFilterGroup.value === Constants.GOAL_GROUP_FILTER ? "open" : "closed",
    goalMenuItems
  );

  result.push(goalGroup);

  // make folder filters
  const folderMenuItems = new Array<MenuItemObject>();

  folderMenuItems.push(
    new MenuItemObject(
      Constants.FOLDER_NONE_FILTER,
      Constants.INBOX_TITLE,
      countTasksFilteredBy(Constants.FOLDER_NONE_FILTER)
    )
  );

  const folders = useFoldersStore().folders;
  // copy for the folder list
  const sorted = [...folders];
  Sorter.sortGoalsInPlace(sorted);
  if (sorted.length > 0) {
    for (let i = 0; i < sorted.length; i++) {
      const folder = sorted[i];
      const filterId = Constants.FOLDER_FILTER_PREFIX + folder.id;

      const menuItem = new MenuItemObject(
        filterId,
        folder.title,
        countTasksFilteredBy(filterId)
      );
      folderMenuItems.push(menuItem);
    }
  }

  const folderGroup = new MenuGroupObject(
    Constants.FOLDER_GROUP_FILTER,
    "Folders",
    openFilterGroup.value === Constants.FOLDER_GROUP_FILTER ? "open" : "closed",
    folderMenuItems
  );

  result.push(folderGroup);

  // make context filters

  const contextMenuItems = new Array<MenuItemObject>();

  contextMenuItems.push(
    new MenuItemObject(
      Constants.CONTEXT_NONE_FILTER,
      "None",
      countTasksFilteredBy(Constants.CONTEXT_NONE_FILTER)
    )
  );

  const contexts = useContextsStore().contexts;
  if (contexts.length > 0) {
    const sorted = [...contexts];
    for (let i = 0; i < sorted.length; i++) {
      const context = sorted[i];
      const filterId = Constants.CONTEXT_FILTER_PREFIX + context.id;

      const menuItem = new MenuItemObject(
        filterId,
        context.title,
        countTasksFilteredBy(filterId)
      );
      contextMenuItems.push(menuItem);
    }
  }

  const contextGroup = new MenuGroupObject(
    Constants.CONTEXT_GROUP_FILTER,
    "Contexts",
    openFilterGroup.value === Constants.CONTEXT_GROUP_FILTER
      ? "open"
      : "closed",
    contextMenuItems
  );

  result.push(contextGroup);

  // return filter menu data

  return result;
};

// end of filterMenuData()

/**
 * A key used to cause the list to refresh itself
 *
 * @remarks
 * Each item in the template is given a key made up of listKey and a filter id.
 * We can refresh the list by incrementing the list key.
 *
 */
const listKey = ref(0);

// computed properties

// functions

function isGroup(item: MenuItemObject) {
  return item.id.indexOf("group") >= 0;
}

function isOpen(item: MenuItemObject) {
  return item.id === openFilterGroup.value;
}

// events

/**
 * Handle All Tasks button
 *
 * @remarks
 * This clears the filters and then applies presets in Settings
 */

/**
 * Present the user with filter panel options
 *
 * @remarks
 * The options are:
 * Multiple filters
 * Simple filters
 *
 *
 */

const onItemSelected = (ev) => {
  // console.log(`FilterPanel onItemSelected`);
  // console.log(`FilterPanel onItemSelected sees event: ${JSON.stringify(ev)}`)
  // // console.log(`Filter panel calls store to select filter: ${ev.id}`);
  // if (addedDateFilterFlag.value) {
  //   console.log(`FilterPanel calls setCurrentFilterId with ${ev.id}`)
  // }

  tasksSearchText.value = "";
  currentFilterId.value = ev.id;

  emit("selected", ev.id); // debugging 11/9/22
};

// const handleClickedRefresh = async () => {
//   // console.log("FilterPanel sees clicked refresh");
//   isRefreshing.value = true
//   await store.dispatch("refreshUserData")
//   // console.log("MasterPage sees refresh finished");
//   // console.log("Hello");
//   // setTimeout(() => {
//   //   console.log("World!");
//     isRefreshing.value = false

//     // emit event to close filter panel
//     ctx.emit("refreshed");

//     // }, 500);

// };

// /**
//  * Handles itemChanged event.
//  *
//  * @remarks
//  * The event has a payload of id and value.
//  * We can can pass that directly into state.
//  */
// const onItemChanged = (ev: any) => {
//   // console.log(
//   //   `FilterPanel onItemChanged sees event: ${JSON.stringify(ev)}`
//   // );

//   useSettingsStore().setSetting(ev.id, ev.value)
//   // store.dispatch("setSetting", {
//   //   key: ev.id,
//   //   value: ev.value,
//   // });
// };

/**
 * Handle when use clicks on open/close for a filter group.
 *
 * @remarks
 * There can be only one filter group open at once. This is saved in settings.
 * This function changes that settings value
 */
const onGroupChanged = (ev: any) => {
  // console.log(
  //   `FilterPanel onGroupChanged sees event: ${JSON.stringify(ev)}`
  // );
  switch (ev.value) {
    case "open":
      openFilterGroup.value = ev.id;
      // setSetting(
      //   Constants.OPEN_FILTER_GROUP,
      //   ev.id,
      // );
      break;
    case "closed":
      openFilterGroup.value = "none";
      // setSetting(
      //   Constants.OPEN_FILTER_GROUP,
      //   ev.id,
      // );
      break;
  }

  // console.log(`FilterPanel onGroupChanged has set openFilterGroup setting to ${openFilterGroup.value}`)
};

// const filterDescription = computed(() => {
//   const filterId = currentFilterId.value;
//   const filter: Filter = taskFilterFromId(filterId);
//   // console.log(`FilterPanel sees filter: ${JSON.stringify(filter)}`);
//   let result: string | undefined = filter?.description;
//   if (!result) result = "No Filter";
//   // console.log(`FilterPanel sees filter description: ${result}`);
//   return result;
// });

// const unsubscribe = store.subscribe((mutation) => {
//   // if (!mutation.type.includes("Note") && store.getters.tasks?.length > 0) {
//     // ignore note mutations, and don't run if there are no tasks
//     // || mutation.type.includes("Goal")
//     // || mutation.type.includes("Setting")
//     // || mutation.type.includes("Folder")) {
//     // calculate all the filter menu data including counts
//     // console.log(
//     //   `FilterPanel sees mutation ${mutation.type} and starts to make menu data`
//     // );

//     // filterMenuData.value = makeMenuData();
//     // console.log(
//     //   `FilterPanel sees ${mutation.type} and makes menu data ${JSON.stringify(
//     //     filterMenuData.value
//     //   )}`
//     // );
//     // listKey.value += 1; // refresh the filter menu
//   // }
// });

const unsubscribeSettings = settings.$subscribe(() => {
  //   // subscribing to pinia state changes

  // console.log(
  //   `FilterPanel subscription sees pinia state change with mutation type  ${
  //     mutation.type
  //   } and payload ${JSON.stringify(mutation.payload)}}  ***`
  // );

  filterMenuData.value = makeMenuData();

  listKey.value += 1;

  //   // areTasksShowing.value = currentTab.value === "tasks";
  //   //  console.log(`App sees currentTab is ${currentTab.value} and sets areTasksShowing to ${areTasksShowing.value}`
  //   // );
  // });
});

onMounted(() => {
  // console.log(`FilterPanel is mounted and starts to make menu data`);
  filterMenuData.value = makeMenuData();
  // console.log(`FilterPanel returned from make menu data`);
  listKey.value += 1; // refresh the filter menu
});

onBeforeUnmount(() => {
  // console.log("unmounting FilterPanel");
  unsubscribeSettings(); // stop listing to mutations
});

// return {
//   // event handlers
//   onItemChanged,
//   onGroupChanged,
//   onItemSelected,
//   handleAllTasks,
//   handleOptionsClick,
//   // handleClickedRefresh,

//   // icons
//   closeOutline,
//   closeCircleOutline,
//   saveOutline,
//   refreshOutline,
//   ellipsisVerticalOutline,
//   filterOutline,
//   listOutline,
//   openFilterGroup,

//   // properties
//   filterMenuData,
//   // filterMenuItems,
//   listKey,
//   filterDescription,
//   isGroup,
//   isOpen,
//   // multipleChoice,
// };
</script>

<style scoped>
ion-accordion::part(content) {
  padding-left: 10px;
}

#container p {
  font-size: 11px;
  line-height: 22px;
  color: #e01818;
  margin: 0;
}

.filter-menu-item {
  font-size: 11px;
  line-height: 22px;
  color: #e01818;
  margin: 0;
}
</style>
