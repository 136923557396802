import { Constants } from "@/constants/constants";
import { defineStore, storeToRefs } from "pinia";

import { DateFilterOption } from "@/models/filters/DateFilterOption";
import { DueDateFilter } from "@/models/filters/DueDateFilter";
import { GoalFilter } from "@/models/filters/GoalFilter";
import { IsCompleteFilter } from "@/models/filters/IsCompleteFilter";
import { PriorityFilter } from "@/models/filters/PriorityFilter";
import { StarredFilter } from "@/models/filters/StarredFilter";
import { StartDateFilter } from "@/models/filters/StartDateFilter";

import { AddedDateFilter } from "@/models/filters/AddedDateFilter";
import { AllFilter } from "@/models/filters/AllFilter";
import { ContextFilter } from "@/models/filters/ContextFilter";
import { Filter } from "@/models/filters/Filter";
import { FolderFilter } from "@/models/filters/FolderFilter";
import { FolderNoteFilter } from "@/models/filters/FolderNoteFilter";
import { ImportedDateFilter } from "@/models/filters/ImportedDateFilter";
import { NotFilter } from "@/models/filters/NotFilter";
import { TextFilter } from "@/models/filters/TextFilter";
import { UrgencyFilter } from "@/models/filters/UrgencyFilter";
import { useContextsStore } from "@/stores/useContextsStore";
import { useFoldersStore } from "@/stores/useFoldersStore";
import { useGoalsStore } from "@/stores/useGoalsStore";
import { serializeState, useSettingsStore } from "@/stores/useSettingsStore";
import * as Sentry from "@sentry/vue";
import { watch } from "vue";
import { useUserStore } from "./useUserStore";

export const useFiltersStore = defineStore("filters", {
  state: () => ({
    taskFilterId: "",
    noteFilterId: "",
    isMenuShowing: false,
    filtersLoaded: false,
    filtersLoading: false,

    selectedStatusFilter: Constants.STATUS_ANY_FILTER,
    selectedStarFilter: Constants.STARRED_ANY_FILTER,
    selectedDueFilter: Constants.DUE_ANY_FILTER,
    selectedUrgencyFilter: Constants.URGENCY_ANY_FILTER,
    selectedPriorityFilter: Constants.PRIORITY_ANY_FILTER,
    selectedStartFilter: Constants.START_ANY_FILTER,
    selectedAddedFilter: Constants.ADDED_ANY_FILTER,
    selectedImportedFilter: Constants.IMPORTED_ANY_FILTER,
    selectedFolderFilter: Constants.FOLDER_ANY_FILTER,
    selectedFolderTitle: "Any",
    selectedGoalFilter: Constants.GOAL_ANY_FILTER,
    selectedGoalTitle: "Any",
    selectedContextFilter: Constants.CONTEXT_ANY_FILTER,
    selectedContextTitle: "Any",
  }),

  getters: {
    isFilterMenuShowing: (state) => {
      return state.isMenuShowing;
    },
    getTaskFilterId: (state) => {
      return state.taskFilterId;
    },
    currentFilter(): AllFilter {
      const filters: Filter[] = [];
      const settings = useSettingsStore()
      const search = settings.tasksSearchText ? new TextFilter(settings.tasksSearchText) : undefined;
      if (search) {
        filters.push(search);
      }

      const filtersStore = useFiltersStore();
      const { selectedFilter } = storeToRefs(filtersStore);
      const selected: AllFilter = selectedFilter.value
      if (selected) filters.push(selected)
      if (!filters) {
        return null;
      }
      const current = new AllFilter('Current filter', filters);
      return current;
    },
    taskFilterFromId: () => {
      return (id) => {
        if (id) {
          switch (id) {
            case Constants.ALL_TASKS_FILTER:
              return null;
            case Constants.ACTIVE_FILTER:
              return new IsCompleteFilter(false);
            case Constants.COMPLETED_FILTER:
              return new IsCompleteFilter(true);

            case Constants.STARRED_FILTER:
              return new StarredFilter(true);
            case Constants.UNSTARRED_FILTER:
              return new StarredFilter(false);

            case Constants.PRIORITY_TOP_FILTER:
              return new PriorityFilter(Constants.PRIORITY_TOP);
            case Constants.PRIORITY_HIGH_FILTER:
              return new PriorityFilter(Constants.PRIORITY_HIGH);
            case Constants.PRIORITY_MEDIUM_FILTER:
              return new PriorityFilter(Constants.PRIORITY_MEDIUM);
            case Constants.PRIORITY_LOW_FILTER:
              return new PriorityFilter(Constants.PRIORITY_LOW);
            case Constants.PRIORITY_NEGATIVE_FILTER:
              return new PriorityFilter(Constants.PRIORITY_NEGATIVE);

            case Constants.URGENCY_CRITICAL_NOW_FILTER: {
              const settings = useSettingsStore();
              const auto = settings.autoUrgency;
              return new UrgencyFilter(Constants.URGENCY_CRITICAL_NOW, auto);
            }
            case Constants.URGENCY_OPPORTUNITY_NOW_FILTER: {
              const settings = useSettingsStore();
              const auto = settings.autoUrgency;
              return new UrgencyFilter(Constants.URGENCY_OPPORTUNITY_NOW, auto);
            }
            case Constants.URGENCY_SOC_FILTER: {
              const settings = useSettingsStore();
              const auto = settings.autoUrgency;
              return new UrgencyFilter(Constants.URGENCY_SOC, auto);
            }
            case Constants.URGENCY_OVER_THE_HORIZON_FILTER: {
              const settings = useSettingsStore();
              const auto = settings.autoUrgency;
              return new UrgencyFilter(
                Constants.URGENCY_OVER_THE_HORIZON,
                auto
              );
            }
            case Constants.START_OVERDUE_FILTER:
              return new StartDateFilter(DateFilterOption.Overdue);
            case Constants.START_STARTED_FILTER:
              return new StartDateFilter(DateFilterOption.Started);
            case Constants.START_BY_TODAY_FILTER:
              return new StartDateFilter(DateFilterOption.ByToday);
            case Constants.START_TODAY_FILTER:
              return new StartDateFilter(DateFilterOption.Today);
            case Constants.START_TOMORROW_FILTER:
              return new StartDateFilter(DateFilterOption.Tomorrow);
            case Constants.START_WEEK_FILTER:
              return new StartDateFilter(DateFilterOption.Week);
            case Constants.START_MONTH_FILTER:
              return new StartDateFilter(DateFilterOption.Month);
            case Constants.START_3_MONTHS_FILTER:
              return new StartDateFilter(DateFilterOption.Quarter);
            case Constants.START_YEAR_FILTER:
              return new StartDateFilter(DateFilterOption.Year);
            case Constants.START_UNDATED_FILTER:
              return new StartDateFilter(DateFilterOption.Undated);

            case Constants.DUE_OVERDUE_FILTER:
              return new DueDateFilter(DateFilterOption.Overdue);
            case Constants.DUE_BY_TODAY_FILTER:
              return new DueDateFilter(DateFilterOption.ByToday);
            case Constants.DUE_TODAY_FILTER:
              return new DueDateFilter(DateFilterOption.Today);
            case Constants.DUE_TOMORROW_FILTER:
              return new DueDateFilter(DateFilterOption.Tomorrow);
            case Constants.DUE_WEEK_FILTER:
              return new DueDateFilter(DateFilterOption.Week);
            case Constants.DUE_MONTH_FILTER:
              return new DueDateFilter(DateFilterOption.Month);
            case Constants.DUE_3_MONTHS_FILTER:
              return new DueDateFilter(DateFilterOption.Quarter);
            case Constants.DUE_YEAR_FILTER:
              return new DueDateFilter(DateFilterOption.Year);
            case Constants.DUE_UNDATED_FILTER:
              return new DueDateFilter(DateFilterOption.Undated);

            case Constants.ADDED_TODAY_FILTER:
              return new AddedDateFilter(DateFilterOption.Today);
            case Constants.ADDED_YESTERDAY_FILTER:
              return new AddedDateFilter(DateFilterOption.Yesterday);
            case Constants.ADDED_WEEK_FILTER:
              return new AddedDateFilter(DateFilterOption.Week);
            case Constants.ADDED_MONTH_FILTER:
              return new AddedDateFilter(DateFilterOption.Month);
            case Constants.ADDED_3_MONTHS_FILTER:
              return new AddedDateFilter(DateFilterOption.Quarter);
            case Constants.ADDED_YEAR_FILTER:
              return new AddedDateFilter(DateFilterOption.Year);
            case Constants.ADDED_OLDER_FILTER:
              return new AddedDateFilter(DateFilterOption.Older);
            case Constants.ADDED_UNDATED_FILTER:
              return new AddedDateFilter(DateFilterOption.Undated);


            case Constants.IMPORTED_TODAY_FILTER:
              return new ImportedDateFilter(DateFilterOption.Today);
            case Constants.IMPORTED_YESTERDAY_FILTER:
              return new ImportedDateFilter(DateFilterOption.Yesterday);
            case Constants.IMPORTED_WEEK_FILTER:
              return new ImportedDateFilter(DateFilterOption.Week);
            case Constants.IMPORTED_MONTH_FILTER:
              return new ImportedDateFilter(DateFilterOption.Month);
            case Constants.IMPORTED_3_MONTHS_FILTER:
              return new ImportedDateFilter(DateFilterOption.Quarter);
            case Constants.IMPORTED_YEAR_FILTER:
              return new ImportedDateFilter(DateFilterOption.Year);
            case Constants.IMPORTED_OLDER_FILTER:
              return new ImportedDateFilter(DateFilterOption.Older);
            case Constants.NOT_IMPORTED_FILTER:
              return new ImportedDateFilter(DateFilterOption.Undated);

            case Constants.GOAL_NONE_FILTER:
              return new GoalFilter();
            case Constants.FOLDER_ANY_FILTER:
              return null;
            case Constants.GOAL_ANY_FILTER:
              return null;
            case Constants.CONTEXT_ANY_FILTER:
              return null;

            default:

              if (id.startsWith(Constants.GOAL_FILTER_PREFIX)) {
                const goalId = id.slice(Constants.GOAL_FILTER_PREFIX.length);
                const goal = useGoalsStore().goalFromId(goalId);
                return new GoalFilter(goal);
              }

              if (id.startsWith(Constants.FOLDER_FILTER_PREFIX)) {
                const folderId = id.slice(
                  Constants.FOLDER_FILTER_PREFIX.length
                );
                const folder = useFoldersStore().folderFromId(folderId);
                return new FolderFilter(folder);
              }

              if (id.startsWith(Constants.CONTEXT_FILTER_PREFIX)) {
                const contextId = id.slice(
                  Constants.CONTEXT_FILTER_PREFIX.length
                );
                const context = useContextsStore().contextFromId(contextId);
                return new ContextFilter(context);
              }
          }

          return null;
        }
      };
    },
    noteFilterFromId: () => {
      return (id: string) => {
        if (id.startsWith(Constants.FOLDER_NOTE_FILTER_PREFIX)) {
          return new FolderNoteFilter(id);
        }
        return null;
      };
    },
    folderIdFromFilter: (state) => {
      let result = "";
      const key = state.taskFilterId;
      if (key) {
        if (key.startsWith(Constants.FOLDER_FILTER_PREFIX)) {

          result = (key as string).slice(Constants.FOLDER_FILTER_PREFIX.length);
          return result;
        }
      }

      return null;
    },

    folderIdFromNoteFilter: (state) => {
      let result = "";
      const key = state.noteFilterId;
      if (key) {
        if (key.startsWith(Constants.FOLDER_NOTE_FILTER_PREFIX)) {
          result = (key as string).slice(
            Constants.FOLDER_NOTE_FILTER_PREFIX.length
          );
          return result;
        }
      }
      return null;
    },

    goalIdFromFilter: (state) => {
      let result = "";
      const key = state.taskFilterId;
      if (key) {
        if (key.startsWith(Constants.GOAL_FILTER_PREFIX)) {
          result = (key as string).slice(Constants.GOAL_FILTER_PREFIX.length);
          return result;
        }
      }
      return null;
    },

    contextIdFromFilter: (state) => {
      let result = "";
      const key = state.taskFilterId;
      if (key) {
        if (key.startsWith(Constants.CONTEXT_FILTER_PREFIX)) {
          result = (key as string).slice(
            Constants.CONTEXT_FILTER_PREFIX.length
          );
          return result;
        }
      }

      return null;
    },

    searchFilter: () => {
      const settings = useSettingsStore();
      const result = new TextFilter(settings.tasksSearchText)
      return result;
    }
    ,
    optionsFilter: () => {
      const result = new AllFilter("Options Filter");
      const settings = useSettingsStore();
      const {
        currentFilterId,
        showCompleted,
        useMYN,
        showNegative,
        showFutureStarts,
      } = storeToRefs(settings);
      const selectedFilterId =
        currentFilterId.value || Constants.ALL_TASKS_FILTER;

      if (
        !showCompleted.value &&
        selectedFilterId != Constants.COMPLETED_FILTER
      ) {
        result.add(new IsCompleteFilter(false));
      }
      if (
        !useMYN.value &&
        !showNegative.value &&
        selectedFilterId != Constants.PRIORITY_NEGATIVE_FILTER
      ) {
        result.add(
          new NotFilter(
            "Not negative",
            new PriorityFilter(Constants.PRIORITY_NEGATIVE)
          )
        );
      }

      if (!showFutureStarts && !selectedFilterId.includes("start")) {
        const futureFilter = new StartDateFilter(DateFilterOption.Future);
        result.add(new NotFilter("Not future start", futureFilter));
      }
      return result;
    },
    selectedFilter(): AllFilter {
      const result = new AllFilter("Selected Filters");
      if (this.selectedStatusFilter) {
        const filter = this.taskFilterFromId(this.selectedStatusFilter);
        if (filter) result.add(filter);
      }
      if (this.selectedStarFilter) {
        const filter = this.taskFilterFromId(this.selectedStarFilter);
        if (filter) result.add(filter);
      }
      if (this.selectedDueFilter) {
        const filter = this.taskFilterFromId(this.selectedDueFilter);
        if (filter) result.add(filter);
      }
      if (this.selectedUrgencyFilter) {
        const filter = this.taskFilterFromId(this.selectedUrgencyFilter);
        if (filter) result.add(filter);
      }
      if (this.selectedPriorityFilter) {
        const filter = this.taskFilterFromId(this.selectedPriorityFilter);
        if (filter) result.add(filter);
      }
      if (this.selectedStartFilter) {
        const filter = this.taskFilterFromId(this.selectedStartFilter);
        if (filter) result.add(filter);
      }
      if (this.selectedAddedFilter) {
        const filter = this.taskFilterFromId(this.selectedAddedFilter);
        if (filter) result.add(filter);
      }
      if (this.selectedImportedFilter) {
        const filter = this.taskFilterFromId(this.selectedImportedFilter);
        if (filter) result.add(filter);
      }
      if (this.selectedFolderFilter) {
        const filter = this.taskFilterFromId(this.selectedFolderFilter);
        if (filter) result.add(filter);
      }
      if (this.selectedGoalFilter) {
        const filter = this.taskFilterFromId(this.selectedGoalFilter);
        if (filter) result.add(filter);
      }
      if (this.selectedContextFilter) {
        const filter = this.taskFilterFromId(this.selectedContextFilter);
        if (filter) result.add(filter);
      }
      return result;
    },
    selectedStatusTitle: (state) => {
      switch (state.selectedStatusFilter) {
        case Constants.ACTIVE_FILTER: return "Active";
        case Constants.COMPLETED_FILTER: return "Completed";
        default: return "Any";
      }
    },
    selectedStarTitle: (state) => {
      switch (state.selectedStarFilter) {
        case Constants.STARRED_FILTER: return "Starred";
        case Constants.UNSTARRED_FILTER: return "Unstarred";
        default: return "Any";
      }
    },
    selectedUrgencyTitle: (state) => {
      switch (state.selectedUrgencyFilter) {
        case Constants.URGENCY_SOC_FILTER: return "Significant Outcome";
        case Constants.URGENCY_CRITICAL_NOW_FILTER: return "Critical Now";
        case Constants.URGENCY_OPPORTUNITY_NOW_FILTER: return "Opportunity Now";
        case Constants.URGENCY_OVER_THE_HORIZON_FILTER: return "Over the Horizon";
        default: return "Any";
      }
    },
    selectedPriorityTitle: (state) => {
      switch (state.selectedPriorityFilter) {
        case Constants.PRIORITY_TOP_FILTER: return "Top";
        case Constants.PRIORITY_HIGH_FILTER: return "High";
        case Constants.PRIORITY_MEDIUM_FILTER: return "Medium";
        case Constants.PRIORITY_LOW_FILTER: return "Low";
        case Constants.PRIORITY_NEGATIVE: return "Negaive";
        default: return "Any";
      }
    },
    selectedDueTitle: (state) => {
      switch (state.selectedDueFilter) {
        case Constants.DUE_OVERDUE_FILTER: return "Overdue";
        case Constants.DUE_TODAY_FILTER: return "Today";
        case Constants.DUE_BY_TODAY_FILTER: return "By Today";
        case Constants.DUE_TOMORROW_FILTER: return "Tomorrow";
        case Constants.DUE_WEEK_FILTER: return "Within a week";
        case Constants.DUE_MONTH_FILTER: return "Within a month";
        case Constants.DUE_3_MONTHS_FILTER: return "Within 3 months";
        case Constants.DUE_YEAR_FILTER: return "Within a year";
        case Constants.DUE_UNDATED_FILTER: return "Undated"
        default: return "Any";

      }
    },
    selectedStartTitle: (state) => {
      switch (state.selectedStartFilter) {
        case Constants.START_BY_TODAY_FILTER: return "By Today";
        case Constants.START_TODAY_FILTER: return "Today";
        case Constants.START_TOMORROW_FILTER: return "Tomorrow";
        case Constants.START_WEEK_FILTER: return "Within a week";
        case Constants.START_MONTH_FILTER: return "Within a month";
        case Constants.START_3_MONTHS_FILTER: return "Within 3 months";
        case Constants.START_YEAR_FILTER: return "Within a year";
        case Constants.START_UNDATED_FILTER: return "Undated"
        default: return "Any";
      }
    },
    selectedAddedTitle: (state) => {
      switch (state.selectedAddedFilter) {
        case Constants.ADDED_TODAY_FILTER: return "Today";
        case Constants.ADDED_YESTERDAY_FILTER: return "Yesterday";
        case Constants.ADDED_WEEK_FILTER: return "In past week";
        case Constants.ADDED_MONTH_FILTER: return "In past month";
        case Constants.ADDED_3_MONTHS_FILTER: return "In past 3 months";
        case Constants.ADDED_YEAR_FILTER: return "In past year";
        case Constants.ADDED_UNDATED_FILTER: return "Undated";
        case Constants.ADDED_OLDER_FILTER: return "Over a year ago";
        default: return "Any";
      }
    },
    selectedImportedTitle: (state) => {
      switch (state.selectedImportedFilter) {
        case Constants.IMPORTED_TODAY_FILTER: return "Today";
        case Constants.IMPORTED_YESTERDAY_FILTER: return "Yesterday";
        case Constants.IMPORTED_WEEK_FILTER: return "In past week";
        case Constants.IMPORTED_MONTH_FILTER: return "In past month";
        case Constants.IMPORTED_3_MONTHS_FILTER: return "In past 3 months";
        case Constants.IMPORTED_YEAR_FILTER: return "In past year";
        case Constants.NOT_IMPORTED_FILTER: return "Not imported";
        case Constants.IMPORTED_OLDER_FILTER: return "Over a year ago";
        default: return "Any";
      }
    },

  },
  actions: {

    async load() {

      const userStore = useUserStore();

      const userKey = `taskangel_filters_${userStore.user?.uid || 'no_user_id'}`

      try {
        const json = localStorage.getItem(userKey)
        if (json) {
          const s = JSON.parse(json)
          s.filtersLoaded = true;
          s.filtersLoading = false;
          this.$patch(s)
        }
      }
      catch (error) {
        Sentry.captureException(error);
        Sentry.captureMessage(`Failure in while watching filters state changes`);
      }
    },

    async init() {
      await this.load()
      const userStore = useUserStore();
      userStore.$subscribe(async () => {
        await this.load();

      })
      watch(() => this, async (state) => {
        const userKey = `taskangel_filters_${userStore.user?.uid || 'no_user_id'}`
        const stringifiedState = serializeState(state)
        localStorage.setItem(userKey, stringifiedState)
      },
        { deep: true }
      );

    },

    clearSelectedFilters(except?: any) {

      this.$patch({
        selectedStatusFilter: Constants.STATUS_ANY_FILTER,
        selectedStatusTitle: "Any",
        selectedStarFilter: Constants.STARRED_ANY_FILTER,
        selectedStarTitle: "Any",
        selectedDueFilter: Constants.DUE_ANY_FILTER,
        selectedDueTitle: "Any",
        selectedUrgencyFilter: Constants.URGENCY_ANY_FILTER,
        selectedUrgencyTitle: "Any",
        selectedPriorityFilter: Constants.PRIORITY_ANY_FILTER,
        selectedPriorityTitle: "Any",
        selectedStartFilter: Constants.START_ANY_FILTER,
        selectedStartTitle: "Any",
        selectedAddedFilter: Constants.ADDED_ANY_FILTER,
        selectedAddedTitle: "Any",
        selectedImportedFilter: Constants.IMPORTED_ANY_FILTER,
        selectedImportedTitle: "Any",
        selectedFolderFilter: Constants.FOLDER_ANY_FILTER,
        selectedFolderTitle: "Any",
        selectedContextFilter: Constants.CONTEXT_ANY_FILTER,
        selectedContextTitle: "Any",
        selectedGoalFilter: Constants.GOAL_ANY_FILTER,
        selectedGoalTitle: "Any",
        ...except
      });

      const { tasksSearchText } = storeToRefs(useSettingsStore());
      tasksSearchText.value = ''

    },
  },
});
