import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
// import { useUserStore } from "@/stores/useUserStore";

// export default defineComponent({
//   name: "InstallPWA",
//   components: {
//     IonCard,
//     IonCardHeader,
//     IonCardTitle,
//     IonCardContent,
//     IonButton,
//   },
//   setup() {
// const deferredPrompt = ref(null); // debugging set to true. - for production, set to null
// the following code has been moved to App.vue
// console.log(`InstallPWA starts listening for beforeinstallprompt event`);

export default /*@__PURE__*/_defineComponent({
  __name: 'InstallPWA',
  setup(__props) {

const deferredPrompt = ref(null);
const cancelled = ref(false);

// const userStore = useUserStore();

const installPWA = async () => {
  if (deferredPrompt.value) {
    deferredPrompt.value.prompt();
    const { outcome } = await deferredPrompt.value.userChoice;
    if (outcome === "accepted") {
      // console.log("User accepted the install prompt");
    } else {
      // console.log("User dismissed the install prompt");
    }
    deferredPrompt.value = null;
  }
};

const onCancel = () => {
  // console.log(`User cancelled InstallPWA`);
  deferredPrompt.value = null;
  cancelled.value = true;
};

onMounted(async () => {
  window.addEventListener("beforeinstallprompt", (e) => {
    // console.log(`InstallPWA sees beforeinstallprompt event`);
    e.preventDefault();
    deferredPrompt.value = e;
  });
});
// return { deferredPrompt, installPWA };
//   },
// });

return (_ctx: any,_cache: any) => {
  return (!cancelled.value && !!deferredPrompt.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(IonCard), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonCardHeader), null, {
              default: _withCtx(() => [
                _createVNode(_unref(IonCardTitle), null, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Install TaskAngel")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(IonCardContent), null, {
              default: _withCtx(() => [
                (deferredPrompt.value)
                  ? (_openBlock(), _createBlock(_unref(IonButton), {
                      key: 0,
                      onClick: installPWA
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("Install")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_unref(IonButton), {
                  onClick: onCancel,
                  fill: "clear"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Cancel")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})