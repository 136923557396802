/* eslint-disable no-console */

import { register } from 'register-service-worker'
// import { ref } from 'vue'

// let savedRegistration: ServiceWorkerRegistration = null

// const refreshing = ref(false)
// const forceReload = () => {
//   if (refreshing.value) return
//   refreshing.value = true
//   // Here the actual reload of the page occurs
//   window.location.reload()

//   // if(environment.production) {
//     console.log(`service worker has called forceReload()`)
//     if (process.env.NODE_ENV === 'production') {
//       const form = document.createElement('form');
//       form.method = "POST";
//       form.action = location.href;
//       document.body.appendChild(form);
//       console.log(`service worker forceReload is submitting dummy POST form`)
//       form.submit();
//   } else {
//       window.location.reload();
//   }
// }



if (process.env.NODE_ENV === 'production') {

  self.addEventListener('install', () => {
    console.log("Service worker sees it is being installed");
  })
  // listen for skip waiting message from App
  // console.log("Service worker is listening for messages from App");

  self.addEventListener('install', (event: any) => {
    console.log('service worker second event listener for installing…');
  
    // cache a horse SVG into a new cache, static-v2
    event.data?.waitUntil(
      caches.open('static-v2').then(cache => cache.add('/horse.svg'))
    );
  });

  // we don't need to listen for skip waiting here. The default service worker does it automatically

  // self.addEventListener('message', (event) => {
  //   if (event.data && event.data.type === 'SKIP_WAITING') {
  //     console.log(`Service worker sees skip waiting event and calls skipWaiting`)
  //     savedRegistration.waiting.skipWaiting()
  //   }
  // })
  
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      // console.log(
      //   'App is being served from cache by a service worker.\n' +
      //   'For more details, visit https://goo.gl/AFskqB'       
      // )


    },
    registered (registration) {
      // console.log('Service worker has been registered.')
      // console.log('Service worker is monitoring for updates every hour')
      // do an initial check before starting the loop
      // console.log('Service worker is doing first check for update');
      registration.update();

      setInterval(() => {
        // console.log('Service worker is checking for another update');
        registration.update();
      }, 
      1000 * 60 * 60); // e.g. hourly checks
    },

    // registered (registration) {
    //   console.log('Service worker has been registered.')
    //   console.log('Service worker is monitoring for updates every hour')
    //   savedRegistration = registration
    //   setInterval(() => {
    //     registration.update();
    //   }, 1000 * 60 * 60); // hourly checks
    // },
    // registered () {
    //   console.log('Service worker has been registered.')
    // },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('Service worker sees New content is available; sending event to App', registration)
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    },
    // updated(registration) {
    //   console.log('Service worker sees New content is available; sending App aevent.')
    //   document.dispatchEvent(
    //     new CustomEvent('swUpdated', { detail: registration })
    //   )
    // },

    
    
    // updated (registration) {
    //   console.log('Service worker is dispatching swUpdated event.')
    //   document.dispatchEvent(
    //     new CustomEvent('swUpdated')
    //   )
    //   console.log("Service worker is telling the updated worker to skip waiting");
    //       registration.waiting?.postMessage({ type: 'SKIP_WAITING' });

    //   // app receives message and asks the user whther or not to reload.

    //   console.log("Service worker is forcing a reload");
    //   if (refreshing.value) return
    //   console.log("Service worker is not already refreshing, so we do the reload");
    //   refreshing.value = true
    //   // Here the actual reload of the page occurs
    //   window.location.reload()


    //   window.location.reload()
    // },
    // updated(registration) {
    //   console.log('New content is available; please refresh.')
    //   document.dispatchEvent(
    //     new CustomEvent('swUpdated', { detail: registration })
    //   )
    // },
    // updated () {
    //   console.log('New content is available; please refresh.')
    // },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
  })
}
