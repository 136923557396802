// set up the model

import { Syncable } from "./Syncable";
import { formatISO } from "date-fns";

export interface GoalType extends Syncable {
  id?: string;
  title?: string;
  contributesId?: string;
  added?: Date;
  modified?: Date;
  note?: string;

  copyToSimpleObject(): any;
}

export class GoalObject implements GoalType {
  id?: string;
  title?: string;
  contributesId?: string;
  added?: Date;
  modified?: Date;
  note?: string;

  // constructor(title?: string){
  //     this.title = title ?? '';
  // }
  //
  constructor(goalData: Partial<GoalObject>) {
    Object.assign(this, goalData);
  }

  copyToSimpleObject() {
    const defaults = {
      id: "",
      title: "",
      contributesId: "",
      added: "",
      modified: "",
      note: "",
    };

    const result = {
      ...defaults,
      ...this,
    };

    return result;
  }

  copyToFirestoreObject() {
    const result = {
      id: this.id ?? "",
      contributesId: this.contributesId ?? "",
      title: this.title ?? "",
      added: this.added ? formatISO(this.added) : "",
      modified: this.modified ? formatISO(this.modified) : "",
      note: this.note ?? "",
    };
    return result;
  }

  static fromFirestoreObject(source: any): GoalObject {
    if (!source) return null;

    return new GoalObject({
      id: source.id,
      contributesId: source.contributesId,
      title: source.title ?? "",
      added: source.added ? new Date(source.added) : undefined,
      modified: source.modified ? new Date(source.modified) : undefined,
      note: source.note,
    });
  }
}
