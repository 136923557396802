import { SortDirection } from "@/models/SortDirection";
import { TaskObject } from "@/models/TaskObject";
import { useFoldersStore } from "@/stores/useFoldersStore";
import { TaskComparer } from "./TaskComparer";

export class TaskFolderComparer extends TaskComparer {
  constructor(direction: SortDirection) {
    super(direction);
  }
  compare = (a: TaskObject, b: TaskObject) => {
    const ascending = this.direction === SortDirection.Ascending;
    const foldersStore = useFoldersStore();

    if (a.folderId == b.folderId) return 0;
    if (!a.folderId) return ascending ? -1 : 1;
    if (!b.folderId) return ascending ? 1 : -1;

    const aFolder = foldersStore.folderFromId(a.folderId);
    const bFolder = foldersStore.folderFromId(b.folderId);

    if (!aFolder?.title) return ascending ? -1 : 1;
    if (!bFolder?.title) return ascending ? 1 : -1;

    const rawCompare = aFolder.title.localeCompare(bFolder.title);
    const descendingMultiplier = ascending ? 1 : -1;
    return rawCompare * descendingMultiplier;
  };
}
