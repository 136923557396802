import { Urgency } from "@/models/Urgency";
import { SortDirection } from "@/models/SortDirection";
import { TaskObject } from "@/models/TaskObject";
import { TaskComparer } from "./TaskComparer";

export class TaskUrgencyComparer extends TaskComparer {
  constructor(direction: SortDirection) {
    super(direction);
  }
  compare = (a: TaskObject, b: TaskObject) => {
    const ascending = this.direction === SortDirection.Ascending;
    const urgencyOrder = [
      Urgency.OverTheHorizon,
      Urgency.OpportunityNow,
      Urgency.CriticalNow,
      Urgency.SOC,
    ];
    if (a.urgency == b.urgency) return 0;
    if (!a.urgency) return ascending ? -1 : 1;
    if (!b.urgency) return ascending ? 1 : -1;
    if (urgencyOrder.indexOf(a.urgency) < urgencyOrder.indexOf(b.urgency))
      return ascending ? -1 : 1;
    return ascending ? 1 : -1;
  };
}
