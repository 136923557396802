import { Property } from "@/models/Property";
import { SortDirection } from "@/models/SortDirection";
import { TaskObject } from "@/models/TaskObject";
import { TaskComparer } from "./TaskComparer";
import { TaskDueComparer } from "./TaskDueComparer";
import { TaskPriorityComparer } from "./TaskPriorityComparer";
import { TaskSortDescriptor } from "./TaskSortDescriptor";
import { TaskStartComparer } from "./TaskStartComparer";
import { TaskTitleComparer } from "./TaskTitleComparer";
import { TaskUrgencyComparer } from "./TaskUrgencyComparer";
import { TaskFolderComparer } from "./TaskFolderComparer";
import { TaskGoalComparer } from "./TaskGoalComparer";
import { TaskContextComparer } from "./TaskContextComparer";

type ComparerFactory = (direction: SortDirection) => TaskComparer;
const comparerMap: Record<Property, ComparerFactory> = {
  [Property.Priority]: (direction) => new TaskPriorityComparer(direction),
  [Property.Due]: (direction) => new TaskDueComparer(direction),
  [Property.Start]: (direction) => new TaskStartComparer(direction),
  [Property.Title]: (direction) => new TaskTitleComparer(direction),
  [Property.Urgency]: (direction) => new TaskUrgencyComparer(direction),
  [Property.Folder]: (direction) => new TaskFolderComparer(direction),
  [Property.Goal]: (direction) => new TaskGoalComparer(direction),
  [Property.Context]: (direction) => new TaskContextComparer(direction),
  [Property.None]: () => null,
};

const getComparer = (
  property: Property,
  direction: SortDirection
): TaskComparer => {
  const factory = comparerMap[property];
  if (!factory) {
    throw new Error(`No comparer found for property: ${property}`);
  }
  return factory(direction);
};

export class TaskSorter {
  constructor(public descriptors: TaskSortDescriptor[]) {}

  // Usage
  // const comparer = getComparer(property, descriptor.direction);

  // note: these member function are arrow function so they can access 'this'

  compare = (a: TaskObject, b: TaskObject) => {
    let result = 0;
    if (this.descriptors.length === 0) return 0;

    for (const descriptor of this.descriptors) {
      const comparer = getComparer(descriptor.property, descriptor.direction);
      if (!comparer)
        throw `Could not get comparer for property ${descriptor.property} and direction ${descriptor.direction}`;
      result = comparer.compare(a, b);
      if (result) return result;
    }
    return 0;
  };

  sort = (tasks: TaskObject[]): TaskObject[] => {
    const copy = [...tasks];
    copy.sort(this.compare);
    return copy;
  };
}
