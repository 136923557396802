import { NoteFilter } from "./NoteFilter";
import { NoteObject } from "../NoteObject";

export class TextNoteFilter extends NoteFilter{
    public get debugDescription(): string {
        return `TextNoteFilter: ${this.value}`;
    }
    
    value: string;
    constructor(value: string) {
        super(value);
        this.value = value;
    }

    public isFiltered(item: NoteObject): boolean {
        if(item.title?.toLowerCase().includes(this.value.toLowerCase())){
            return true
        }
        if (!item.text) {
            return false
        }
        if (item.text.toLowerCase().indexOf(this.value.toLowerCase()) >= 0) {
            return true;
        }
        // console.log(`Note with title ${item.title} does not include ${this.value}`)
        // if(item.text?.includes(this.value)){
        //     return true
        // }          
        // console.log(`Note with text ${item.text} does not include ${this.value}`)
        // if(!item.title) {
        //     return false;
        // } 
        // if (item.title.toLowerCase().indexOf(this.value.toLowerCase()) >= 0) {
        //     return true;
        // }
        // if(!item.text) {
        //     return false;
        // } 
        // if (item.text.toLowerCase().indexOf(this.value.toLowerCase()) >= 0) {
        //     return true;
        // }
        return false;
    }
    
    public get description(){
        return this.value;
    }

    
}