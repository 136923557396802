import { Constants } from "@/constants/constants";
import {
  checkoutSessionsCollectionRef,
  getCollectionDocs,
  listenForCollectionChanges,
  subscriptionsCollectionRef,
} from "@/firebase/firebase-auth";
import { Logger } from "@/helpers/Logger";
import { SubscriptionObject } from "@/models/SubscriptionObject";
import * as Sentry from "@sentry/vue";
import { loadStripe } from "@stripe/stripe-js";
import { addDays } from "date-fns";
import {
  doc,
  DocumentChange,
  DocumentData,
  onSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
} from "firebase/firestore";
import { defineStore, storeToRefs } from "pinia";
import { v4 as generateUUID } from "uuid";
import { useFeatureFlagsStore } from "./useFeatureFlagsStore";
import { useUserStore } from "./useUserStore";
import { SubscribePageStep } from "@/models/SubscribePageStep";
import { DateFormatter } from "@/helpers/DateFormatter";
import { JourneyStage } from "@/models/JourneyStage";

interface SubscriptionState {
  subscribePageStep: SubscribePageStep;
  subscriptions: SubscriptionObject[];
  isSubscribing: boolean;
  // subscription: SubscriptionObject;
  subscriptionStatus: string;
  firebaseUser: any;
  stripe: any;
  isFirestoreSubscribed: boolean;
  haveSubscriptionsLoaded: boolean;
  isLoadingSubscriptions: boolean;
}

let firebaseSubscriptionsUnsubscribe: { (): void; (): void } | null = null;
export const useSubscriptionsStore = defineStore("subscriptionsStore", {
  state: (): SubscriptionState => ({
    subscriptions: Array<SubscriptionObject>(),
    isSubscribing: false,
    // subscription: new SubscriptionObject(),
    subscriptionStatus: "",
    firebaseUser: null,
    stripe: null,
    subscribePageStep: SubscribePageStep.Starting,
    isFirestoreSubscribed: false,
    haveSubscriptionsLoaded: false,
    isLoadingSubscriptions: false,
  }),

  getters: {
    isRunningInProduction: (): boolean => {
      return window.location.hostname == "app.taskangel.com";
    },
    isTesting(): boolean {
      return !this.isRunningInProduction;
    },

    freeTrialExpiryDate: (): Date | null => {
      const userStore = useUserStore();
      if (!userStore.user?.metadata) return null;
      const created = userStore.user.metadata.creationTime; // creation date as an utc string
      const createdDate = new Date(created);
      return addDays(createdDate, 7);
    },

    journeyStage(): JourneyStage {
      try {
        const userStore = useUserStore();
        if (!userStore.user?.metadata) {
          return JourneyStage.Unregistered;
        }

        const created = userStore.user.metadata.creationTime;
        if (created) {
          const createdDate = new Date(created);
          const lastFounder = new Date(2021, 4, 1);
          if (createdDate < lastFounder) {
            return JourneyStage.Founder;
          }
        }

        if (this.activeSubscription) {
          return JourneyStage.Premium;
        }

        if (created) {
          const userStore = useUserStore();
          const { user } = userStore;
          if (!user || !user.metadata) return null;

          const createdDate = new Date(created);

          const freeTrialExpiry = addDays(createdDate, 7);
          if (freeTrialExpiry > new Date()) {
            return JourneyStage.InFreeTrial;
          }
          return JourneyStage.FreeTrialExpired;
        }

        return JourneyStage.Unregistered;
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    journeyStageAsString(): string {
      try {
        if (!this.journeyStage) return "null";
        return JourneyStage[this.journeyStage];
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    canSubscribe() {
      const userStore = useUserStore();
      if (!userStore.user?.metadata) {
        return true;
      }
      const userCreated = userStore.user.metadata?.creationTime; // creation date as an utc string

      if (!userCreated) {
        return true;
      }

      if (userStore.isFounder) return false;

      if (this.activeSubscription) return false;
      return true;
    },

    activeSubscription(state) {
      const found = state.subscriptions.find((sub) => sub.status === "active");
      if (!found) return null;
      return SubscriptionObject.copyFromAnyObject(found);
    },

    canManage() {
      const userStore = useUserStore();
      const meta = userStore.user?.metadata;
      if (!meta) {
        return false;
      }
      const userCreated = meta.creationTime;
      if (!userCreated) return false;

      if (userStore.isFounder) return false;
      if (!this.activeSubscription) return false;
    },

    statusMessage(): string {
      const userStore = useUserStore();

      if (!userStore.user && !this.firebaseUser) {
        return "Please sign to see your subscription status";
      }
      const featureFlagsStore = useFeatureFlagsStore();
      const { freePassFlag } = storeToRefs(featureFlagsStore);
      if (freePassFlag.value) {
        return "You have a free pass. Enjoy!";
      }

      if (userStore.isFounder)
        return "You are a founder member, with a free subscription. Thank you.";

      if (!this.haveSubscriptionsLoaded)
        return "Waiting to load subscription details";

      if (this.activeSubscription?.status === "active")
        return `You have a Monthly subscription. Thank you.`;
      if (this.activeSubscription?.status === "canceled")
        return `You have cancelled your subscription. Please come back soon.`;

      if (userStore.isInFreeTrial) {
        return `Your free trial will continue until ${DateFormatter.formatDate(
          userStore.freeTrialExpiryDate
        )}.`;
      }

      return `To continue using TaskAngel, please click on the subscribe button.`;
    },
  },

  actions: {
    clearSubscriptions() {
      this.subscriptions = [];
      this.isSubscribing = false;
    },

    // subscribeToFirestoreSubscriptions() {
    //   return new Promise<SubscriptionObject>((resolve, reject) => {
    //     if (!subscriptionsCollectionRef)
    //       throw new Error(
    //         `subscribeToFirestoreSubscriptions has no collection ref`
    //       );

    //     try {
    //       if (firebaseSubscriptionsUnsubscribe) resolve(this.subscription);
    //       const userStore = useUserStore();
    //       if (!userStore.user)
    //         reject(
    //           new Error(
    //             `Error when getting subscriptions. User must be logged in`
    //           )
    //         );

    //       firebaseSubscriptionsUnsubscribe = onSnapshot(
    //         subscriptionsCollectionRef,
    //         (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
    //           try {
    //             const docs = snapshot.docs;
    //             if (!Array.isArray(docs)) {
    //               console.log(
    //                 "subscribeToFirestoreSubs snapshot sees docs are not an array"
    //               );
    //               reject(
    //                 new Error(
    //                   "subscribeToFirestoreSubs snapshot sees docs are not an array"
    //                 )
    //               );
    //             }
    //             if (docs.length === 0) {
    //               console.log(
    //                 "subscribeToFirestoreSubss snapshot sees there are no docs"
    //               );
    //               this.subscription = null;
    //             }

    //             snapshot.docs.forEach((doc) => {
    //               const data = doc.data();
    //               const id = doc.id;
    //               const subscription = SubscriptionObject.copyFromAnyObject(
    //                 data,
    //                 id
    //               );

    //               this.subscription = subscription;
    //             });

    //             this.isSubscribing = true;
    //             resolve(this.subscription);
    //           } catch (error) {
    //             Logger.logError("Error while processing a subscription", error);
    //             reject(error);
    //           }
    //         }
    //       );

    //       this.isFirestoreSubscribed = true;
    //     } catch (error) {
    //       Logger.logError("Error while subscribing to subscriptions", error);
    //       reject(error);
    //     }
    //   });
    // },

    // async loadSubscriptions(): Promise<SubscriptionObject> {
    //   if (this.isLoadingSubscriptions) {
    //     throw new Error(
    //       `Error: loadSubscriptions sees subs are already loading`
    //     );
    //   }
    //   if (this.haveSubscriptionsLoaded) {
    //     throw new Error(
    //       `Error: loadSubscriptions sees subs have already loaded`
    //     );
    //   }
    //   const userStore = useUserStore();
    //   if (!userStore.user) {
    //     Logger.logError(`loadSubscriptions aborts because there is no user`);
    //     throw new Error(`loadSubscriptions aborts because there is no user`);
    //   }
    //   try {
    //     if (this.isSubscribing) {
    //       return this.subscription;
    //     }
    //     await this.subscribeToFirestoreSubscriptions();
    //     this.$patch({
    //       haveSubscriptionsLoaded: true,
    //       isLoadingSubscriptions: false,
    //     });
    //   } catch (error) {
    //     Logger.logError("Error in LoadSubscriptions", error);
    //   }
    // },
    //

    async loadSubscriptions() {
      try {
        console.log("loadSubscriptions is starting");
        Logger.log(`loadSubscriptions is starting`);

        console.log(
          `loadSubscriptions is calling getCollectionDocs for subscriptions`
        );
        const docs = await getCollectionDocs("subscriptions");
        console.log(`loadSubscriptions has got ${docs.length} docs`);

        const fetched = this.convertFirestoreDocs(docs);
        this.$patch({ subscriptions: fetched, haveSubscriptionsLoaded: true });

        console.log(
          `loadSubscriptions has converted ${this.subscriptions.length} subscriptions and starts listening for changes`
        );
        this.haveSubscriptionsLoaded = true;
        this.listenForFirestoreSubscriptionChanges();
        console.log(
          `loadSubscriptions is listening for changes. There are currently ${this.subscriptions.length} subscriptions.`
        );
      } catch (error) {
        Logger.logError("Error in loadSubscriptions", error);
        throw error;
      }
    },

    listenForFirestoreSubscriptionChanges() {
      firebaseSubscriptionsUnsubscribe = listenForCollectionChanges(
        "subscriptions",
        (changes: DocumentChange<DocumentData, DocumentData>[]) => {
          this.processFirestoreChanges(changes);
        }
      );
    },
    processFirestoreChanges(
      changes: DocumentChange<DocumentData, DocumentData>[]
    ) {
      for (const change of changes) {
        this.processFirestoreChange(change);
      }
      console.log(
        `after processFirestoreChanges we now have ${this.subscriptions.length} subscriptions`
      );
    },
    processFirestoreChange(change: DocumentChange<DocumentData, DocumentData>) {
      const source = change.doc.data();
      const id = change.doc.id;
      source.id = id;
      const sub = SubscriptionObject.copyFromAnyObject(source);
      if (change.type === "added") this.processFirestoreAdded(sub);
      else if (change.type === "modified") this.processFirestoreModified(sub);
      else if (change.type === "removed") this.processFirestoreRemoved(sub);
    },

    unsubscribeSubscriptions() {
      if (firebaseSubscriptionsUnsubscribe) {
        firebaseSubscriptionsUnsubscribe();
        firebaseSubscriptionsUnsubscribe = null;
      }
      this.isSubscribing = false;
    },
    processFirestoreAdded(subscription: SubscriptionObject) {
      this.subscriptions.push(subscription);
    },

    processFirestoreModified(subscription: SubscriptionObject) {
      const subscriptionIndex = this.subscriptions.findIndex(
        (t: SubscriptionObject) => t.id == subscription.id
      );
      if (subscriptionIndex < 1) return;

      this.subscriptions.splice(subscriptionIndex, 1);
      this.subscriptions.push(subscription);
    },

    processFirestoreRemoved(subscription: SubscriptionObject) {
      const subscriptionIndex = this.subscriptions.findIndex(
        (t: SubscriptionObject) => t.id == subscription.id
      );
      if (subscriptionIndex < 1) {
        return;
      }
      this.subscriptions.splice(subscriptionIndex, 1);
    },

    convertFirestoreDocs(
      docs: QueryDocumentSnapshot<DocumentData, DocumentData>[]
    ) {
      const result = Array<SubscriptionObject>();
      docs.forEach((doc: any) => {
        const context = this.convertFirestoreDoc(doc);
        result.push(context);
      });
      return result;
    },

    convertFirestoreDoc(doc: any) {
      const source = doc.data();
      return SubscriptionObject.copyFromAnyObject(source);
    },

    async writeCheckoutSessionToFirestore(session: any) {
      try {
        const checkoutCollectionRef = checkoutSessionsCollectionRef;
        const docRef = doc(checkoutCollectionRef, session.id);
        await setDoc(docRef, session);
        return docRef;
      } catch (error) {
        Logger.logError("Error writing checkout session for Firestore", error);
        throw error;
      }
    },

    async callSubscriptionCheckout() {
      const checkoutSession = {
        price: "price_1KDBcUJhaCQ1c8lR6ksI90lx",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        id: generateUUID(),
      };

      const docRef = await this.writeCheckoutSessionToFirestore(
        checkoutSession
      );

      onSnapshot(docRef, (snap: any) => {
        const { error, url } = snap.data();
        if (error) {
          Logger.logError(
            "Error while processing snapshot for checkout",
            error
          );
          throw error;
        }
        if (url) {
          window.location.assign(url);
        }
      });
    },

    async startFreeTrial() {
      const userStore = useUserStore();
      if (!userStore.user?.uid) {
        return;
      }

      if (!userStore.user) return;

      const session = {
        id: generateUUID(),
        mode: "subscription",
        price: Constants.MONTHLY_PRICE_ID,
        success_url: "https://app.taskangel.com",
        cancel_url: "https://app.taskangel.com",
        subscription_data: {
          trial_settings: {
            end_behavior: {
              missing_payment_method: "cancel",
            },
          },
          trial_period_days: Constants.TRIAL_PERIOD_DAYS,
        },
        payment_method_collection: "if_required",
      };

      const docRef = await this.writeCheckoutSessionToFirestore(session);

      onSnapshot(docRef, async (snap: { data: () => any }) => {
        const snapData = snap.data();
        const error = snapData?.error;
        const sessionId = snapData?.sessionId;

        if (error) {
          Logger.logError("Error while processing checkout snapshot", error);
          throw error;
        }
        if (sessionId) {
          const stripeApiKey = this.isTesting
            ? process.env.STRIPE_SANDBOX_PUBLISHABLE_API_KEY
            : process.env.STRIPE_PUBLISHABLE_API_KEY;

          const stripe = await loadStripe(stripeApiKey);
          await stripe.redirectToCheckout({ sessionId });
        }
      });
    },

    async checkoutUsingPaymentLink() {
      const userStore = useUserStore();
      // const router = useRouter();
      const { uid } = storeToRefs(userStore);
      if (!uid.value) throw new Error(`You must be logged in to do this`);
      const paymentLink: string = this.isTesting
        ? `https://buy.stripe.com/test_9AQ9Br1rQg8n2nm6oo?customer_id=${uid.value}`
        : `https://buy.stripe.com/4gw6qoaPLg2bfgAbII?customer_id=${uid.value}`;

      window.open(paymentLink, "_blank");
    },

    async gotoCheckout() {
      const featureFlagsStore = useFeatureFlagsStore();
      const { stripeDirectFlag } = storeToRefs(featureFlagsStore);
      if (stripeDirectFlag.value) {
        this.checkoutUsingPaymentLink();
        return;
      }

      if (this.isTesting) return;
      const userStore = useUserStore();
      if (!userStore.user) {
        throw new Error("To subscribe you must be logged in");
      }

      const session = {
        id: generateUUID(),
        mode: "subscription",
        price: Constants.MONTHLY_PRICE_ID,
        success_url: window.location.origin, // 'https://app.taskangel.com',
        cancel_url: window.location.origin, // 'https://app.taskangel.com',
      };

      const docRef = await this.writeCheckoutSessionToFirestore(session);
      onSnapshot(docRef, async (snap: { data: () => any }) => {
        const snapData = snap.data();
        const error = snapData?.error;
        if (error) {
          Logger.logError("Error while processing checkout snapshot", error);
          throw error;
        }
        const sessionId = snapData?.sessionId;

        if (sessionId) {
          const stripeApiKey = this.isTesting
            ? process.env.STRIPE_SANDBOX_PUBLISHABLE_API_KEY
            : process.env.STRIPE_PUBLISHABLE_API_KEY;
          const stripe = await loadStripe(stripeApiKey);
          await stripe.redirectToCheckout({ sessionId });
        }
      });
    },
  },
});
