export enum DateFilterOption {
  Overdue = "overdue", // date is before today
  Started = "started", // date is today or before today
  ByToday = "bytoday", // date is today or before today
  Today = "today", // date is today, not before or after
  Tomorrow = "tomorrow", // date is tomorrow
  Week = "week", // date is within 7 days
  Month = "month", // date is within 1 month
  Quarter = "quarter", // date is within 3 months
  Year = "year", // date is within 1 year
  Undated = "undated", // date is undefined or null
  Future = "future",
  Yesterday = "yesterday",
  Older = "older", // older than a year

}
