export enum Status {
    None,
    NextAction,
    Active,
    Planning,
    Delegate,
    Waiting,
    Hold,
    Postponed,
    Someday,
    Canceled,
    Reference
}