import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }

import { Constants } from "@/constants/constants";
import { MenuGroupObject } from "@/models/MenuGroupObject";
import { MenuItemObject } from "@/models/MenuItemObject";
import {
  // IonButtons,
  IonHeader,
  IonItem,
  // IonToolbar,
  // IonButton,
  // IonIcon,
  // IonLabel,
  IonTitle,
} from "@ionic/vue";
import {
  // computed,
  onBeforeUnmount,
  onMounted,
  // Ref,
  ref,
} from "vue";
import FilterMenuItem from "../components/FilterMenuItem.vue";
// import { useStore } from "vuex";
// import { NoteFilter } from "@/models/filters/NoteFilter";
import FilterMenuGroup from "./FilterMenuGroup.vue";

import { useFoldersStore } from "@/stores/useFoldersStore";
import { useNotesStore } from "@/stores/useNotesStore";
import { useSettingsStore } from "@/stores/useSettingsStore";
import { storeToRefs } from "pinia";

// setup: (_, ctx: SetupContext) => {
// const isRefreshing = ref(false)

// Pinea store

export default /*@__PURE__*/_defineComponent({
  __name: 'NoteFilterPanel',
  emits: ["selected"],
  setup(__props, { emit: __emit }) {

const settings = useSettingsStore();
// const filterStore = useFiltersStore();
// const store = useStore();
const {
  notesSearchText,
  sortNotesBy,
  sortNotesDirection,
  currentNoteFilterId,
  openNoteFilterGroup,
} = storeToRefs(settings);

const emit = __emit;

// const { loadSettings, } = settings;

const notesStore = useNotesStore();
const { notes } = storeToRefs(notesStore);

// const { countNotesFilteredBy } = filterStore;

// list data for menu items

const filterMenuData = ref(null);

const makeMenuData = async () => {
  // console.log(`NoteFilterPanel makeMenuData is making menu data`);
  // console.log(
  //   `NoteFilterPanel makeMenuData sees settingsLoaded is ${settingsLoaded.value} and settingsLoading is ${settingsLoading.value}`
  // );

  // if (!settingsLoaded.value && !settingsLoading.value) {
  //   // console.log(`NoteFilterPanel asks for settings to be loaded`);
  //   await loadSettings();
  // }
  const result = Array<MenuItemObject>();
  result.push(
    new MenuItemObject(
      Constants.ALL_NOTES_FILTER,
      "All Notes",
      notes.value?.length ?? 0
    )
  );

  // make folder filters
  const folderMenuItems = new Array<MenuItemObject>();

  const noFolderNotes = notes.value.filter((n) => !n.folderId);
  // console.log(`NoteFilterPanel sees no-folder notes ${JSON.stringify(noFolderNotes)}`)

  folderMenuItems.push(
    new MenuItemObject(
      Constants.FOLDER_NONE_NOTE_FILTER,
      Constants.INBOX_TITLE,
      // null,
      noFolderNotes.length
    )
  );

  // console.log(`NoteFilterPanel is calling vuex to get folders`);
  const folders = useFoldersStore().folders;
  // console.log(`NoteFilterPanel called vuex and got ${folders.length} folders`);
  if (folders.length > 0) {
    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i];
      const folderId = folder.id;
      const notesInFolder = notes.value.filter((n) => n.folderId === folderId);

      const menuItem = new MenuItemObject(
        Constants.FOLDER_NOTE_FILTER_PREFIX + folderId,
        folder.title,
        // null,
        notesInFolder.length
      );
      folderMenuItems.push(menuItem);
    }
  }

  // console.log(
  //   `NoteFilterPanel has made ${folderMenuItems.length} folder menu items and is making a folder group`
  // );

  const folderGroup = new MenuGroupObject(
    Constants.FOLDER_GROUP_NOTE_FILTER,
    "Folders",
    openNoteFilterGroup.value === Constants.FOLDER_GROUP_NOTE_FILTER
      ? "open"
      : "closed",
    folderMenuItems
  );

  result.push(folderGroup);

  // console.log(`NoteFilterPanel made filter menu data ${JSON.stringify(result)}`);
  // return filter menu data
  return result;
};

// filterMenuData.value = await makeMenuData();

/**
 * A key used to cause the list to refresh itself
 *
 * @remarks
 * Each item in the template is given a key made up of listKey and a filter id.
 * We can refresh the list by incrementing the list key.
 *
 */
const listKey = ref(0);

// computed properties

// functions

function isGroup(item: MenuItemObject) {
  return item.id.indexOf("group") >= 0;
}

function isOpen(item: MenuItemObject) {
  return item.id === openNoteFilterGroup.value;
}

// const filterMenuLength = computed(()=>{
//   return filterMenuData.value?.length ?? 0;
// })

// events

/**
 * Handle All Notes button
 *
 * @remarks
 * This clears the filters and then applies presets in Settings
 */
// const handleAllNotes = async () => {
//   // console.log(`Filter panel sees AllNotes filter tapped`)
//   const settings = useSettingsStore();
//   await settings.setCurrentNoteFilterId(Constants.ALL_NOTES_FILTER);
// };

const onItemSelected = async (ev: any) => {
  // console.log(`Note Filter panel calls store to select filter: ${ev.id}`);
  notesSearchText.value = "";
  sortNotesBy.value = Constants.SORT_ALPHA;
  sortNotesDirection.value = "ascending";
  currentNoteFilterId.value = ev.id;
  notesSearchText.value = "";

  emit("selected", ev.id);
};

/**
 * Handle when use clicks on open/close for a filter group.
 *
 * @remarks
 * There can be only one filter group open at once. This is saved in settings.
 * This function changes that settings value
 */
const onGroupChanged = (ev: any) => {
  // console.log(
  //   `FilterPanel onGroupChanged sees event: ${JSON.stringify(ev)}`
  // );
  switch (ev.value) {
    case "open":
      openNoteFilterGroup.value = ev.id;
      break;
    case "closed":
      openNoteFilterGroup.value = "";
      break;
  }
};

const unsubscribeSettings = settings.$subscribe(async () => {
  filterMenuData.value = await makeMenuData();

  listKey.value += 1;
});

const unsubscribeFolders = () => {
  return useFoldersStore().$subscribe(async () => {
    filterMenuData.value = await makeMenuData();
    // console.log(
    //   `NoteFilterPanel sees notes mutation and makes menu data ${JSON.stringify(
    //     filterMenuData.value
    //   )}`
    // );
    listKey.value += 1; // refresh the filter menu
    // }
  });
};

onMounted(async () => {
  // console.log(`NoteFilterPanel is mounted and starts to make menu data`);
  filterMenuData.value = await makeMenuData();
  listKey.value += 1; // refresh the filter menu
});

onBeforeUnmount(() => {
  // console.log("unmounting NoteFilterPanel");
  unsubscribeSettings(); // stop listing to mutations
  unsubscribeFolders();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonHeader), null, {
      default: _withCtx(() => [
        _createVNode(_unref(IonItem), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonTitle), null, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Note Filters")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (filterMenuData.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterMenuData.value, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: listKey.value + item.id + item.status
            }, [
              (isGroup(item))
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(FilterMenuGroup, {
                      itemData: item,
                      onChanged: _cache[0] || (_cache[0] = ($event: any) => (onGroupChanged($event)))
                    }, null, 8, ["itemData"]),
                    (isOpen(item))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: listKey.value + child.id
                            }, [
                              _createVNode(FilterMenuItem, {
                                isForNotes: "",
                                itemData: child,
                                filterId: child.id,
                                title: child.title,
                                status: child.status,
                                onChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onItemChanged($event))),
                                onSelected: _cache[2] || (_cache[2] = ($event: any) => (onItemSelected($event)))
                              }, null, 8, ["itemData", "filterId", "title", "status"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(FilterMenuItem, {
                      isForNotes: "",
                      itemData: item,
                      filterId: item.id,
                      title: item.title,
                      status: item.status,
                      isGroup: item.isGroup,
                      count: item.count,
                      onChanged: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onItemChanged($event))),
                      onSelected: _cache[4] || (_cache[4] = ($event: any) => (onItemSelected($event)))
                    }, null, 8, ["itemData", "filterId", "title", "status", "isGroup", "count"])
                  ]))
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[6] || (_cache[6] = [
          _createElementVNode("p", null, "NoteFilterPanel has no filterMenuData", -1)
        ])))
  ], 64))
}
}

})