import { TaskObject } from "../TaskObject";

export abstract class Filter {
    public name: string;

    constructor(name: string) {
        this.name = name;
    }

    public abstract isFiltered(item: TaskObject): boolean;
    public abstract description: string | undefined;
    public abstract get debugDescription(): string | undefined;
}