import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    key: _ctx.myFilterId + _ctx.myStatus,
    button: "",
    detail: "false",
    mode: "md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, {
        className: "ion-text-wrap",
        onClick: _ctx.onStatusClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.myTitle), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_ion_button, {
        onClick: _ctx.onStatusClicked,
        fill: "clear"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            icon: _ctx.chevronDownOutline,
            color: "medium"
          }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}