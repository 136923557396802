// set up the model

import { Syncable } from "./Syncable";
import { formatISO } from "date-fns";
import { DocumentData } from "firebase/firestore";

export interface FolderType extends Syncable {
  id?: string;
  title?: string;
  added?: Date;
  modified?: Date;
  note?: string;

  copyToSimpleObject(): any;
}

export class FolderObject implements FolderType {
  id?: string;
  title?: string;
  added?: Date;
  modified?: Date;
  note?: string;

  constructor(folderData: Partial<FolderObject>) {
    Object.assign(this, folderData);
  }

  public copyToSimpleObject() {
    const defaults = {
      id: "",
      title: "",
      added: "",
      modified: "",
      note: "",
    };

    const result = {
      ...defaults,
      ...this,
    };

    return result;
  }

  public copyToFirestoreObject() {
    const result = {
      id: this.id ?? "",
      title: this.title ?? "",
      added: this.added ? formatISO(this.added) : "",
      modified: this.modified ? formatISO(this.modified) : "",
      note: this.note ?? "",
    };
    return result;
  }

  static fromFirestoreObject(
    source: DocumentData,
    withId?: string
  ): FolderObject {
    return new FolderObject({
      title: source.title ?? "",
      id: withId ?? source.id,
      added: source.added ? new Date(source.added) : undefined,
      modified: source.modified ? new Date(source.modified) : undefined,
      note: source.note,
    });
  }
}
