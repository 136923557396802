import { NoteObject } from "../NoteObject";

export abstract class NoteFilter {
    public name: string;

    constructor(name: string) {
        this.name = name;
    }

    public abstract isFiltered(item: NoteObject): boolean;
    public abstract description: string | undefined;
    public abstract get debugDescription(): string | undefined;
}