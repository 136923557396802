import { defineStore } from "pinia";
import { MyNotification } from "@/models/Notification";

let nextNotificationId = 1

export const useNotificationsStore = defineStore("notifications", {
    state: () => ({
        /**
         * An array of notifications with properties {type: string, message: string}
         */
        notifications: Array<MyNotification>()
    }),

    getters: {

    },

    actions: {

        addNotification(notification: MyNotification) {
            // console.log(`addNotification mutation for ${JSON.stringify(notification)}`)
              this.notifications.push({
                ...notification,
                id: nextNotificationId++
              })
              // console.log(`addNotification mutation for ${JSON.stringify(notification)} leaves notification state as ${JSON.stringify(this.notifications)}` )
            },
            removeNotification(notificationToRemove: any) {
              this.notifications = this.notifications.filter(
                (notification: { id: any }) => notification.id !== notificationToRemove.id
              )
              // console.log(`removeNotification mutation for ${JSON.stringify(notificationToRemove)} leaves notification state as ${JSON.stringify(this.notifications)}` )

            },

    }
})