import { Priority } from "@/models/Priority";
import { SortDirection } from "@/models/SortDirection";
import { TaskObject } from "@/models/TaskObject";
import { TaskComparer } from "./TaskComparer";

export class TaskPriorityComparer extends TaskComparer {
  constructor(direction: SortDirection) {
    super(direction);
  }
  compare = (a: TaskObject, b: TaskObject) => {
    const ascending = this.direction === SortDirection.Ascending;
    const priorityOrder = [
      Priority.Negative,
      Priority.Low,
      Priority.Medium,
      Priority.High,
      Priority.Top,
    ];
    if (a.priority == b.priority) return 0;
    if (!a.priority) return ascending ? -1 : 1;
    if (!b.priority) return ascending ? 1 : -1;
    if (priorityOrder.indexOf(a.priority) < priorityOrder.indexOf(b.priority))
      return ascending ? -1 : 1;
    return ascending ? 1 : -1;
  };
}
