import { NoteFilter } from "./NoteFilter";
import { NoteObject } from "../NoteObject";
import { FolderObject } from "../FolderModel";
import { Constants } from "@/constants/constants";
import { useFoldersStore } from "@/stores/useFoldersStore";


/**
 * Filters notes that have folder Id set to a given vaue
 *
 * @param folderId - the folder Id to be filtered on
 *
 * @remarks
 * If value is null, notes are filtered in when they have no folderId
 */
export class FolderNoteFilter extends NoteFilter {

  public get debugDescription(): string {
    return `Folder Note filter: folder ${this.value}`
  }
  value?: string;
  title?: string;
  constructor(folderId?: string) {
    if (!folderId || folderId === 'none'){
      super(Constants.INBOX_TITLE)
      // console.log(`FolderNoteFilter constructor with no folder`)
      this.value = null
      this.title = Constants.INBOX_TITLE
      return
    }

    // get title for folder with this id
    const foldersStore = useFoldersStore();
    const folder: FolderObject = foldersStore.folderFromId(folderId)
    const t = folder?.title ?? Constants.INBOX_TITLE
    super(t);
    this.title = t;
    this.value = folderId
    // console.log(`FolderNoteFilter constructor with folder id ${value}`)
  }

  public isFiltered(item: NoteObject): boolean {
    // console.log(`FolderNoteFilter is filtering for folder Id ${this.value}`)
    if (!this.value) {
      const result = !item.folderId

      return result;
    }
    const result = this.value == item.folderId;
    return result;
  }

  public get description() {
    if (!this.value) return Constants.INBOX_TITLE;
    return `${this.title} : ${this.value}`;
  }


}
