export class Constants {
  // app
  public static APP_URL = "https://app.taskangel.com";
  public static FIREBASE_API_KEY = "AIzaSyAU-W1NpRM2fZ-yLGwKdnY_Smnkq7ym9DA";
  public static FIREBASE_PROJECT_ID = "taskangel-f1161";
  public static GOOGLE_CLIENT_ID =
    "133694406051-hqfkg0gqqt76tp7r3qrghms3gc8fqaj8.apps.googleusercontent.com";
  // which sign-up step is showing

  public static SIGNUP_STEP1 = "step 1";
  public static SIGNUP_STEP2 = "step 2";
  public static SIGNUP_STEP3 = "step 3";
  public static SIGNUP_NONE = "no signup";

  // customer journey

  public static JOURNEY_NEWBIE = "newbie";
  public static JOURNEY_REGISTERED = "registered";
  public static JOURNEY_IN_CHECKOUT = "inCheckout";
  public static JOURNEY_IN_FREE_TRIAL = "inFreeTrial";
  public static JOURNEY_FREE_TRIAL_EXPIRED = "freeTrialExpired";
  public static JOURNEY_PREMIUM = "premium";
  public static JOURNEY_PREMIUM_EXPIRED = "premiumExpired";
  public static JOURNEY_CANCELLED = "cancelled";
  public static JOURNEY_UNPAID = "unpaid";
  public static JOURNEY_PAST_DUE = "past_due";
  public static JOURNEY_PAYMENT_FAILED = "payment_failed";
  public static JOURNEY_FOUNDER = "founder";
  public static TRIAL_PERIOD_DAYS = 7;

  public static MONTHLY_PRICE_ID = "price_1KDBcUJhaCQ1c8lR6ksI90lx";

  // limits
  public static MAX_USERS = 15;
  public static MAX_BATCH_SIZE = 500; // for firestore
  public static MAX_TASKS_IN_TRIAL = 100;
  public static MAX_NOTES_IN_TRIAL = 100;
  public static MAX_TASKS_WHEN_ACTIVE = 1000;
  public static MAX_NOTES_WHEN_ACTIVE = 1000;
  // public static IMPORT_CHUNK_SIZE = 500

  // subscriptions

  // purging tasks completed long ago
  public static PURGE_TASKS_THRESHOLD = 100;
  public static KEEP_TASKS_DAYS = 30;
  public static PURGE_BATCH_SIZE = 100;

  // notification types
  // (these values are used in css classes)
  public static NOTIFICATION_ERROR = "error";
  public static NOTIFICATION_SUCCESS = "success";

  // priority values
  public static PRIORITY_TOP = "Top";
  public static PRIORITY_HIGH = "High";
  public static PRIORITY_MEDIUM = "Medium";
  public static PRIORITY_LOW = "Low";
  public static PRIORITY_NEGATIVE = "Negative";

  // urgency values

  public static URGENCY_SOC = "Significant Outcome";
  public static URGENCY_CRITICAL_NOW = "Critical Now";
  public static URGENCY_OPPORTUNITY_NOW = "Opportunity Now";
  public static URGENCY_OVER_THE_HORIZON = "Over-the-Horizon";

  // repeat values
  public static REPEAT_NONE = "None";
  public static REPEAT_DAILY = "Daily";
  public static REPEAT_WEEKDAYS = "Weekdays";
  public static REPEAT_WEEKLY = "Weekly";
  public static REPEAT_BIWEEKLY = "Biweekly";
  public static REPEAT_MONTHLY = "Monthly";
  public static REPEAT_QUARTERLY = "Quarterly";
  public static REPEAT_SEMIANNUALLY = "Semiannually";
  public static REPEAT_YEARLY = "Yearly";
  public static REPEAT_PARENT = "With Parent";

  public static NO_DATE = new Date(0);
  public static INBOX_TITLE = "No Folder";

  // error messages

  public static ERROR_NOT_LOGGED_IN = "You aren't logged in";
  public static ERROR_TASK_NO_ID = "Can't write a task without an id";

  // view options

  public static COMPACT_LIST = "Compact";
  public static EXPANDED_LIST = "Expanded";

  // commands in the filter menu

  public static REFRESH_COMMAND = "Refresh...";
  public static REFRESH_COMMAND_ID = "command.refresh";

  // filters

  public static SIDE_MENU_OPEN = "sideMenuOpen";

  // saved filters are prefixed with "savedfilter."

  public static SAVED_FILTER_PREFIX = "savedfilter.";
  public static ALL_TASKS_FILTER = "filter.alltasks";
  public static ALL_NOTES_FILTER = "filter.allnotes";
  public static COMPLETED_FILTER = "filter.iscomplete";
  public static STATUS_ANY_FILTER = "filter.status.any";
  public static ACTIVE_FILTER = "filter.active";
  public static STARRED_FILTER = "filter.starred";
  public static UNSTARRED_FILTER = "filter.star.unstarred";
  public static STARRED_ANY_FILTER = "filter.starred.any";
  public static TEXT_FILTER = "filter.text";
  // public static NOTE_TEXT_FILTER = "filter.note.text";
  public static OPEN_FILTER_GROUP = "open.filter.group";
  public static TEXT_NOTE_FILTER = "note.filter.text";
  public static OPEN_NOTE_FILTER_GROUP = "open.note.filter.group";

  public static PRIORITY_GROUP_FILTER = "filter.priority.group";
  public static PRIORITY_TOP_FILTER = "filter.priority.top";
  public static PRIORITY_HIGH_FILTER = "filter.priority.high";
  public static PRIORITY_MEDIUM_FILTER = "filter.priority.medium";
  public static PRIORITY_LOW_FILTER = "filter.priority.low";
  public static PRIORITY_NEGATIVE_FILTER = "filter.priority.negative";
  public static PRIORITY_ANY_FILTER = "filter.priority.any";

  public static URGENCY_GROUP_FILTER = "filter.urgency.group";
  public static URGENCY_SOC_FILTER = "filter.urgency.soc";
  public static URGENCY_CRITICAL_NOW_FILTER = "filter.urgency.critical";
  public static URGENCY_OPPORTUNITY_NOW_FILTER = "filter.urgency.opp";
  public static URGENCY_OVER_THE_HORIZON_FILTER = "filter.urgency.oth";
  public static URGENCY_ANY_FILTER = "filter.urgency.any";

  public static START_GROUP_FILTER = "filter.start.group";
  public static START_OVERDUE_FILTER = "filter.start.overdue";
  public static START_STARTED_FILTER = "filter.start.started";
  public static START_BY_TODAY_FILTER = "filter.start.bytoday";
  public static START_TODAY_FILTER = "filter.start.today";
  public static START_TOMORROW_FILTER = "filter.start.tomorrow";
  public static START_WEEK_FILTER = "filter.start.week";
  public static START_MONTH_FILTER = "filter.start.month";
  public static START_3_MONTHS_FILTER = "filter.start.quarter";
  public static START_YEAR_FILTER = "filter.start.year";
  public static START_UNDATED_FILTER = "filter.start.undated";
  public static START_ANY_FILTER = "filter.start.any";

  public static DUE_GROUP_FILTER = "filter.due.group";
  public static DUE_OVERDUE_FILTER = "filter.due.overdue";
  public static DUE_BY_TODAY_FILTER = "filter.due.bytoday";
  public static DUE_TODAY_FILTER = "filter.due.today";
  public static DUE_TOMORROW_FILTER = "filter.due.tomorrow";
  public static DUE_WEEK_FILTER = "filter.due.week";
  public static DUE_MONTH_FILTER = "filter.due.month";
  public static DUE_3_MONTHS_FILTER = "filter.due.quarter";
  public static DUE_YEAR_FILTER = "filter.due.year";
  public static DUE_UNDATED_FILTER = "filter.due.undated";
  public static DUE_ANY_FILTER = "filter.due.any";

  public static ADDED_GROUP_FILTER = "filter.added.group";
  public static ADDED_TODAY_FILTER = "filter.added.today";
  public static ADDED_YESTERDAY_FILTER = "filter.added.yesterday";
  public static ADDED_WEEK_FILTER = "filter.added.week";
  public static ADDED_MONTH_FILTER = "filter.added.month";
  public static ADDED_3_MONTHS_FILTER = "filter.added.quarter";
  public static ADDED_YEAR_FILTER = "filter.added.year";
  public static ADDED_OLDER_FILTER = "filter.added.older";
  public static ADDED_UNDATED_FILTER = "filter.added.undated";
  public static ADDED_ANY_FILTER = "filter.added.any";

  public static IMPORTED_GROUP_FILTER = "filter.imported.group";
  public static IMPORTED_TODAY_FILTER = "filter.imported.today";
  public static IMPORTED_YESTERDAY_FILTER = "filter.imported.yesterday";
  public static IMPORTED_WEEK_FILTER = "filter.imported.week";
  public static IMPORTED_MONTH_FILTER = "filter.imported.month";
  public static IMPORTED_3_MONTHS_FILTER = "filter.imported.quarter";
  public static IMPORTED_YEAR_FILTER = "filter.imported.year";
  public static IMPORTED_OLDER_FILTER = "filter.imported.older";
  public static NOT_IMPORTED_FILTER = "filter.imported.undated";
  public static IMPORTED_ANY_FILTER = "filter.imported.any";

  public static GOAL_GROUP_FILTER = "filter.goal.group";
  public static GOAL_FILTER_PREFIX = "filter.goal.";
  public static GOAL_NONE_FILTER = "filter.goal.none";
  public static GOAL_ANY_FILTER = "filter.goal.any";

  public static FOLDER_GROUP_FILTER = "filter.folder.group";
  public static FOLDER_FILTER_PREFIX = "filter.folder.";
  public static FOLDER_NONE_FILTER = "filter.folder.none";
  public static FOLDER_ANY_FILTER = "filter.folder.any";

  public static CONTEXT_GROUP_FILTER = "filter.context.group";
  public static CONTEXT_FILTER_PREFIX = "filter.context.";
  public static CONTEXT_NONE_FILTER = "filter.context.none";
  public static CONTEXT_ANY_FILTER = "filter.context.any";

  public static FOLDER_GROUP_NOTE_FILTER = "note.filter.folder.group";
  public static FOLDER_NOTE_FILTER_PREFIX = "note.filter.folder.";
  public static FOLDER_NONE_NOTE_FILTER = "note.filter.folder.none";
  public static FOLDER_ANY_NOTE_FILTER = "note.filter.folder.any";

  // settings

  public static SETTINGS_VERSION_KEY = "settings.version";
  public static SETTINGS_JSON = "settings.json";
  public static SHOW_COMPLETED = "setting.show.completed";
  public static SHOW_NEGATIVE = "setting.show.negative";
  public static NEW_TASKS_START_TODAY = "setting.new_tasks_start_today";
  public static SHOW_FUTURE_STARTS = "setting.show.future_starts";
  public static SHOW_MULTIPLE_FILTERS = "setting.show.multiplefilters";
  public static SELECTED_FILTER = "setting.selectedFilter";
  public static SELECTED_NOTE_FILTER = "setting.selectedNoteFilter";
  public static CURRENT_TAB = "settings.currentTab"; // this can be 'notes' or 'tasks'
  public static TASKS_ACTIVE = "tasks";
  public static NOTES_ACTIVE = "notes";

  public static GROUP_TASKS_BY = "setting.group.tasks.by";
  public static GROUP_TASKS_DIRECTION = "setting.group.tasks.direction";

  public static SORT_TASKS_FIRST_BY = "setting.sort.tasks.first.by";
  public static SORT_TASKS_FIRST_DIRECTION =
    "setting.sort.tasks.first.direction";

  public static SORT_TASKS_SECOND_BY = "setting.sort.tasks.second.by";
  public static SORT_TASKS_SECOND_DIRECTION =
    "setting.sort.tasks.second.direction";

  public static SORT_TASKS_THIRD_BY = "setting.sort.tasks.third.by";
  public static SORT_TASKS_THIRD_DIRECTION =
    "setting.sort.tasks.third.direction";

  public static SORT_NOTES_BY = "setting.sort.notes.by";
  public static SORT_NOTES_DIRECTION = "setting.sort.notes.direction";

  public static SORT_RECENT = "recent";
  public static SORT_ALPHA = "alpha";

  public static USE_MYN = "settings.use_myn";
  public static AUTO_URGENCY = "settings.auto.urgency";

  /** convert priority to urgency.
   *
   * usage: urgency = Constants.URGENCY_MAP[priority] ?? Constants.OVER_THE_HORIZON
   * */
  public static URGENCY_MAP = {
    Top: "Significant Outcome",
    High: "Critical Now",
    Medium: "Opportunity Now",
    Low: "Over-the-Horizon",
    Negative: "Over-the-Horizon",
  };

  public static URGENCY_ORDER = [
    "Significant Outcome",
    "Critical Now",
    "Opportunity Now",
    "Over-the-Horizon",
  ];
  public static PRIORITY_ORDER = ["Top", "High", "Medium", "Low", "Negative"];

  // keys for stored dashboard counts

  public static STORED_CRITICAL_TASKS_COUNT = "storedCriticalTasksCount";
  public static STORED_TODAYS_TASKS_COUNT = "storedTodaysTasksCount";
  public static STORED_ACTIVE_TASKS_COUNT = "storedActiveTasksCount";
  public static STORED_ALL_TASKS_COUNT = "storedActiveTasksCount";
  public static STORED_COMPLETED_TASKS_COUNT = "storedCompletedTasksCount";

  public static STORED_TODAYS_NOTES_COUNT = "storedTodaysNotesCount";
  public static STORED_ALL_NOTES_COUNT = "storedAllNotesCount";

  public static STORED_FOLDERS_COUNT = "storedStoredFoldersCount";
  public static STORED_CONTEXTS_COUNT = "storedStoredContextsCount";
  public static STORED_GOALS_COUNT = "storedGoalsCount";

  //options for markdown converter

  public static SHOWDOWN_OPTIONS = {
    simplifiedAutoLink: true,
    simpleLineBreaks: true,
    tables: true,
    tasklists: true,
  };
}
