<template>
  <ion-item :key="myFilterId + myStatus" button detail="false" mode="md">
    <ion-label className="ion-text-wrap" @click="onStatusClicked"
      >{{ myTitle }}
    </ion-label>

    <ion-button @click="onStatusClicked" fill="clear">
      <ion-icon :icon="chevronDownOutline" color="medium"> </ion-icon>
    </ion-button>
  </ion-item>
</template>

<script lang="ts">
import {
  addCircleOutline,
  ban,
  checkmarkCircle,
  //   filterOutline,
  chevronUpOutline,
  chevronDownOutline,
} from "ionicons/icons";
import { defineComponent, toRefs, ref, computed, SetupContext } from "vue";
import { IonIcon, IonButton, IonItem, IonLabel } from "@ionic/vue";
import { MenuItemObject } from "@/models/MenuItemObject";

export default defineComponent({
  name: "FilterMenuGroup",
  components: {
    IonIcon,
    IonButton,
    IonItem,
    IonLabel,
  },
  props: {
    // icon: String,
    // title: String,
    // filterId: String, // like 'folder.12345' or 'priority.high'
    itemData: MenuItemObject,
    // status: String,
  },
  emits: ["changed"],
  setup(props, ctx: SetupContext) {
    const { itemData } = toRefs(props);
    // const store = useStore();

    const title = itemData?.value?.title ?? "";
    const filterId = itemData?.value?.id ?? "";
    const status = itemData?.value?.status ?? "";

    // console.log(`FilterMenuItem sees props: ${JSON.stringify(props)}`);

    // declare properties

    const myFilterId = filterId;
    const myTitle = title;
    const myStatus = ref(status);

    const listKey = ref(0);

    // declare computed properties

    // myStatus.value = store.getters.getSetting(myFilterId) ?? "inactive";

    // console.log(`FilterMenuGroup status: ${myStatus.value}`);

    const isOpen = computed<boolean>(() => {
      return myStatus.value === "open";
    });

    const hasChildren = computed<boolean>(() => {
      if (!itemData?.value) {
        return false;
      }
      return itemData.value?.children?.length > 0;
    });

    // declare functions

    const onStatusClicked = () => {
      // console.log(
      //   `FilterMenuGroup: user clicked status when it was ${myStatus.value}`
      // );
      let newStatus = "closed";

      switch (myStatus.value) {
        case "open":
          newStatus = "closed";
          break;
        default:
          newStatus = "open";
          break;
      }

      // console.log(
      //   `FilterMenuGroup emits changed event for: ${filterId}: ${newStatus}`
      // );
      myStatus.value = newStatus;
      if (filterId) {
        // store.dispatch("setSetting", { key: filterId.value, value: newStatus });
        ctx.emit("changed", { id: filterId, value: newStatus });
      }
    };

    return {
      myTitle,
      myFilterId,
      isOpen,
      hasChildren,
      listKey,
      myStatus,
      // status,

      // icons
      chevronUpOutline,
      chevronDownOutline,
      addCircleOutline,
      ban,
      checkmarkCircle,
      //   filterOutline,
      // functions

      onStatusClicked,
    };
  },
});
</script>

<style scoped></style>
