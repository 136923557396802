import { Priority } from "./Priority";
import { Urgency } from "./Urgency";
import { addDays, startOfDay } from "date-fns";

export const UrgencyUtils = {
  urgencyToPriority(urgency?: string): Priority {
    switch (urgency ?? Urgency.OpportunityNow) {
      case Urgency.SOC:
        return Priority.Top;
      case Urgency.CriticalNow:
        return Priority.High;
      case Urgency.OpportunityNow:
        return Priority.Medium;
      case Urgency.OverTheHorizon:
        return Priority.Low;
      default:
        return Priority.Low;
    }
  },
  urgencyFromPriorityWithAutoUrgency(
    priority?: Priority,
    due?: Date,
    completed?: Date,
    autoUrgency?: boolean
  ): Urgency {
    const today = new Date();
    const tomorrow = addDays(today, 1);
    const beforeTomorrow = due < startOfDay(tomorrow);

    return (
      (!priority && Urgency.OverTheHorizon) ||
      (priority === Priority.Top && Urgency.SOC) ||
      (priority === Priority.High && Urgency.CriticalNow) ||
      (priority === Priority.Medium &&
        autoUrgency &&
        !completed &&
        beforeTomorrow &&
        Urgency.CriticalNow) ||
      (priority === Priority.Medium && Urgency.OpportunityNow) ||
      Urgency.OverTheHorizon
    );
  },
  urgencyFromString(stringIn: string): Urgency | undefined {
    const urgencyKey = Object.keys(Urgency).find(
      (key) => Urgency[key] === stringIn
    );
    return urgencyKey ? Urgency[urgencyKey] : undefined;
  },
};
