import { Filter } from "./Filter";
import { TaskObject } from "../TaskObject";

export class StarredFilter extends Filter{
    public get debugDescription(): string {
        return `Starred filter:  ${this.name}`
    }
    value: boolean;
    constructor(value: boolean) {
        super(value ? 'Starred' : 'Not starred');
        this.value = value;
    }

    public isFiltered(item: TaskObject): boolean {
        let result = false;
        if (this.value) {
            result = !!item.isStarred;
        } else {
            result =  !item.isStarred;
        }
        return result;
    }
    
    public get description(){
        return this.value ? "starred" : "unstarred";
    }

   
}