import { MenuItemObject } from "./MenuItemObject";

export class MenuGroupObject extends MenuItemObject{
    // id: string;
    // title: string;
    summary: string;
    // status: string;
    // children: Array<MenuItemObject>;



    constructor(id: string, title: string, status: string,  children: Array<MenuItemObject>, ) {
        super(id, title);
        this.title = title;
        this.id = id;
        this.summary = '';
        this.status = status;
        this.children = children;
    }

}