import { Logger } from "@/helpers/Logger";
import { Priority } from "@/models/Priority";
import { Property } from "@/models/Property";
import { SortDirection } from "@/models/SortDirection";
import { TaskGroup } from "@/models/TaskGroup";
import { TaskObject } from "@/models/TaskObject";
import { TaskSortDescriptor } from "@/sorters/TaskSortDescriptor";
import { TaskSorter } from "@/sorters/TaskSorter";
import { useContextsStore } from "@/stores/useContextsStore";
import { useFoldersStore } from "@/stores/useFoldersStore";
import { useGoalsStore } from "@/stores/useGoalsStore";
import { useSettingsStore } from "@/stores/useSettingsStore";

export class TaskGrouper {
  constructor(private property: Property, private direction: SortDirection) {}

  group(tasks: TaskObject[]): TaskGroup[] {
    if (this.property === Property.Priority) return this.groupByPriority(tasks);
    if (this.property === Property.Urgency) return this.groupByUrgency(tasks);
    if (this.property === Property.Folder) return this.groupByFolder(tasks);
    if (this.property === Property.Goal) return this.groupByGoal(tasks);
    if (this.property === Property.Context) return this.groupByContext(tasks);
    if (this.property === Property.Start) return this.groupByStart(tasks);
    if (this.property === Property.Due) return this.groupByDue(tasks);
    if (this.property === Property.Title) return this.groupByTitle(tasks);
    throw new Error(
      `TaskGrouper is unable to group tasks by property ${this.property}`
    );
  }

  groupByPriority(tasks: TaskObject[]): TaskGroup[] {
    Logger.log(`groupByPriority is starting`);
    const result = [];
    let currentGroup: TaskGroup;
    currentGroup = undefined;
    const sorter = new TaskSorter([
      new TaskSortDescriptor(Property.Priority, this.direction),
    ]);
    Logger.log(`groupByPriority is sorting by priority`);
    const sortedTasks = sorter.sort(tasks);
    Logger.log(`groupByPriority is grouping by priority`);
    for (const task of sortedTasks) {
      const priorityString: string = task?.priority ?? Priority.Low;
      if (
        !currentGroup ||
        !currentGroup.title ||
        currentGroup.title != priorityString
      ) {
        currentGroup = new TaskGroup(priorityString, priorityString);
        result.push(currentGroup);
      }
      if (currentGroup) currentGroup.items.push(task);
    }
    return result;
  }

  groupByUrgency(tasks: TaskObject[]): TaskGroup[] {
    const settingsStore = useSettingsStore();
    const { autoUrgency } = settingsStore;
    const result = Array<TaskGroup>();
    let currentGroup: TaskGroup;
    currentGroup = undefined;
    for (let i = 0; i < tasks.length; ++i) {
      const task = tasks[i];
      const value = task.getUrgency(autoUrgency);
      if (!currentGroup?.title || currentGroup.title !== value) {
        currentGroup = new TaskGroup(value, value);
        result.push(currentGroup);
      }
      if (currentGroup) currentGroup.items.push(task);
    }
    return result;
  }

  groupByTitle(tasks: TaskObject[]): TaskGroup[] {
    const result = Array<TaskGroup>();
    let currentGroup: TaskGroup;
    currentGroup = undefined;
    for (const task of tasks) {
      const value = task.title,
        groupTitle = `Title: ${value ?? "No title"}`;
      if (
        currentGroup === undefined ||
        !currentGroup?.title ||
        currentGroup.title !== groupTitle
      ) {
        currentGroup = new TaskGroup(groupTitle, value);
        result.push(currentGroup);
      }
      if (currentGroup) currentGroup.items.push(task);
    }
    return result;
  }

  groupByFolder(tasks: TaskObject[]): TaskGroup[] {
    const result = Array<TaskGroup>();
    const foldersStore = useFoldersStore();
    let currentGroup: TaskGroup;
    for (let i = 0; i < tasks.length; ++i) {
      const task = tasks[i];
      const value = task.folderId;
      const groupTitle = `Folder: ${
        foldersStore.folderFromId(value)?.title ?? "No folder"
      }`;

      if (
        !currentGroup ||
        !currentGroup?.title ||
        currentGroup.title !== groupTitle
      ) {
        currentGroup = new TaskGroup(groupTitle, value);
        result.push(currentGroup);
      }
      if (currentGroup) currentGroup.items.push(task);
    }
    return result;
  }

  groupByGoal(tasks: TaskObject[]): TaskGroup[] {
    const result = Array<TaskGroup>();
    const goalsStore = useGoalsStore();
    let currentGroup: TaskGroup;
    for (let i = 0; i < tasks.length; ++i) {
      const task = tasks[i];
      const value = task.goalId;
      const groupTitle = `Goal: ${
        goalsStore.goalFromId(value)?.title ?? "No goal"
      }`;

      if (
        !currentGroup ||
        !currentGroup?.title ||
        currentGroup.title !== groupTitle
      ) {
        currentGroup = new TaskGroup(groupTitle, value);
        result.push(currentGroup);
      }
      if (currentGroup) currentGroup.items.push(task);
    }
    return result;
  }

  groupByContext(tasks: TaskObject[]): TaskGroup[] {
    const result = Array<TaskGroup>();
    const contextsStore = useContextsStore();
    let currentGroup: TaskGroup;
    for (let i = 0; i < tasks.length; ++i) {
      const task = tasks[i];
      const value = task.contextId;
      const groupTitle = `Context: ${
        contextsStore.contextFromId(value)?.title ?? "No context"
      }`;

      if (
        !currentGroup ||
        !currentGroup?.title ||
        currentGroup.title !== groupTitle
      ) {
        currentGroup = new TaskGroup(groupTitle, value);
        result.push(currentGroup);
      }
      if (currentGroup) currentGroup.items.push(task);
    }
    return result;
  }

  groupByStart(tasks: TaskObject[]): TaskGroup[] {
    const result = Array<TaskGroup>();
    let currentGroup: TaskGroup;
    for (let i = 0; i < tasks.length; ++i) {
      const task = tasks[i];
      const value = task.start;
      const groupTitle = `Start: ${
        Intl.DateTimeFormat().format(value) ?? "Undated"
      }`;

      if (
        !currentGroup ||
        !currentGroup?.title ||
        currentGroup.title !== groupTitle
      ) {
        currentGroup = new TaskGroup(groupTitle, value);
        result.push(currentGroup);
      }
      if (currentGroup) currentGroup.items.push(task);
    }
    return result;
  }

  groupByDue(tasks: TaskObject[]): TaskGroup[] {
    const result = Array<TaskGroup>();
    let currentGroup: TaskGroup;
    for (let i = 0; i < tasks.length; ++i) {
      const task = tasks[i];
      const value = task.due;
      const groupTitle = `Due: ${
        Intl.DateTimeFormat().format(value) ?? "Undated"
      }`;

      if (
        !currentGroup ||
        !currentGroup?.title ||
        currentGroup.title !== groupTitle
      ) {
        currentGroup = new TaskGroup(groupTitle, value);
        result.push(currentGroup);
      }
      if (currentGroup) currentGroup.items.push(task);
    }

    return result;
  }
}
